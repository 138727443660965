const React = require('react');
const formatDate = require('mn-utils/formatDate');
const withState = require('../../../utils/withState');
const {log$, logListLoading$} = require('../../../emitters/log');
const LayoutD = require('../../dumb/LayoutD');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const TableC = require('../../dumb/TableC');
const Animate = require('../../dumb/Animate');


function renderItem(props) {
  const {item} = props;
  const hasError = item.event_type == 'error';
  return (
    <div
      className={`w layoutRow fhaS fvaC h40 ph15 ph5>1 cA00.error
        dn1000 (dn|bgBBF).active`
        + (hasError ? ' error' : '')
        + (props.active ? ' active' : '')}
    >
      <div
        className="w160 ws tov ov"
      >{formatDate(new Date(item.timestamp), 'dd.mm.yyyy, HH:MM:ss')}</div>
      <div
        className="fx1 ws tov ov"
      >{item.event_text}</div>
    </div>
  );
}


const Logs = require('../../../utils/virtualOutputProvider')({
  data$: log$,
  listLoading$: logListLoading$,
  rowHeight: 40,
  render(item) {
    return (<Animate
      item={item}
      render={renderItem}
    />);
  },
  request: require('../../../api/apiLog').apiLogList,
});

module.exports = withState((setState) => {
  return (state, props) => {
    const {width} = state;
    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Log</CaptionB>
        </HeaderA>
        <TableC
          headWidth={width}
          head={(
            <>
              <div className="w160">Time</div>
              <div className="fx1">Action</div>
            </>
          )}
          body={(
            <div className="h100vh-338 pv10 hmin200 ovxH ovyA">
              <Logs
                onWidth={(_width) => {
                  width === _width || setState({width: _width});
                }}
              />
            </div>
          )}
        />
      </LayoutD>
    );
  };
}, {});
