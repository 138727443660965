const {errors$} = require('../popup');
const {decorateReconnect} = require('../account');

module.exports = (request, loading$, onResponse) => {
  const _request = decorateReconnect(request);
  const loadingEmit = loading$.emit;
  let promise;
  return (params) => {
    if (loading$.getValue()) return promise;
    loadingEmit(1);
    return promise = _request(params).finally((err, response) => {
      loadingEmit(0);
      if (err) {
        err.skip || (
          errors$.push(err.message),
          console.error(err)
        );
        return;
      }
      try {
        onResponse && onResponse(response, params);
      } catch (ex) {
        console.error(ex);
        errors$.push(ex.message);
      }
    });
  };
};
