const React = require('react');
const ButtonH = require('../../dumb/ButtonH');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const OneTimePassword = require('../../dumb/OneTimePassword');
const PopupB = require('../../containers/PopupB');
const withState = require('../../../utils/withState');
const {
  LINK_BLOCKED,
} = require('../../../constants/links');
const {
  login$,
  openAuth2FA$,
  account$,
} = require('../../../emitters/account');
const {
  bind2FALoading$,
  verify2FA,
} = require('../../../emitters/profile');
const {
  replaceLocation,
} = require('../../../router');


const Auth2FA = withState((setState) => {
  function handleChangeOneTimePassword(oneTimePassword) {
    setState({
      oneTimePassword,
    });
  }
  return (state, props) => {
    const oneTimePassword = state.oneTimePassword || '';
    const disabled = oneTimePassword.length !== 6;

    function handleSubmit() {
      disabled || verify2FA({
        code: oneTimePassword,
      })
          .finally((err, response) => {
            if (response && response.blocked) {
              replaceLocation({
                path: LINK_BLOCKED + '/' + state.email,
              });
              return;
            }
            err || (
              openAuth2FA$.emit(0),
              window.location.reload()
            );
          });
    }
    return (
      <WrapperWithLoading
        className="wmax w600 cF strokeF>circle"
        loading={state.loading}
      >
        <OneTimePassword
          value={oneTimePassword}
          onChange={handleChangeOneTimePassword}
          onEnd={handleSubmit}
        />
        <div className="mt40 layoutRow fhaB fvaC">
          <ButtonH
            className="bgF\.12:h"
            onClick={() => {
              openAuth2FA$.emit(0);
              account$.signOut();
            }}
          >Cancel</ButtonH>
          <ButtonH
            disabled={disabled}
            className="bgEFF0F0*2 bgF:not[.disabled]:h*2 c1D1E20*2"
            onClick={handleSubmit}
          >Send</ButtonH>
        </div>
      </WrapperWithLoading>
    );
  };
}, {
  loading: bind2FALoading$,
  email: login$,
});


module.exports = (props) => {
  return (
    <PopupB open$={openAuth2FA$}>
      <Auth2FA/>
    </PopupB>
  );
};
