const React = require('react');
const FormAuthBase = require('./components/FormAuthBaseB');

module.exports = (props) => {
  return (
    <FormAuthBase>
      <div className="tc wmax400 mhA">
        <div className="f24">Thank you for registering!</div>
        <div className="mt40 f18 lh150%">
          As soon as the arbitration is ready, we will immediately send
          you access to the system to {props.params.email}.
        </div>
      </div>
    </FormAuthBase>
  );
};
