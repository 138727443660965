const Emitter = require('mn-utils/Emitter');
const map = require('mn-utils/map');
const filter = require('mn-utils/filter');
const find = require('mn-utils/find');
const indexOf = require('mn-utils/indexOf');
const {
  MOCK_STATS,
  apiStatsCashFlow,
  apiStatsBalances,
} = require('../api/apiStats');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');
const {
  notifierCash$,
  notifierCashFlow$,
} = require('./notifications');

export const cashFlowLoading$ = new Emitter(0);
export const statsLoading$ = new Emitter(0);

const getUpdate = decorateByLoadingEmit(
    apiStatsCashFlow,
    cashFlowLoading$,
    (emit, response) => emit(response.cash_flow),
);
export const cashFlow$ = (new Emitter((emit) => {
  getUpdate(emit);

  notifierCashFlow$.on(({
    removed,
    updated,
    pushed,
  }) => {
    cashFlow$.emit([
      ...map(filter(
          cashFlow$.getValue(),
          (item) => indexOf(removed, item.date) === -1,
      ), (item) => find(updated, ['date', item.date]) || item),
      ...pushed,
    ]);
  });
}, [])).behave({
  getUpdate,
});


const getUpdateBalances = decorateByLoadingEmit(
    apiStatsBalances,
    statsLoading$,
    (emit, response) => emit(response),
);
export const stats$ = (new Emitter((emit) => {
  getUpdateBalances(emit);

  notifierCash$.on((next) => {
    const {code, kind, exchange} = next;
    stats$.set({
      [kind]: map(stats$.getValue()[kind], (v) => {
        return code === v.code && v.exchange === exchange ? next : v;
      }),
    });
  });
}, MOCK_STATS)).behave({
  getUpdate: getUpdateBalances,
});
function iterateeFilter(v) {
  return parseFloat(v.amount) > 0;
}
export const statsFiltred$ = stats$.map((v) => {
  return {
    ...v,
    external: filter(v.external, iterateeFilter),
    internal_cc: filter(v.internal_cc, iterateeFilter),
    internal_sc: filter(v.internal_sc, iterateeFilter),
  };
});
export const statsBalances$ = stats$.map((stats) => {
  return [
    ...stats.internal_cc,
    ...stats.internal_sc,
  ];
});
