const React = require('react');

module.exports = (props) => {
  return (
    <div
      {...props}
      className={`layoutRow fhaS fvaC w h52 bgF8F8F9 r4 c1B1B1B ttU f14
        fw5 ff$main lts-\.005em ph15 ph5>1 ` + (props.className || '')}
    />
  );
};
