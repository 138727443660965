const React = require('react');

/* eslint-disable */

module.exports = (props) => {
  return (
    <svg
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'dB fillCT>path ' + (props.className || '')}
    >
      <path d="M4.5 6L0.602886 0L8.39711 0L4.5 6Z"/>
    </svg>
  );
};
