const CancelablePromise = require('mn-utils/CancelablePromise');
const {apiRequest} = require('./ws');
const ENTITY_PREFIX = 'stats/';

export const MOCK_STATS = {
  external: [
    {exchange: 'binance', code: 'BCH', amount: '0'},
    {exchange: 'binance', code: 'BTC', amount: '0.000063089999999999'},
    {exchange: 'hitbtc', code: 'BTC', amount: '0.000257831529999999'},
    {exchange: 'okcoin', code: 'BTC', amount: '0.0'},
    {exchange: 'binance', code: 'DASH', amount: '0'},
    {exchange: 'binance', code: 'ETH', amount: '0'},
    {exchange: 'hitbtc', code: 'ETH', amount: '0.0086'},
    {exchange: 'okcoin', code: 'ETH', amount: '0.0'},
    {exchange: 'binance', code: 'LTC', amount: '0'},
    {exchange: 'hitbtc', code: 'LTC', amount: '0'},
  ],
  internal_cc: [
    {code: 'BTC', amount: '0.000014290000'},
    {code: 'ETH', amount: '0.002173247342464671'},
  ],
  internal_sc: [
    {code: 'EURS', amount: '429560.655'},
  ],
};

function sortByCodeIteratee(a, b) {
  a = a.code;
  b = b.code;
  return a < b ? -1 : (
    a > b ? 1 : 0
  );
}
function sortByCode(v) {
  return v.sort(sortByCodeIteratee);
}

export function apiStatsBalances(params) {
  return (
    process.env.MOCK
      ? CancelablePromise.delay(500, MOCK_STATS)
      : apiRequest(ENTITY_PREFIX + 'balances', params)
  ).then((v) => {
    return {
      ...v,
      external: sortByCode(v.external),
      internal_sc: sortByCode(v.internal_sc),
      internal_cc: sortByCode(v.internal_cc),
    };
  });
}
export function apiStatsCashFlow(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {
      cash_flow: [
        {
          date: '2020-05-29',
          amount: '11',
        },
        {
          date: '2020-05-30',
          amount: '2.34',
        },
        {
          date: '2020-06-01',
          amount: '4.34',
        },
      ],
    });
  }
  return apiRequest(ENTITY_PREFIX + 'cash_flow', params);
}
