const React = require('react');
const IconButton = require('@material-ui/core/IconButton').default;
const Visibility = require('@material-ui/icons/Visibility').default;
const VisibilityOff = require('@material-ui/icons/VisibilityOff').default;
const isEmail = require('mn-utils/isEmail');
const sha512 = require('mn-utils/sha512');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const {
  LINK_SIGN_UP,
  LINK_FORGOT_PASSWORD,
  LINK_HOME,
  LINK_SIGN_UP_THANKS,
} = require('../../../constants/links');
const validatePassword = require('../../../utils/validatePassword');
const withState = require('../../../utils/withState');
const {
  Link,
  replaceLocation,
  pushLocation,
} = require('../../../router');
const {
  account$,
  loadingSignIn$,
  login$,
  handleChangeEmail,
} = require('../../../emitters/account');
const {
  error$,
} = require('../../../emitters/popup');
const ButtonConnect = require('../../dumb/ButtonConnect');
const TextFieldA = require('../../dumb/TextFieldReg');
const FormAuthBase = require('./components/FormAuthBase');

module.exports = withState((setState) => {
  const changeProvider = changeProviderProvider(setState);
  const handleChangePassword = changeProvider('password');
  let passwordNode;

  function handlePasswordRef(node) {
    node && (passwordNode = node);
  }
  function handleEmailEnter(e) {
    e.key == 'Enter' && passwordNode && passwordNode.focus();
  }

  return (state) => {
    const {email, clicked} = state;
    const showPassword = state.showPassword || 0;
    const password = state.password || '';
    const invalidPassword = !validatePassword(password);
    const invalidEmail = !isEmail(email);
    const disabled = invalidPassword || invalidEmail;
    const VisibilityIcon = showPassword ? VisibilityOff : Visibility;

    state.account && replaceLocation({
      path: LINK_HOME,
    });

    function handleSubmit() {
      passwordNode && passwordNode.blur();
      if (state.error) return;
      const _email = email.toLowerCase();
      disabled
        ? setState({clicked: 1})
        : account$.signIn({
          email: _email,
          password: sha512(password),
          handleError(err) {
            if (err.code === 134) {
              pushLocation({
                path: LINK_SIGN_UP_THANKS + '/' + _email,
              });
              throw {skip: 1}; // eslint-disable-line
            }
            throw err;
          },
        });
    }
    function handlePasswordEnter(e) {
      e.key == 'Enter' && handleSubmit();
    }

    return (
      <FormAuthBase
        loading={state.loading}
        footer={(
          <div className="tc">
            <Link
              className="f17 fw5 c1B1B1B tdU olN dn o70:h"
              href={LINK_SIGN_UP}
            >Create new account</Link>
          </div>
        )}
      >
        <div>
          <TextFieldA
            className="w"
            error={clicked && invalidEmail}
            label="Email"
            value={email}
            onChange={handleChangeEmail}
            onKeyPress={handleEmailEnter}
          />
        </div>
        <div className="mt15 rlv">
          {
            clicked && invalidPassword ? (<div
              className="abs sh sb-12 f11 cEC4C4C fw5 ws"
            >
              password must contain Latin letters, numbers and symbols
            </div>) : null
          }
          <TextFieldA
            className="w"
            error={clicked && invalidPassword}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={password}
            onChange={handleChangePassword}
            onKeyPress={handlePasswordEnter}
            inputRef={handlePasswordRef}
            InputProps={{
              endAdornment: (
                <IconButton
                  className="rlv sl10 mv-5*2"
                  aria-label="Toggle password visibility"
                  onClick={() => {
                    setState({
                      showPassword: !showPassword,
                    });
                  }}
                >
                  <VisibilityIcon className="c1B1B1B"/>
                </IconButton>
              ),
            }}
          />
        </div>
        <div className="mt15 ph20">
          <Link
            className="f16 fw5 c1B1B1B tdN olN dn o70:h"
            href={LINK_FORGOT_PASSWORD}
          >Forgot password?</Link>
        </div>
        <div className="mt40">
          <ButtonConnect
            className="w"
            disabled={clicked && disabled}
            onClick={handleSubmit}
          >Sign In</ButtonConnect>
        </div>
      </FormAuthBase>
    );
  };
}, {
  account: account$,
  email: login$,
  loading: loadingSignIn$,
  error: error$,
});
