export const RESPONSE_SUCCESS = 'SUCCESS';
export const RESPONSE_FAIL = 'FAIL';
export const RESPONSE_PENDING = 'PENDING';

export const STABLECOINS = [
  {id: 'EURS', name: 'EURS'},
  {id: 'USDC', name: 'USDC'},
  {id: 'USDT', name: 'USDT'},
  {id: 'TUSD', name: 'TUSD'},
  {id: 'GUSD', name: 'GUSD'},
  {id: 'BUSD', name: 'BUSD'},
  {id: 'PAX', name: 'PAX'},
];

export const CRYPTOCOINS = [
  {id: 'BTC', name: 'BTC'},
  {id: 'LNX', name: 'BTC Lightning Network'},
  {id: 'BCH', name: 'BCH'},
  {id: 'DASH', name: 'DASH'},
  {id: 'EOS', name: 'EOS'},
  {id: 'ETH', name: 'ETH'},
  {id: 'LTC', name: 'LTC'},
  {id: 'BTH', name: 'BTH'}, // Bitcoin ABC
  {id: 'BSV', name: 'BSV'}, // Bitcoin SV
  {id: 'XRP', name: 'XRP'},
  {id: 'TRON', name: 'TRON'},
  /*
  'Monero',
  */
];

export const ASSETS = [
  ...CRYPTOCOINS,
];

export const EXCHANGE_TYPES = [
  {id: 'binance', name: 'Binance'},
  {id: 'kraken', name: 'Kraken'},
  {id: 'bitfinex', name: 'Bitfinex'},
  // {id: 'bittrex', name: 'Bittrex'},
  {id: 'hitbtc', name: 'HitBTC'},
  // {id: 'tokens.net', name: 'Tokens.net'},
  // {id: 'huobi', name: 'Huobi'},
  // {id: 'okcoin', name: 'OkCoin'},
  // {id: 'poloniex', name: 'Poloniex'},
];

export const ACCOUNT_ASSET_ORDER = 'order';
export const ACCOUNT_ASSET_CONNECT = 'connect';
export const ACCOUNT_ASSET_GET = 'get';

export const FIAT_TOKENS_MAP = {
  USD: '$',
  EUR: '€',
};
