const React = require('react');
const Ripple = require('./Ripple');

module.exports = (props) => {
  return (
    <Ripple
      {...props}
      className={
        `layoutRow fvaC fhaC h46 bg1B1B1B cF r6 f14 fw6 ph30 tdN olN ff$main
          usN rlv o70.disabled ` + (props.className || '')
      }
    />
  );
};
