const React = require('react');
const PopupC = require('../../containers/PopupC');
const Ripple = require('../../dumb/Ripple');
const CaptionB = require('../../dumb/CaptionB');
const ButtonA = require('../../dumb/ButtonA');
const HeaderA = require('../../dumb/HeaderA');
const QRCode = require('../../dumb/QRCode');
const ClipboardText = require('../../dumb/ClipboardText');
const {backLocation} = require('../../../router');
const {depositShow$} = require('./emitters');

const QRCodeOptions = {
  width: 190,
  height: 190,
  colorDark: '#000000',
  colorLight: '#ffffff',
  margin: 0,
  errorCorrectionLevel: 'H',
};

module.exports = () => {
  return (
    <PopupC
      className="w740*2 w600>1*2"
      open$={depositShow$}
      render={(deposit) => {
        const exchangeType = '' + (deposit.exchangeType || '').toUpperCase();
        const code = '' + (deposit.code || '').toUpperCase();
        const address = '' + (deposit.address || '');
        return (
          <>
            <HeaderA>
              <CaptionB>{code} Deposit</CaptionB>
            </HeaderA>
            {
              code === 'EURS' ? (
                <div className="pb10">Deposit should be at least 10 EURS</div>
              ) : null
            }
            <div className="layoutRow fhaC fvaC h240 bs b0 bv1 bcE">
              {address ? (
                <QRCode
                  className="rlv"
                  text={(code === 'BTC' ? 'bitcoin:' : '') + address}
                  options={QRCodeOptions}
                />
              ) : (
                <div className="p10 cF00">empty invoice</div>
              )}
            </div>
            <div className="mt20">
              {
                exchangeType === 'HITBTC' ? (<div className="mb20">
                  Funds will be credited to your Wallet Balance at HitBTC.
                  Thereafter you should transfer these funds to
                  the Trading Balance to start trading
                </div>) : null
              }
              <div>Click to select and copy {code} Deposit Address</div>
              <ClipboardText
                text={address}
                component={Ripple}
                className="layoutRow fhaC fvaC tc hmin44 pv10 bgC ph$1 mt10
                  cr@mouse rlv"
                tooltip={(
                  <div className="layoutRow fhaC fvaC hmin40 pv10 ph20">
                    Text has been copied to clipboard
                  </div>
                )}
              >
                <div className="break ovH">
                  {address}
                </div>
              </ClipboardText>
            </div>
            <div className="mt40 layoutRow fhaE fvaC">
              <ButtonA
                className="layoutRow fhaS fvaC"
                onClick={backLocation}
              >
                <div>Close</div>
              </ButtonA>
            </div>
          </>
        );
      }}
    />
  );
};
