const React = require('react');
const CircularProgress = require('@material-ui/core/CircularProgress').default;

module.exports = (props) => {
  return (
    <div className="abs s layoutRow fvaC fhaC">
      <div>
        <div>
          <CircularProgress className="mhA c0*2"/>
        </div>
        <div className="abs s ff$main o0 z-10">
          <span className="fw3">A</span>
          <span className="fw4">A</span>
          <span className="fw5">A</span>
          <span className="fw6">A</span>
          <span className="fw7">A</span>
          <span className="fw8">A</span>
          <span className="fw9">A</span>
        </div>
        {props.children || null}
      </div>
    </div>
  );
};
