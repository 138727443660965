const React = require('react');
const Emitter = require('mn-utils/Emitter');
const combine = require('mn-utils/Emitter/combine');
const map = require('mn-utils/map');
const some = require('mn-utils/some');
const formatNum = require('mn-utils/formatNum');
const ButtonBase = require('@material-ui/core/ButtonBase').default;
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const TableB = require('../../dumb/TableB');
const LayoutD = require('../../dumb/LayoutD');
const withState = require('../../../utils/withState');
const {stats$, statsLoading$} = require('../../../emitters/stats');
const {errors$} = require('../../../emitters/popup');
const {apiDepositSCCreate} = require('../../../api/apiDepositSC');
const {Link} = require('../../../router');
const {
  LINK_DEPOSIT_HISTORY,
  LINK_WITHDRAW,
} = require('../../../constants/links');
const {depositShow$} = require('./emitters');

const ButtonA = require('./ButtonA');
const ButtonRefresh = require('./ButtonRefresh');

const depositLoading$ = new Emitter(0);
const depositLoadingEmit = depositLoading$.emit;
const loading$ = combine([
  statsLoading$,
  depositLoading$,
]).map(some);

module.exports = withState(() => {
  return (state, props) => {
    const {stats} = state;
    const items = stats.internal_sc;
    return (
      <LayoutD {...props}>
        <HeaderA>
          <CaptionB>Stablecoins internal</CaptionB>
          <ButtonRefresh
            onClick={() => {
              stats$.getUpdate();
            }}
          />
        </HeaderA>
        <WrapperWithLoading
          loading={state.loading}
        >
          <TableB
            head={(
              <>
                <div className="w60 fx1">Stablecoin</div>
                <div className="fx1">Amount</div>
                <div className="fx1">Deposit</div>
                <div className="fx1">Withdraw</div>
              </>
            )}
            body={(
              <div className="ph15">
                {map(items, (item) => {
                  const code = item.code;
                  return (
                    <div
                      key={code}
                      className="rlv ov bs b0 bb1 bcF0F0F0"
                    >
                      <div
                        className="layoutRow fhaS fvaC h66 f15 fw5
                          (ph5|tov|ws|ovH|fx1)>1"
                      >
                        <div className="w60">{code}</div>
                        <div>{formatNum(item.amount, 2)}</div>
                        <div className="layoutRow fhaS fvaC">
                          <ButtonA
                            onClick={() => {
                              depositLoadingEmit(1),
                              apiDepositSCCreate({
                                code,
                              })
                                  .finally((err) => {
                                    depositLoadingEmit(0);
                                    err && errors$.push(err.message);
                                  })
                                  .then((v) => {
                                    depositShow$.emit({
                                      code,
                                      address: v.address,
                                    });
                                  });
                            }}
                          >Deposit</ButtonA>
                        </div>
                        <div className="layoutRow fhaS fvaC">
                          <ButtonA
                            component={Link}
                            href={LINK_WITHDRAW + '/sc/' + code}
                          >Withdraw</ButtonA>
                        </div>
                      </div>
                      <ButtonBase
                        className="dB*2 abs*2 s ov olN tdN cCT"
                        component={Link}
                        href={LINK_DEPOSIT_HISTORY + '/sc/' + code}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          />
        </WrapperWithLoading>
      </LayoutD>
    );
  };
}, {
  stats: stats$,
  loading: loading$,
});
