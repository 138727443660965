const React = require('react');
const map = require('mn-utils/map');
const formatNum = require('mn-utils/formatNum');
const Collapse = require('@material-ui/core/Collapse').default;
const IconButton = require('@material-ui/core/IconButton').default;
const RefreshIcon = require('@material-ui/icons/Refresh').default;
const Ripple = require('../../dumb/Ripple');
const TableB = require('../../dumb/TableB');
const ButtonA = require('../../dumb/ButtonA');
const ClipboardText = require('../../dumb/ClipboardText');
const CloseIcon = require('../../icons/Close');
const PencilIcon = require('../../icons/Pencil');
const CopyIcon = require('../../icons/Copy');
const withState = require('../../../utils/withState');
const {
  depositShow$,
} = require('../Deposits/emitters');
const {
  Link,
} = require('../../../router');
const {
  LINK_UPDATE_EXCHANGE,
} = require('../../../constants/links');
const {
  exchangeFull$,
} = require('../../../emitters/exchangeFull');
const {
  removeOpen$,
} = require('../../../emitters/popup');

function exchangeUpdate() {
  exchangeFull$.getUpdate();
}

module.exports = withState((setState) => {
  return (state, props) => {
    const {open} = state;
    const exchange = props.exchange || {};
    const name = exchange.exchange_name || '';
    const deposits = exchange.deposits || [];
    const {id} = exchange;

    return (
      <div className="bgF bs bcEDEFF4 b1 r5 mb22">
        <Ripple
          className="layoutRow fhaB fvaC h64 ph30"
          onClick={() => {
            setState({
              open: !open,
            });
          }}
        >
          <div
            className="fw6 f18"
          >{name}</div>
          <div className="layoutRow fhaS fvaC">
            <IconButton
              className="rlv z1"
              onClick={exchangeUpdate}
            >
              <RefreshIcon className="cC5CAD0 m-4"/>
            </IconButton>
            <IconButton
              className="rlv z1"
              component={Link}
              href={LINK_UPDATE_EXCHANGE + '/' + id}
            >
              <PencilIcon
                className="w16 cC5CAD0"
              />
            </IconButton>
            <IconButton
              className="rlv z1"
              onClick={() => {
                removeOpen$.action(() => {
                  exchangeFull$.remove({id});
                });
              }}
            >
              <CloseIcon
                className="w16 cC5CAD0"
              />
            </IconButton>
          </div>
        </Ripple>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className="w"
        >
          <div className="ph30">
            <TableB
              head={(
                <>
                  <div className="w(|min)150">CRYPTO</div>
                  <div className="w(|min)150">AMOUNT</div>
                  <div className="fx1">ADDRESS TO DEPOSIT</div>
                  <div className="w(|min)150"></div>
                </>
              )}
              body={(
                <div className="ovxH ovyA">
                  <div className="ph15 pv10">
                    {map(deposits, (item, i) => {
                      const {asset} = item;
                      const address = item.address || '';
                      return (
                        <div
                          key={i}
                          className="layoutRow fhaS fvaC h62 c1B1B1B f15
                            (ph5|tov|ws|ovH)>1 bs b bb1 bcF0F0F1"
                        >
                          <div className="w(|min)150">{asset}</div>
                          <div
                            className="w(|min)150"
                          >{formatNum(item.amount, 6)}</div>
                          <div className="fx1">
                            {address}
                          </div>
                          {address ? (
                            <div className="w(|min)150 layoutRow fhaE fvaC">
                              <ButtonA
                                onClick={() => {
                                  depositShow$.emit({
                                    code: asset,
                                    address,
                                    exchangeType: exchange.exchange_type,
                                  });
                                }}
                                className="mr15"
                              >QR</ButtonA>
                              <ClipboardText
                                className="rlv z1"
                                component={IconButton}
                                tooltip={(
                                  <div
                                    className="layoutRow fhaC fvaC hmin40 pv10
                                    ph20"
                                  >Address has been copied to clipboard</div>
                                )}
                                text={address}
                              >
                                <div className="layoutRow fhaC fvaC sq24">
                                  <CopyIcon
                                    className="w22 c0"
                                  />
                                </div>
                              </ClipboardText>
                            </div>
                          ) : null}
                        </div>
                      );
                    }, [])}
                  </div>
                </div>
              )}
            />
          </div>
        </Collapse>
      </div>
    );
  };
}, {});
