const React = require('react');
const State = require('./State');

const STYLE = {
  background: 'rgba(24, 26, 28, 0.95)',
};

module.exports = ({open$, ...props}) => {
  return (
    <State
      state={{
        open: open$,
        animate: open$.delay(50),
        lazyOpen: open$.delay(500),
      }}
      render={(state) => {
        const {
          open,
          animate,
        } = state;

        return open || animate || state.lazyOpen ? (
          <div
            style={STYLE}
            className={`fixed s z10 o0 ff$main dn ovxH ovyA o100.active `
              + (open && animate ? ' active' : '')}
          >
            <div
              {...props}
              className={'layoutRow fhaC fvaC w hmin rlv p10 '
                + (props.className || '')}
            />
          </div>
        ) : null;
      }}
    />
  );
};
