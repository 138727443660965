const React = require('react');
const Ripple = require('../../dumb/Ripple');
const MenuAvatar = require('../../dumb/MenuAvatar');
const Loading = require('../../dumb/Loading');
const AppIcon = require('../AppIcon');
const withState = require('../../../utils/withState');
const {account$, loadingReconnect$} = require('../../../emitters/account');
const {NavLink, pushLocation} = require('../../../router');
const {
  LINK_TRADES,
  LINK_PAIRS,
  LINK_SIGN_IN,
  LINK_DEPOSITS,
  LINK_EXCHANGES,
  LINK_HELP,
  LINK_LOG,
  LINK_SETTINGS,
} = require('../../../constants/links');


const PROFILE_LINKS = [
  {name: 'Settings', onClick() {
    pushLocation({
      path: LINK_SETTINGS,
    });
  }},
  {name: 'Logout', onClick: account$.signOut},
];


const links = [
  {name: 'Trades', link: LINK_TRADES},
  {name: 'Pairs', link: LINK_PAIRS},
  {name: 'Deposits', link: LINK_DEPOSITS},
  {name: 'Exchanges', link: LINK_EXCHANGES},
  {name: 'Log', link: LINK_LOG},
  {name: 'Help', link: LINK_HELP},
].map((props) => {
  const {name} = props;
  return (
    <Ripple
      key={name}
      component={NavLink}
      className="layoutRow fhaC fvaC h f16 cCT tdN olN fw6.active ph20 rlv"
      href={props.link}
      activeAsParent={!props.skip}
    >{name}</Ripple>
  );
});


const Account = withState(() => {
  return (state) => {
    const {account} = state;
    return (
      <div className="ph20">
        <MenuAvatar
          items={PROFILE_LINKS}
          render={(props) => (
            <Ripple
              {...props}
              className="h50 tdN olN f16 layoutRow fvaC fhaS cCT rlv ph20 r4 ov"
            >{account.username}</Ripple>
          )}
        />
      </div>
    );
  };
}, {
  account: account$,
});


const Reconnect = withState(() => {
  return (state) => {
    return (
      state.loading ? (
        <div className="bgF z1 abs sv-10 sr20 layoutRow fhaE fvaC pl15 r5 ov">
          <div className="sq50 rlv">
            <Loading/>
          </div>
          <div className="pl15 pr20">Reconnection...</div>
        </div>
      ) : null
    );
  };
}, {
  loading: loadingReconnect$,
});


module.exports = (props) => {
  return (
    <div
      className="hmin100vh wmin1270 rlv ov"
      style={{
        background: 'linear-gradient(238.09deg, #F9F9F9 0%, #F0F0F0 95.31%)',
      }}
    >
      <div
        className="abs s"
        style={{
          backgroundImage: 'url('
            + require('../../../assets/images/bg.png') + ')',
        }}
      />
      <div className="w1270 ph15 pb20 mhA rlv">
        <div className="h116 layoutRow fvaC fhaC ff$main c1B1B1B mh-20">
          <div className="fx1 layoutRow fhaS fvaC">
            <Ripple
              component={NavLink}
              className="h70 tdN olN layoutRow fvaC fhaS cCT rlv ph20 r4 ov"
              href={LINK_SIGN_IN}
            >
              <AppIcon className="w150 fill0>path rlv st1"/>
              <div className="fw5 f16 pl25 rlv st1 lts-\.01em">
                Arbitrage
              </div>
            </Ripple>
          </div>
          <div className="fx2 layoutRow fhaC fvaC h50">
            {links}
          </div>
          <div className="fx1 layoutRow fhaE fvaC rlv">
            <Account/>
            <Reconnect/>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};
