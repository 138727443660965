const React = require('react');
const {
  LINK_DELETE_ACCOUNT,
} = require('../../../constants/links');
const {
  Link,
} = require('../../../router');
const ButtonA = require('../../dumb/ButtonA');

module.exports = () => {
  return (
    <div className="layoutRow fhaS fvaC pv50">
      <ButtonA
        className="wmin230 h55*2"
        component={Link}
        href={LINK_DELETE_ACCOUNT}
      >Delete account</ButtonA>
    </div>
  );
};
