const React = require('react');
const {LINK_LOG} = require('../../../constants/links');
const LayoutC = require('../../dumb/LayoutC');
const Log = require('./Log');
const Visualisation = require('./Visualisation');
const TradingPairList = require('./TradingPairList');
const YourBalance = require('./YourBalance');


module.exports = () => {
  return (
    <div>
      <div className="layoutRow fhaS fvaS mh-10 ph10>1 ff$main c1B1B1B">
        <div className="fx2">
          <div className="h770 w bgF ov r8">
            <Visualisation
              className="sq ov"
            />
          </div>
        </div>
        <div className="fx1">
          <LayoutC className="h390">
            <div
              className="f20 fw6"
            >Your balance</div>
            <YourBalance/>
          </LayoutC>
          <LayoutC className="h360 mt20">
            <div
              className="f15 fw6 ttU"
            >Arbitrage Pair</div>
            <TradingPairList className="mt20"/>
          </LayoutC>
        </div>
      </div>
      <LayoutC className="h360 mt20 ph40*2">
        <div className="layoutRow fhaB fvaC rlv">
          <div
            className="f15 fw6 ttU"
          >Log</div>
          <a
            className="c1B1B1B f15 tdU dn o70:h"
            href={LINK_LOG}
            rel="noreferrer"
            target="_blank"
          >Open in new window</a>
        </div>
        <div className="mt20">
          <Log/>
        </div>
      </LayoutC>
    </div>
  );
};
