const React = require('react');
const TableHeadB = require('./TableHeadB');

module.exports = ({head, body, className, ...props}) => {
  return (
    <div
      {...props}
      className={'mh-20 ' + (className || '')}
    >
      <TableHeadB>{head}</TableHeadB>
      {body}
    </div>
  );
};
