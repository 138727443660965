const React = require('react');
const Emitter = require('mn-utils/Emitter');
const isEqual = require('mn-utils/isEqual');
const {List} = require('react-virtualized/dist/commonjs/List');
const {AutoSizer} = require('react-virtualized/dist/commonjs/AutoSizer');
const {errors$} = require('../emitters/popup');
const withState = require('./withState');
const WrapperWithLoading = require('../components/dumb/WrapperWithLoading');

module.exports = (env) => {
  const {
    rowHeight,
    render,
    request,
  } = env;
  let data$ = env.data$;
  const hasList = !!data$;
  data$ || (data$ = new Emitter());
  const listLoading$ = env.listLoading$ || new Emitter(0);
  const nextLoading$ = new Emitter(0);

  function next(params) {
    if (listLoading$.getValue() || nextLoading$.getValue()) return;
    const state = data$.getValue();
    const items = state && state.list || [];
    const offset = items.length || 0;
    const count = state && state.count || offset;
    return state && offset >= count || (nextLoading$.emit(1), request({
      ...params,
      offset,
    }).finally((err, response, list) => {
      nextLoading$.emit(0);
      err
        ? (
          errors$.push(err.message),
          console.error(err)
        )
        : data$.emit({
          list: list = items.concat(response.list || []),
          count: response.count || list.length,
        });
    }));
  }

  const Items = withState((setState) => {
    let lastParams;
    return (state, props) => {
      const onWidth = props.onWidth;
      const data = state.data || {};
      const items = data.list || [];
      const length = items.length || 0;
      const count = data.count || length;
      const params = props.params || {};
      if (!hasList && !isEqual(lastParams, params)) {
        data$.emit(0);
        next(lastParams = params);
      }
      function handleRef(ref) {
        const w = ref && ref.offsetWidth || 0;
        w && onWidth && onWidth(w);
      }
      return (
        <AutoSizer className="sq">
          {({height, width}) => {
            return (
              <List
                className="olN"
                height={height}
                width={width}
                rowCount={length + (count === length ? 0 : 1)}
                rowHeight={rowHeight}
                rowRenderer={({key, index, style}) => {
                  const item = items[index] || {};
                  return (
                    <div
                      className="layoutRow fvaC"
                      key={key}
                      style={style}
                      ref={handleRef}
                    >
                      {
                        length === index ? (next(params), (
                          <PaginationLoading/>
                        )) : render(item)
                      }
                    </div>
                  );
                }}
              />
            );
          }}
        </AutoSizer>
      );
    };
  }, {
    data: data$,
  });

  const PaginationLoading = withState(() => {
    return (state) => {
      return (
        <WrapperWithLoading
          className="w"
          loading={state.loading}
        />
      );
    };
  }, {
    loading: nextLoading$,
  });

  return withState(() => {
    return (state, props) => {
      return (
        <WrapperWithLoading
          className="sq"
          innerClassName="sq"
          loading={state.loading}
        >
          <Items {...props}/>
        </WrapperWithLoading>
      );
    };
  }, {
    loading: listLoading$,
  });
};
