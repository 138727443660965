const React = require('react');

/* eslint-disable */

module.exports = (props) => {
  return (
    <div
      {...props}
      className={'bgF r8 ph30 pv25 ' + (props.className || '')}
    />
  );
};
