const React = require('react');
const ButtonA = require('../../../dumb/ButtonA');
const WrapperWithLoading = require('../../../dumb/WrapperWithLoading');

module.exports = (props) => {
  const {onSubmit} = props;
  return (
    <WrapperWithLoading
      loading={props.loading}
    >
      <div className="wmax540 pt5">
        {props.children}
        {
          onSubmit ? (
            <div className="layoutRow fhaS fvaC pv50">
              <ButtonA
                disabled={props.submitDisabled}
                className="wmin230 h55*2"
                onClick={props.onSubmit}
              >{props.submitText}</ButtonA>
            </div>
          ) : null
        }
      </div>
    </WrapperWithLoading>
  );
};
