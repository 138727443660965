const React = require('react');
const formatNum = require('mn-utils/formatNum');
const IconButton = require('@material-ui/core/IconButton').default;
const PlayIcon = require('@material-ui/icons/PlayArrow').default;
const StopIcon = require('@material-ui/icons/Stop').default;
const {
  LINK_TRADING_PAIR_EDIT,
} = require('../../../constants/links');
const {Link} = require('../../../router');
const {
  removeOpen$,
} = require('../../../emitters/popup');
const {
  ccPair$,
  ccPairLoading$,
} = require('../../../emitters/ccPair');
const PencilIcon = require('../../icons/Pencil');
const CloseIcon = require('../../icons/Close');

module.exports = require('../../../utils/virtualOutputProvider')({
  data$: ccPair$,
  listLoading$: ccPairLoading$,
  rowHeight: 45,
  render(item) {
    const ccDst = item.cc_dst;
    const ccSrc = item.cc_src;
    const active = item.active || false;
    const {id} = item;
    const ActiveIcon = active ? StopIcon : PlayIcon;
    return (
      <div className="layoutRow fhaS fvaC w c1B1B1B ph15 h45 ph5>1">
        <div
          className={'w(|min)85 fx1 o30.disactive'
            + (active ? '' : ' disactive')}
        >{ccSrc}/{ccDst}</div>
        <div
          className={'w(|min)85 fx1 o30.disactive'
            + (active ? '' : ' disactive')}
        >{item.exchange_name}</div>
        <div
          className={'w(|min)100 fx1 o30.disactive'
            + (active ? '' : ' disactive')}
        >
          {
            item.kind_of_gain === 'increase_stables'
              ? ccDst
              : ccSrc
          }
        </div>
        <div
          className={'w(|min)55 fx1 tr o30.disactive'
            + (active ? '' : ' disactive')}
        >+{formatNum(item.gain, 2)}%</div>
        <div className="w(|min)150 layoutRow fhaC fhaE">
          <IconButton
            onClick={() => {
              ccPair$.activate({
                id,
                value: !active,
              });
            }}
          >
            <div className="m-5">
              <ActiveIcon
                className="cC5CAD0 dB*2"
              />
            </div>
          </IconButton>
          <IconButton
            className={'o30.disactive' + (active ? '' : ' disactive')}
            component={Link}
            href={LINK_TRADING_PAIR_EDIT + id}
          >
            <PencilIcon
              className="w14 cC5CAD0"
            />
          </IconButton>
          <IconButton
            className={'o30.disactive' + (active ? '' : ' disactive')}
            onClick={() => {
              removeOpen$.action(() => {
                ccPair$.remove({id});
              });
            }}
          >
            <CloseIcon
              className="w16 cC5CAD0 m-1"
            />
          </IconButton>
        </div>
      </div>
    );
  },
  request: require('../../../api/apiCcPair').apiCcPairList,
});
