const React = require('react');
const ButtonBase = require('@material-ui/core/ButtonBase').default;

/* eslint-disable */

module.exports = (props) => {
  const {
		disabled,
		onChange,
		checked,
	} = props;
  return (
    <div
      className={'sq(|min)42 rlv layoutRow fvaC fhaC ovH r o50.disabled '
        + (props.className || '')
        + (disabled ? ' disabled' : '')}
      onClick={() => {
        !disabled && onChange && onChange(!checked);
      }}
    >
      <div
        className="sq30 r7 b2 bs bcCT layoutRow fvaC fhaC"
      >{checked ? icon : null}</div>
      {disabled ? null : (
        <ButtonBase
          className="dB*2 abs*2 s"
          component="div"
          centerRipple={true}
        />
      )}
    </div>
  );
};


const icon = (
  <svg
    className="w10 h8 fill0>path"
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.45208 0L3.75 4.87032L1.5475 2.76042L0 4.32505L3.75 8L10 1.56421L8.45208 0Z"
    />
  </svg>
);
