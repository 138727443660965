const React = require('react');
const LayoutC = require('../../dumb/LayoutC');
const DepositsStablecoins = require('./DepositsStablecoins');
const ExternalCryptoCurrency = require('./ExternalCryptoCurrency');
const TradesA = require('./TradesA');
const Visualisation = require('./VisualisationE');
const TradingPairList = require('./TradingPairList');


module.exports = () => {
  return (
    <div>
      <div className="layoutRow fhaS fvaS mh-10 ph10>1 ff$main c1B1B1B">
        <div className="fx2">
          <div className="h770 w ov bgF ph5 r8">
            <Visualisation
              className="sq ov"
            />
          </div>
        </div>
        <div className="fx1">
          <LayoutC className="h390">
            <div
              className="f20 fw6"
            >Liquidity</div>
            <div
              className="mt20 f16 fw5 lts-1%"
            >External Cryptocurrency</div>
            <ExternalCryptoCurrency className="mt10"/>
            <div
              className="mt30 f16 fw5 lts-1%"
            >Deposits Stablecoins</div>
            <DepositsStablecoins className="mt10"/>
          </LayoutC>
          <LayoutC className="h360 mt20">
            <div
              className="f15 fw6 ttU"
            >Arbitrage Pair</div>
            <TradingPairList className="mt20"/>
          </LayoutC>
        </div>
      </div>
      <LayoutC className="h360 mt20">
        <div
          className="f15 fw6 ttU"
        >Last Trades</div>
        <div className="mt20">
          <TradesA/>
        </div>
      </LayoutC>
    </div>
  );
};
