const React = require('react');
const map = require('mn-utils/map');
const delay = require('mn-utils/delay');
const invoke = require('mn-utils/invoke');
const withState = require('../../utils/withState');


module.exports = withState((setState) => {
  function handleToggle(open) {
    setState({open});
    delay(setState, 50, [{animate: open}]);
    delay(setState, 500, [{lazyOpen: open}]);
  }

  function handleClose(e) {
    handleToggle(false);
  }

  return (state, props) => {
    const MenuItem = props.itemComponent || 'a';
    const {open, animate} = state;
    const show = open && animate;

    return (
      <div className="rlv">
        {props.render({
          onClick() {
            handleToggle(!open);
          },
        })}
        {
          open || animate || state.lazyOpen ? (
            <div className="rlv">
              {
                show ? (
                  <div
                    className="fixed s z3000"
                    onClick={handleClose}
                  />
                ) : null
              }
              <div
                className={`abs sr st30 w230 pv10 bgF bxsh35y8c0\\.15 r6 z3001
                  o0 dn (o100|st10).active` + (show ? ' active' : '')}
              >
                <div className="dB abs sb100% sr34 bs bc00 b6 bcbF"/>
                {map(props.items, (item, i) => {
                  return (
                    <MenuItem
                      disabled={!!item.disabled}
                      className="layoutRow fhaS fvaC h38 ph25 olN tdN w dn
                        c1B1B1B f14 fwN bgF7F7F8:h"
                      key={item.id || item.name || i}
                      onClick={(e) => {
                        handleClose();
                        invoke(item, 'onClick', [e]);
                      }}
                    >{item.name}</MenuItem>
                  );
                }, [])}
              </div>
            </div>
          ) : null
        }
      </div>
    );
  };
});
