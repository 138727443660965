const CancelablePromise = require('mn-utils/CancelablePromise');
const loopMap = require('mn-utils/loopMap');
const {apiRequest} = require('./ws');

const ITEMS = loopMap(150, (i) => ({
  id: '' + 1 + i,
  exchange_name: 'Binance',
  client_id: '149ec6da-f0dc-4cdf-9fb3-8ba2dc602f60',
  arbitrator_id: '111c397a-1da2-4014-8bdc-101ddc03c53c',
  cc: {
    code: 'ETH',
    amount: 0.004345435,
    address: '',
    fee: '0',
  },
  sc: {
    code: 'USDC',
    amount: 10,
    address: '',
  },
  gain: '11.54',
  price: 169.49,
  profit: 0.00145 * (i % 2 == 1 ? 1 : -1),
  ps_fee_amount: 0.0001,
  found_in_blockchain: false,
  timestamp: '2020-04-09T14:15:45.502501+00:00',
}));

export function apiTxHistory(params) {
  params = params || {};
  const offset = params.offset || 0;
  const limit = params.limit || 50;
  return (
    process.env.MOCK
      ? CancelablePromise.delay(500, {
        list: ITEMS.slice(offset, offset + limit),
        count: ITEMS.length,
      })
      : apiRequest('tx_history', {
        offset,
        limit,
      })
  );
}
