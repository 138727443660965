const Emitter = require('mn-utils/Emitter');
const combine = require('mn-utils/Emitter/combine');
const some = require('mn-utils/some');
const isDefined = require('mn-utils/isDefined');
const {
  paramStore,
} = require('../stores');
const {
  apiAddressCreate,
  apiAddressUpdate,
  apiAddressRemove,
  apiAddressList,
} = require('../api/apiAddress');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');

export const addressEdit$ = paramStore('address');
export const addressId$ = paramStore('addressId')
    .map((v) => isDefined(v) ? ('' + v) : '', true);

export const addressUpdateLoading$ = new Emitter(0);
export const addressListLoading$ = new Emitter(0);
export const addressLoading$ = combine([
  addressListLoading$,
  addressUpdateLoading$,
]).map(some);

const getUpdate = decorateByLoadingEmit(
    apiAddressList,
    addressListLoading$,
    (emit, response) => emit(response.list || []),
);

export const address$ = (new Emitter((emit) => {
  getUpdate(emit);
}, [])).behave({
  getUpdate,
  add: decorateByLoadingEmit(apiAddressCreate, addressUpdateLoading$, update),
  edit: decorateByLoadingEmit(apiAddressUpdate, addressUpdateLoading$, update),
  remove: decorateByLoadingEmit(
      apiAddressRemove, addressUpdateLoading$, update),
});

function update() {
  address$.getUpdate();
}
