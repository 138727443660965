const React = require('react');
const Ripple = require('./Ripple');

/* eslint react/prop-types: 0 */
module.exports = (props) => {
  return (
    <Ripple
      {...props}
      className={`dn h42 wmin120 r4 layoutRow ph24 lts\\.015em tdN olN
        bgF\\.06 fhaC fvaC cF lh fw6 f15 ov tc (o50|crNA).disabled*2 `
        + (props.className || '')}
    />
  );
};
