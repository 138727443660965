const React = require('react');
const PopupA = require('../containers/PopupA');
const {error$} = require('../../emitters/popup');

module.exports = () => {
  return (
    <PopupA
      open$={error$}
      render={(errorText) => {
        return (
          <>
            <div className="mb25 pr30 f18 lh fw6">Warning</div>
            <div>{'' + (errorText || '')}</div>
          </>
        );
      }}
    />
  );
};
