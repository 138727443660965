const React = require('react');
const map = require('mn-utils/map');
const formatNum = require('mn-utils/formatNum');
const {
  LINK_EXCHANGES,
} = require('../../../constants/links');
const {Link} = require('../../../router');
const Animate = require('../../dumb/Animate');

function handleRender(v) {
  return (
    <td className="w200 tr">
      <span
        className={'pt4 pb2 ph5 mh-5 r3 dn1000 (dn|bgBBF).active'
          + (v.active ? ' active' : '')}
      >{formatNum(v.value, 6)}</span>
    </td>
  );
}

module.exports = (props) => {
  const {open} = props;
  const items = props.items || [];
  return (
    <div
      className={'hmax100 ovxH ovyA dn hmax195.open hmax0.close mh-20 ph20 '
        + (open > 0 ? 'open ' : (open < 0 ? 'close ' : ''))}
    >
      {
        items.length > 0 ? (
          <table className="b0 bsp0 f14 lh b0 (b0|h25)>td w">
            <tbody>
              {
                map(items, (v) => {
                  const name = v.code + ' ' + v.exchange;
                  return (
                    <tr key={name}>
                      <td>{name}</td>
                      <Animate
                        value={v.amount}
                        render={handleRender}
                      />
                    </tr>
                  );
                }, [])
              }
            </tbody>
          </table>
        ) : (
          <div>No Exchanges connected. <Link
            className="tdU cCT dn o70:h"
            href={LINK_EXCHANGES}
          >Connect one</Link>.</div>
        )
      }
    </div>
  );
};
