const React = require('react');
const sha512 = require('mn-utils/sha512');
const isInvalidStringLength = require('mn-utils/isInvalidStringLength');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const IconButton = require('@material-ui/core/IconButton').default;
const Visibility = require('@material-ui/icons/Visibility').default;
const VisibilityOff = require('@material-ui/icons/VisibilityOff').default;
const {backLocation} = require('../../../router');
const PopupC = require('../../containers/PopupC');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const TextFieldA = require('../../dumb/TextFieldB');
const ButtonH = require('../../dumb/ButtonH');
const withState = require('../../../utils/withState');
const {
  infos$,
} = require('../../../emitters/popup');
const {
  openChangePassword$,
  changePassword,
  changePasswordLoading$,
} = require('../../../emitters/profile');

function FieldA(props) {
  const {input, helperText} = props;
  return (
    <div className={'pv20 rlv ' + (props.className || '')}>
      {
        input.error && helperText ? (<div
          className="abs sh sb7 f11 cEC4C4C fw5"
        >{helperText}</div>) : null
      }
      <div className="abs sl st">{props.label}</div>
      <TextFieldA
        {...input}
        className="w"
      />
    </div>
  );
}

const ChangePassword = withState((setState) => {
  const changeProvider = changeProviderProvider(setState);
  const handleCurrentPassword = changeProvider('currentPassword');
  const handleNewPassword = changeProvider('newPassword');
  const handleConfirmPassword = changeProvider('confirmPassword');

  return (state) => {
    const {clicked} = state;
    const showCurrentPassword = state.showCurrentPassword || false;
    const showNewPassword = state.showNewPassword || false;
    const showConfirmPassword = state.showConfirmPassword || false;
    const currentPassword = state.currentPassword || '';
    const newPassword = state.newPassword || '';
    const confirmPassword = state.confirmPassword || '';
    const invalidCurrentPassword = isInvalidStringLength(currentPassword, 8);
    const invalidNewPassword = isInvalidStringLength(newPassword, 8);
    const invalidConfirmPassword = confirmPassword !== newPassword;
    const disabled = invalidCurrentPassword
      || invalidNewPassword
      || invalidConfirmPassword;
    const VisibilityIconCurrent = showCurrentPassword
      ? VisibilityOff
      : Visibility;
    const VisibilityIconNew = showNewPassword
      ? VisibilityOff
      : Visibility;
    const VisibilityIconConfirm = showConfirmPassword
      ? VisibilityOff
      : Visibility;

    return (
      <WrapperWithLoading
        className="w(|min)140>.__label stroke0>circle"
        loading={state.loading}
      >
        <div
          className="fw5 f18 tc c1D1E20 hmin26"
        >Password changes</div>
        <FieldA
          className="mt45"
          helperText="password must contain at least 8 characters"
          label="Current password"
          input={{
            type: showCurrentPassword ? 'text' : 'password',
            value: currentPassword,
            error: clicked && invalidCurrentPassword,
            onChange: handleCurrentPassword,
            InputProps: {
              endAdornment: (
                <IconButton
                  className="rlv sl10 mv-5*2"
                  aria-label="Toggle password visibility"
                  onClick={() => {
                    setState({
                      showCurrentPassword: !showCurrentPassword,
                    });
                  }}
                >
                  <VisibilityIconCurrent className="c1B1B1B"/>
                </IconButton>
              ),
            },
          }}
        />
        <FieldA
          helperText="password must contain at least 8 characters"
          label="New password"
          input={{
            type: showNewPassword ? 'text' : 'password',
            value: newPassword,
            error: clicked && invalidNewPassword,
            onChange: handleNewPassword,
            InputProps: {
              endAdornment: (
                <IconButton
                  className="rlv sl10 mv-5*2"
                  aria-label="Toggle password visibility"
                  onClick={() => {
                    setState({
                      showNewPassword: !showNewPassword,
                    });
                  }}
                >
                  <VisibilityIconNew className="c1B1B1B"/>
                </IconButton>
              ),
            },
          }}
        />
        <FieldA
          label="Confirm password"
          input={{
            type: showConfirmPassword ? 'text' : 'password',
            value: confirmPassword,
            error: clicked && invalidConfirmPassword,
            onChange: handleConfirmPassword,
            InputProps: {
              endAdornment: (
                <IconButton
                  className="rlv sl10 mv-5*2"
                  aria-label="Toggle password visibility"
                  onClick={() => {
                    setState({
                      showConfirmPassword: !showConfirmPassword,
                    });
                  }}
                >
                  <VisibilityIconConfirm className="c1B1B1B"/>
                </IconButton>
              ),
            },
          }}
        />
        <div className="mt60 layoutRow fhaE fvaC">
          <ButtonH
            className="bgE*2 bgD:h c1D1E20*2"
            onClick={backLocation}
          >Cancel</ButtonH>
          <ButtonH
            className="bg1D1F20*2 o90:h ml15"
            disabled={clicked && disabled}
            onClick={() => {
              disabled
                ? setState({clicked: 1})
                : changePassword({
                  old_pass: sha512(currentPassword),
                  new_pass: sha512(newPassword),
                }).then(() => {
                  backLocation();
                  infos$.push([
                    '', 'Password changed successfully',
                  ]);
                });
            }}
          >Save</ButtonH>
        </div>
      </WrapperWithLoading>
    );
  };
}, {
  loading: changePasswordLoading$,
});


module.exports = (props) => {
  return (
    <PopupC
      open$={openChangePassword$}
      render={() => {
        return (
          <ChangePassword/>
        );
      }}
    />
  );
};
