const CancelablePromise = require('mn-utils/CancelablePromise');
const {post} = require('./http');
const {apiRequest} = require('./ws');

const ENTITY_SUFFIX = 'auth/';

const {localStore} = require('../stores');
const user$ = localStore('mockUser', 0);
const userSignUp$ = localStore('mockUserSignUp', 0);
const {
  emit: emitUser,
  getValue: getUser,
} = user$;

export function apiAuthSignUp(params) {
  if (process.env.MOCK) {
    userSignUp$.emit(params);
    return CancelablePromise.delay(500, {});
  }
  return post(ENTITY_SUFFIX + 'sign-up/arbitrage', params);
}
export function apiAuthSignIn(params) {
  if (process.env.MOCK) {
    const response = {
      // blocked: true,
      need_tfa: true,
      version: 'local mock',
      username: params.email,
      need_accept_disclaimer: false,
      verified: 1,
      fullname: 'Liza',
      removal_state: {
        remaining_balances: [
          {
            asset_code: 'EURS',
            amount: 10,
          },
        ],
      },
      // lifeflow_status: 'removing_preparing',
      company: 'Thyssen-Bornemisza Art Contemporary Academy',
      account_type: 'arbitrator',
    };
    user$.emit(response);
    return CancelablePromise.delay(500, response);
  }
  return post(ENTITY_SUFFIX + 'sign-in/arbitrage', params).catch((err) => {
    if (err.code === 149) return {blocked: 1};
    throw err;
  });
}
export function apiAuthSignOut() {
  if (process.env.MOCK) {
    emitUser(0);
    return CancelablePromise.delay(500, {});
  }
  return post(ENTITY_SUFFIX + 'sign-out');
}
export function apiAuthForgotPassword(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {});
  }
  return post(ENTITY_SUFFIX + 'passrecovery/arbitrage', params);
}
export function apiAuthUser(params) {
  if (process.env.MOCK) {
    const countGetUser = window.countGetUser = 1 + (window.countGetUser || 0);
    return CancelablePromise.delay(1000, countGetUser > 2 ? {
      ...getUser(),
      lifeflow_status: 'removed',
    } : getUser());
  }
  return post(ENTITY_SUFFIX + 'user', params).then((v) => v || 0);
}
export function apiAuthUserRemove(params) {
  if (process.env.MOCK) {
    emitUser({
      ...getUser(),
      lifeflow_status: 'removing_withdrawing',
    });
    return CancelablePromise.delay(500);
  }
  return post(ENTITY_SUFFIX + 'remove/start', params);
}
export function apiAuthEmailConfirm(params) {
  if (process.env.MOCK) {
    const user = userSignUp$.getValue();
    emitUser({username: user.email});
    return CancelablePromise.delay(500, {
      token: user.action,
    });
  }
  return post(ENTITY_SUFFIX + 'email/confirm', {
    code: params.token,
  });
}
export function apiAuthResendConfirmation(params) {
  if (process.env.MOCK) {
    userSignUp$.emit(params);
    return CancelablePromise.delay(500, {});
  }
  return post(ENTITY_SUFFIX + 'email/resend_confirmation', params);
}
export function apiAuthPasswordSet(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {});
  }
  return apiRequest('password/set', params);
}
export function apiAuthTfaBind(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {
      secret: 'I53SISZC2UJQZCYIMBI2PIAF5ZMT47JA',
      qrcode_url: 'https://img-android.lisisoft.com/imgmic/2/4/3942-i-com.notes.education.test.educationalapp.jpg',
    });
  }
  return post(ENTITY_SUFFIX + 'tfa/bind', params);
}
export function apiAuthTfaConfirm(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {});
  }
  return post(ENTITY_SUFFIX + 'tfa/confirm', params).catch((err) => {
    const {code} = err;
    if (code === 149 || code === 108) return {blocked: 1};
    throw err;
  });
}
export function apiAuthTfaVerify(params) {
  if (process.env.MOCK) {
    emitUser({
      ...getUser(),
      need_tfa: 0,
    });
    return CancelablePromise.delay(500, {});
  }
  return post(ENTITY_SUFFIX + 'tfa/verify', params);
}
export function apiAuthCheckEmail(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {
      invalid: params.email !== 'mr.amirka@ya.ru',
    });
  }
  return post(ENTITY_SUFFIX + 'check-email', params).then((v) => {
    return {
      invalid: (v && v.status || '').toLowerCase() == 'invalid',
    };
  });
}
