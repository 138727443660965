const Emitter = require('mn-utils/Emitter');
const attachEvent = require('mn-utils/attachEvent');

export const scroll$ = new Emitter((emit) => {
  attachEvent(window, 'scroll', (e) => {
    emit([
      window.scrollLeft,
      window.scrollTop,
    ]);
  });
});
export const scrollX$ = scroll$.map('0');
export const scrollY$ = scroll$.map('1');
