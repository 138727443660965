const Emitter = require('mn-utils/Emitter');
const CancelablePromise = require('mn-utils/CancelablePromise');
const map = require('mn-utils/map');
const {apiWithdraw} = require('../api/apiWithdraw');
const decorateByLoading = require('./utils/decorateByLoading');
const {errors$} = require('./popup');
const _apiWithdraw = require('./account').decorateReconnect(apiWithdraw);
const {
  stats$,
} = require('./stats');

export const loadingWithdraw$ = new Emitter(0);
export const withdraw = decorateByLoading(apiWithdraw, loadingWithdraw$, () => {
  stats$.getUpdate();
});

export function withdrawAll(args) {
  loadingWithdraw$.emit(1);
  return CancelablePromise.all(map(args, (params) => {
    return _apiWithdraw(params);
  }, [])).finally((err) => {
    err && (
      errors$.push(err.message),
      console.error(err)
    );
    stats$.getUpdate();
    loadingWithdraw$.emit(0);
  });
}
