const React = require('react');
const withState = require('../../../utils/withState');
const {
  statsFiltred$,
  statsLoading$,
} = require('../../../emitters/stats');
const Ripple = require('../../dumb/Ripple');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const ArrowDownA = require('../../icons/ArrowDownA');
const DepositsCC = require('./DepositsCC');
const DepositsSC = require('./DepositsSC');
const ExternalCC = require('./ExternalCC');


const EXTERNAL_LABEL = 'External Cryptocurrency';
module.exports = withState((setState) => {
  return (state, props) => {
    const {open} = state;
    const stats = state.stats || {};
    const external = stats.external || [];
    const hasArrow = external.length > 3;
    return (
      <WrapperWithLoading loading={state.loading}>
        {hasArrow ? (
          <Ripple
            className="dB mt10 f16 fw5 lts-1% r3 ov pv10 ph15 mh-15 dn
              layoutRow fhaS fvaC"
            onClick={() => {
              setState({open: open > 0 ? -1 : (open < 0 ? 0 : 1)});
            }}
          >
            <div>{EXTERNAL_LABEL}</div>
            <ArrowDownA
              className={'ml5 w9 dn rz-90 (rz0|rx180).open (rz0|rx0).close '
                + (open > 0 ? 'open' : (open < 0 ? 'close' : ''))}
            />
          </Ripple>
        ) : (
          <div
            className="dB mt10 f16 fw5 lts-1% r3 ov pv10 ph15 mh-15 dn
              layoutRow fhaS fvaC"
          >{EXTERNAL_LABEL}</div>
        )}
        <ExternalCC
          items={external}
          open={open}
        />
        <div className={`dB mt10 f16 fw5 lts-1% r3 ov pv10 ph15 mh-15 dn
          layoutRow fhaS fvaC mt5.close` + (open > 0 ? ' close' : '')}
        >Deposits Stablecoins</div>
        <DepositsSC
          items={stats.internal_sc}
          close={open > 0}
        />
        <div className={`dB mt10 f16 fw5 lts-1% r3 ov pv10 ph15 mh-15 dn
          layoutRow fhaS fvaC mt0.close` + (open > 0 ? ' close' : '')}
        >Deposits Cryptocurrency</div>
        <DepositsCC
          items={stats.internal_cc}
          close={open > 0}
        />
      </WrapperWithLoading>
    );
  };
}, {
  loading: statsLoading$,
  stats: statsFiltred$,
});
