const React = require('react');
const combine = require('mn-utils/Emitter/combine');
const some = require('mn-utils/some');
const withState = require('./utils/withState');
const reload = require('./utils/reloadHash');
const {router$} = require('./emitters/router');
const {addressEdit$} = require('./emitters/address');
const {depositShow$} = require('./components/pages/Deposits/emitters');
const {popup$, error$, info$} = require('./emitters/popup');
const {loadingSignOut$, account$} = require('./emitters/account');
const {
  LINK_SIGN_IN,
} = require('./constants/links');
const Loading = require('./components/dumb/Loading');
const Page = require('./components/smart/Page');
const PopupInfo = require('./components/smart/PopupInfo');
const PopupError = require('./components/smart/PopupError');
const PopupDisconnect = require('./components/smart/PopupDisconnect');
const PopupRemove = require('./components/smart/PopupRemove');
const PopupConfirm = require('./components/smart/PopupConfirm');
const PopupRemoveAddress = require('./components/smart/PopupRemoveAddress');
const PopupAuth2FA = require('./components/pages/Auth/PopupAuth2FA');
const PopupDeposit = require('./components/pages/Deposits/PopupDeposit');
const PopupAddress = require('./components/pages/ManageAddresses/PopupEdit');
const PopupChangePassword
  = require('./components/pages/Settings/PopupChangePassword');


function InnerEmpty() {
  return null;
}

const Router = withState(() => {
  return (state) => {
    const {router, account} = state;
    const views = router.views || {};
    const View = views.main || InnerEmpty;
    const PublicView = views.public;
    const Wrapper = views.wrapper || Page;
    const params = router.params || {};
    const PublicWrapper = views.publicWrapper || Wrapper;

    if (state.loading) {
      return (
        <div className="rlv h100vh ovH">
          <Loading/>
        </div>
      );
    }

    return router.public || account ? (
      <Wrapper>
        <View params={params}/>
      </Wrapper>
    ) : (
      PublicView ? (
        <PublicWrapper>
          <PublicView/>
        </PublicWrapper>
      ) : (account === 0 && reload(LINK_SIGN_IN), null)
    );
  };
}, {
  router: router$,
  account: account$,
  loading: loadingSignOut$,
});

const Wrapper = withState(() => {
  return (state, props) => {
    return (
      <div
        {...props}
        className={'h100vh ovA ovyS w dn tp_filter ftBlur14.popup'
          + (state.popup ? ' popup' : '')}
      />
    );
  };
}, {
  popup: combine([
    error$,
    info$,
    popup$,
    addressEdit$,
    depositShow$,
  ]).map(some),
});

module.exports = () => {
  return (
    <>
      <Wrapper>
        <Router/>
      </Wrapper>
      <PopupAuth2FA/>
      <PopupChangePassword/>
      <PopupAddress/>
      <PopupDeposit/>
      <PopupRemove/>
      <PopupRemoveAddress/>
      <PopupInfo/>
      <PopupError/>
      <PopupDisconnect/>
      <PopupConfirm/>
    </>
  );
};
