const React = require('react');
const withState = require('../../../utils/withState');
const {Link} = require('../../../router');
const {
  exchange$,
  exchangeListLoading$,
} = require('../../../emitters/exchange');
const {
  LINK_TRADING_PAIR_EDIT,
  LINK_ADD_EXCHANGE,
} = require('../../../constants/links');
const ButtonA = require('../../dumb/ButtonA');
const LayoutD = require('../../dumb/LayoutD');
const HeaderA = require('../../dumb/HeaderA');
const CaptionB = require('../../dumb/CaptionB');
const TableC = require('../../dumb/TableC');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const Pairs = require('./PairsList');


module.exports = withState((setState) => {
  return (state) => {
    const {width} = state;
    const exchanges = state.exchanges || [];
    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Trading Pairs</CaptionB>
          <WrapperWithLoading
            loading={state.loadingExchanges}
          >
            {exchanges.length > 0 ? (
              <ButtonA
                component={Link}
                href={LINK_TRADING_PAIR_EDIT + 'new'}
                className="wmin160"
              >Add Arbitrage Pair</ButtonA>
            ) : (
              <ButtonA
                component={Link}
                href={LINK_ADD_EXCHANGE}
                className="wmin160"
              >Add Exchange first</ButtonA>
            )}
          </WrapperWithLoading>
        </HeaderA>
        <TableC
          headWidth={width}
          head={(
            <>
              <div className="w(|min)85 fx1">Pair</div>
              <div className="w(|min)85 fx1">Exchange</div>
              <div className="w(|min)100 fx1">Profit</div>
              <div className="w(|min)55 fx1 tr">Gain</div>
              <div className="w(|min)150"></div>
            </>
          )}
          body={(
            <div className="pv10 h100vh-330 hmin200">
              <Pairs
                onWidth={(_width) => {
                  width === _width || setState({width: _width});
                }}
              />
            </div>
          )}
        />
      </LayoutD>
    );
  };
}, {
  exchanges: exchange$,
  loadingExchanges: exchangeListLoading$,
});
