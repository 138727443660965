const Emitter = require('mn-utils/Emitter');
const combine = require('mn-utils/Emitter/combine');
const some = require('mn-utils/some');
const {
  apiExchangeCreate,
  apiExchangeUpdate,
  apiExchangeRemove,
  apiExchangeListFull,
} = require('../api/apiExchange');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');
const {exchange$} = require('./exchange');

export const exchangeFullUpdateLoading$ = new Emitter(0);
export const exchangeFullListLoading$ = new Emitter(0);
export const exchangeFullLoading$ = combine([
  exchangeFullUpdateLoading$,
  exchangeFullListLoading$,
]).map(some);

const getUpdate = decorateByLoadingEmit(
    apiExchangeListFull,
    exchangeFullListLoading$,
    (emit, response) => {
      const list = response.list || [];
      emit(response);
      exchange$.emit(list);
    },
);

export const exchangeFull$ = (new Emitter((emit) => {
  getUpdate(emit);
}, {
  list: [],
})).behave({
  getUpdate,
  create: decorateByLoadingEmit(
      apiExchangeCreate, exchangeFullUpdateLoading$, update),
  update: decorateByLoadingEmit(
      apiExchangeUpdate, exchangeFullUpdateLoading$, update),
  remove: decorateByLoadingEmit(
      apiExchangeRemove, exchangeFullUpdateLoading$, update),
});

function update() {
  exchangeFull$.getUpdate();
}
