const CancelablePromise = require('mn-utils/CancelablePromise');
const {apiRequest} = require('./ws');
const {localStore} = require('../stores');
const ENTITY_SUFFIX = 'settings/';

export const mockSettings$ = localStore('_mockSettings', {});
const {getValue} = mockSettings$;

export function apiSettingsSet(params) {
  if (process.env.MOCK) {
    mockSettings$.set(params);
    return CancelablePromise.delay(500, getValue());
  }
  return apiRequest(ENTITY_SUFFIX + 'set', params);
}
export function apiSettingsGet() {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, getValue());
  }
  return apiRequest(ENTITY_SUFFIX + 'list');
}
