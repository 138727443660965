const noop = require('mn-utils/noop');
const interval = require('mn-utils/async/interval');
const withState = require('../../utils/withState');

const DELAY_STEP = 50;
const COUNTER_LIMIT = 10;

module.exports = withState((setState, self, onMount) => {
  const render = self.props.render;
  let _value;
  let _valueLast;
  let _valueCurrent;
  let _cancel = noop;
  let _index;
  let _active;
  let _item;

  function cancelAnimate() {
    _cancel();
    _active = 0;
  }

  onMount(() => {
    return cancelAnimate;
  });

  return (state, props) => {
    const item = props.item;
    let value = props.value;

    value === undefined && (
      _item === item || (
        value = (_value || 0) + 1,
        _item = item
      )
    );

    _value === value || (
      _cancel(),
      _active = 1,
      _valueLast = _value || 0,
      _valueCurrent = _valueLast,
      _value = value,
      _index = 0,

      _cancel = interval(() => {
        _index++;
        COUNTER_LIMIT > _index || cancelAnimate();
        setState({
          value: _valueCurrent
            = (_value * _index + _valueLast * (COUNTER_LIMIT - _index))
            / COUNTER_LIMIT,
        });
      }, DELAY_STEP)
    );
    return render({
      item,
      active: _active,
      value: _valueCurrent,
    });
  };
}, {});
