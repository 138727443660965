const React = require('react');
const Ripple = require('../../dumb/Ripple');

module.exports = (props) => {
  return (
    <Ripple
      {...props}
      className={`layoutRow fvaC fhaC h40 c1B1B1B r fw5 f14 ph20 tdN olN tov ws
        ov z1 usN rlv bs b1 bcE4E5E7 ` + (props.className || '')}
    />
  );
};
