const React = require('react');
const Ripple = require('./Ripple');

module.exports = (props) => {
  return (
    <Ripple
      {...props}
      className={`layoutRow fhaC fvaC h56 bg30343D ph20 pv5 r8 ov f16 dn tdN
        olN usN o50.disabled o90:not[.disabled]:h lts-0\\.01em fwBold cF
        ff$main  `
        + (props.className || '')}
    />
  );
};
