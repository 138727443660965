const React = require('react');

/* eslint-disable */
module.exports = (props) => {
  return (
    <svg
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={'dB fillCT>path ' + (props.className || '')}
    >
      <path
        d="M11.6891 21H4.15545C2.41549 21 1 19.5982 1 17.875V7.28906C1 5.56589 2.41549 4.16406 4.15545 4.16406H11.6891C13.4291 4.16406 14.8445 5.56589 14.8445 7.28906V17.875C14.8445 19.5982 13.4291 21 11.6891 21ZM4.15545 5.72656C3.28555 5.72656 2.57773 6.42755 2.57773 7.28906V17.875C2.57773 18.7365 3.28555 19.4375 4.15545 19.4375H11.6891C12.559 19.4375 13.2668 18.7365 13.2668 17.875V7.28906C13.2668 6.42755 12.559 5.72656 11.6891 5.72656H4.15545ZM18 15.9219V4.125C18 2.40182 16.5845 1 14.8445 1H6.08817C5.65244 1 5.2993 1.34973 5.2993 1.78125C5.2993 2.21277 5.65244 2.5625 6.08817 2.5625H14.8445C15.7145 2.5625 16.4223 3.26349 16.4223 4.125V15.9219C16.4223 16.3534 16.7754 16.7031 17.2111 16.7031C17.6469 16.7031 18 16.3534 18 15.9219Z"
        fillOpacity="0.3"
        stroke="white"
        strokeOpacity="0.3"
        strokeWidth="0.3"
      />
    </svg>
  );
};
