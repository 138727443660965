const React = require('react');
const combine = require('mn-utils/Emitter/combine');
const get = require('mn-utils/get');
const find = require('mn-utils/find');
const some = require('mn-utils/some');
const noop = require('mn-utils/noop');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const ArrowBackIcon = require('@material-ui/icons/ArrowBack').default;
const CaptionB = require('../../dumb/CaptionB');
const ButtonA = require('../../dumb/ButtonA');
const TextFieldB = require('../../dumb/TextFieldB');
const LayoutD = require('../../dumb/LayoutD');
const HeaderA = require('../../dumb/HeaderA');
const SelectA = require('../../dumb/SelectA');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const withState = require('../../../utils/withState');
const numberHandle = require('../../../utils/numberHandle');
const {apiAddressList} = require('../../../api/apiAddress');
const {
  withdraw,
  loadingWithdraw$,
} = require('../../../emitters/withdraw');
const {
  infos$,
  errors$,
} = require('../../../emitters/popup');
const {
  statsLoading$,
  statsBalances$,
} = require('../../../emitters/stats');
const {
  addressId$,
  address$,
  addressEdit$,
} = require('../../../emitters/address');
const {
  Link,
  backLocation,
} = require('../../../router');
const {
  LINK_DEPOSITS,
  LINK_MANAGE_ADDRESS,
} = require('../../../constants/links');

const MIN_AMOUNT = 0.005;

function propName(v) {
  return v.description || v.address;
}
function handleAddAddress() {
  addressEdit$.emit({id: 'new'});
}

const CODE_NAMES = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
};

const ADRESSES_ON_EMPTY = [
  {
    id: 'new',
    description: 'Add address',
    onClick: handleAddAddress,
  },
];

function getCodeName(code) {
  return CODE_NAMES[code] || code;
}

const handleChangeAddress = addressId$.emit;

module.exports = withState((setState, self, onMount) => {
  const changeProvider = changeProviderProvider(setState);
  const handleAmount = changeProvider('amount', 0, numberHandle);
  let _addressGettingCancel = noop;
  let _code;

  function getAddresses(code) {
    setState({
      addressesLoading: 1,
    });
    _addressGettingCancel();
    _addressGettingCancel = apiAddressList({
      asset_code: code,
    })
        .then('list')
        .finally((err, list) => {
          if (err) {
            errors$.push(err.message);
          }
          setState({
            addressesLoading: 0,
            addresses: list || [],
          });
        }).cancel;
  }

  onMount(() => {
    getAddresses(self.props.params.code);
    const _addressWatchCancel = address$.on(() => {
      getAddresses(self.props.params.code);
    });
    return () => {
      _addressWatchCancel();
      _addressGettingCancel();
    };
  });

  return (state, props) => {
    const code = props.params.code;
    const {clicked} = state;
    const balance = get(find(state.balances, ['code', code]), 'amount') || 0;
    let addresses = state.addresses || [];
    const address = state.address || get(addresses, '0.id');
    const amount = state.amount || '';
    const amountFloat = parseFloat(amount);
    const invalidAmount = isNaN(amountFloat)
      || balance < amountFloat
      || amountFloat < MIN_AMOUNT;
    const invalidAddress = !address;
    const disabled = invalidAmount || invalidAddress;

    _code === code || code && getAddresses(_code = code);

    addresses.length || (addresses = ADRESSES_ON_EMPTY);

    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Withdraw</CaptionB>
        </HeaderA>
        <div>
          To add an address for {getCodeName(code)} withdrawals, click
          &quot;Add address&quot; below and fill in the address you&apos;d like
          to withdraw to. After you&apos;ve added the address, simply select it
          and enter the amount you wish to withdraw.
          <br/><br/>
          We strongly recommend that you copy + paste the address to help avoid
          errors. Please note that we are not responsible for coins mistakenly
          sent to the wrong address.
          Don&apos;t exceed your daily or monthly funding limits. If you exceed
          the limits, your account will be frozen until you can get verified for
          higher limits.
        </div>
        <WrapperWithLoading
          loading={state.loading}
        >
          <div className="layoutRow fhaS fvaC mh-10 (ph10|fx1)>1 h100 f22 c0">
            <div>{code}</div>
            <div>{balance}</div>
            <div/>
          </div>
          <div className="layoutRow fhaS fvaC mh-10 (ph10|fx1)>1">
            <WrapperWithLoading
              className="hmin0*2"
              loading={state.addressesLoading}
            >
              <div className="pv20 rlv">
                <div className="abs sl st">Address</div>
                <SelectA
                  error={clicked && invalidAddress}
                  className="w"
                  items={addresses}
                  value={address}
                  onChange={handleChangeAddress}
                  propName={propName}
                  propValue="id"
                />
              </div>
            </WrapperWithLoading>
            <div>
              <ButtonA
                onClick={handleAddAddress}
              >Add new address</ButtonA>
            </div>
            <div>
              <ButtonA
                component={Link}
                href={LINK_MANAGE_ADDRESS}
              >Manage</ButtonA>
            </div>
          </div>
          <div className="layoutRow fhaS fvaC mh-10 (ph10|fx1)>1 mt20">
            <div>
              <div className="pv20 rlv">
                <div className="abs sl st">Amount</div>
                <TextFieldB
                  error={clicked && invalidAmount}
                  className="w"
                  value={amount}
                  onChange={handleAmount}
                />
              </div>
            </div>
            <div/>
            <div/>
          </div>
          <div className="mt20 f16">
            <div>Minimum: {MIN_AMOUNT} {code}</div>
            <div className="mt5">
              Maximum: <a
                className="tdU dn o70:h"
                onClick={() => {
                  setState({
                    amount: balance,
                  });
                }}
              >BALANCE {code}</a>
            </div>
          </div>
          <div className="mt40 layoutRow fhaS fvaC">
            <ButtonA
              disabled={clicked && disabled}
              className="wmin160 layoutRow fhaC fvaC"
              onClick={() => {
                disabled
                  ? setState({clicked: 1})
                  : withdraw({
                    asset: code,
                    address,
                    amount,
                  }).then(() => {
                    backLocation();
                    infos$.push(['', 'Withdrawal Success']);
                  });
              }}
            >Withdraw</ButtonA>
          </div>
        </WrapperWithLoading>
        <div className="mt40 layoutRow fhaS fvaC">
          <ButtonA
            className="layoutRow fhaS fvaC"
            component={Link}
            href={LINK_DEPOSITS}
          >
            <ArrowBackIcon className="ml-10 dB*2 mr15"/>
            <div>Back to Deposits</div>
          </ButtonA>
        </div>
      </LayoutD>
    );
  };
}, {
  address: addressId$,
  loading: combine([
    statsLoading$,
    loadingWithdraw$,
  ]).map(some),
  balances: statsBalances$,
});
