const React = require('react');
const map = require('mn-utils/map');
const {Link} = require('../../../router');
const {
  LINK_SIGN_UP,
} = require('../../../constants/links');

module.exports = ({items, onSelect, ...props}) => {
  return (
    <div {...props}>
      <div className="cfx">
        {
          map(items, ({id, name, active}, i) => (
            <Link
              key={id || name || i}
              className={
                `lt ph15 dB f15 fw5 lh c9FACBE usN tdN ff$main dn
                  (tdU|c1B1B1B).active ` + (active ? 'active' : '')
              }
              href={LINK_SIGN_UP}
            >{name}</Link>
          ), [])
        }
      </div>
    </div>
  );
};
