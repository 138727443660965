const React = require('react');
const CancelablePromise = require('mn-utils/CancelablePromise');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const withReDelay = require('mn-utils/withReDelay');
const isEmail = require('mn-utils/isEmail');
const isInvalidStringLength = require('mn-utils/isInvalidStringLength');
const sha512 = require('mn-utils/sha512');
const IconButton = require('@material-ui/core/IconButton').default;
const Visibility = require('@material-ui/icons/Visibility').default;
const VisibilityOff = require('@material-ui/icons/VisibilityOff').default;
const {
  LINK_SIGN_UP_THANKS,
  LINK_SIGN_IN,
  LINK_HOME,
} = require('../../../constants/links');
const {
  apiAuthCheckEmail,
} = require('../../../api/apiAuth');
const validatePassword = require('../../../utils/validatePassword');
const withState = require('../../../utils/withState');
const {
  Link,
  pushLocation,
  replaceLocation,
} = require('../../../router');
const {
  account$,
  loadingSignUp$,
} = require('../../../emitters/account');
const {
  error$,
} = require('../../../emitters/popup');
const ButtonConnect = require('../../dumb/ButtonConnect');
const TextFieldA = require('../../dumb/TextFieldReg');
const FormAuthBase = require('./components/FormAuthBase');
const Loading = require('../../dumb/Loading');

module.exports = withState((setState, self, onMount) => {
  const changeProvider = changeProviderProvider(setState);
  const handleChangePassword = changeProvider('password');
  let passwordNode;
  let accountCheckEmailPromise = CancelablePromise.resolve();

  const handleValidateEmail = withReDelay((email) => {
    accountCheckEmailPromise = apiAuthCheckEmail({
      email: email.toLowerCase(),
    }).finally((err, v) => {
      setState({
        alreadyUsed: v && v.invalid || 0,
        checkEmailLoading: 0,
      });
    });
  }, 1000);

  function handlePasswordRef(node) {
    node && (passwordNode = node);
  }
  function handleEmailEnter(e) {
    e.key == 'Enter' && passwordNode && passwordNode.focus();
  }
  function handleChangeEmailWithValidate(e) {
    const email = e.target.value;
    const validEmail = isEmail(email);
    accountCheckEmailPromise.cancel();
    handleValidateEmail.cancel();
    setState({
      email,
      alreadyUsed: 0,
      checkEmailLoading: validEmail,
    });
    validEmail && handleValidateEmail(email);
  }

  onMount(() => () => {
    handleValidateEmail.cancel();
    accountCheckEmailPromise.cancel();
  });

  return (state) => {
    const {clicked, alreadyUsed, checkEmailLoading} = state;
    const showPassword = state.showPassword || 0;
    const password = state.password || '';
    const email = state.email || '';
    const invalidPasswordChars = !validatePassword(password);
    const invalidPasswordLength = isInvalidStringLength(password, 8);
    const _invalidEmail = !isEmail(email);
    const totalInvalidEmail = _invalidEmail || alreadyUsed;
    const invalidEmail = (email || clicked) && totalInvalidEmail;
    const openAlreadyUsed = alreadyUsed || checkEmailLoading;
    const invalidPassword = invalidPasswordChars || invalidPasswordLength;
    const disabled = invalidPassword || totalInvalidEmail || checkEmailLoading;
    const VisibilityIcon = showPassword ? VisibilityOff : Visibility;

    function handleSubmit() {
      passwordNode && passwordNode.blur();
      if (state.error) return;
      const _email = email.toLowerCase();
      disabled
        ? setState({clicked: 1})
        : account$.signUp({
          email: _email,
          account_type: 'arbitrator',
          password: sha512(password),
        }).then(() => {
          pushLocation({
            path: LINK_SIGN_UP_THANKS + '/' + _email,
          });
        });
    }
    function handlePasswordEnter(e) {
      e.key == 'Enter' && handleSubmit();
    }

    state.account && replaceLocation({
      path: LINK_HOME,
    });

    return (
      <FormAuthBase
        loading={state.loading}
        footer={(
          <div className="tc">
            <Link
              className="f17 fw5 c1B1B1B tdN olN dn o70:h"
              href={LINK_SIGN_IN}
            >
              Already have an Account? <span
                className="tdU"
              >Sign in here</span>
            </Link>
          </div>
        )}
      >
        <div className="rlv">
          <TextFieldA
            className="w"
            error={invalidEmail}
            label="Email"
            value={email}
            onChange={handleChangeEmailWithValidate}
            onKeyPress={handleEmailEnter}
          />
          {
            openAlreadyUsed ? (
              <div className="abs svr">
                <div className="abs svl">
                  {checkEmailLoading ? (
                    <div className="h w70">
                      <Loading/>
                    </div>
                  ) : (
                    <div
                      className="layoutRow fhaS h w250 fvaC ph15 f14 cF00"
                    >This login is already used</div>
                  )}
                </div>
              </div>
            ) : null
          }
        </div>
        <div className="rlv mt15">
          {
            clicked && invalidPassword ? (<div
              className="abs sh sb-12 f11 cEC4C4C fw5 ws"
            >{
              invalidPasswordChars
                ? 'password must contain Latin letters, numbers and symbols'
                : 'password must contain at least 8 characters'
              }
            </div>) : null
          }
          <TextFieldA
            className="w"
            error={clicked && invalidPassword}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={password}
            onChange={handleChangePassword}
            onKeyPress={handlePasswordEnter}
            inputRef={handlePasswordRef}
            InputProps={{
              endAdornment: (
                <IconButton
                  className="rlv sl10 mv-5*2"
                  aria-label="Toggle password visibility"
                  onClick={() => {
                    setState({
                      showPassword: !showPassword,
                    });
                  }}
                >
                  <VisibilityIcon className="c1B1B1B"/>
                </IconButton>
              ),
            }}
          />
        </div>
        <div className="mt40">
          <ButtonConnect
            className="w"
            disabled={clicked && disabled}
            onClick={handleSubmit}
          >Send confirmation</ButtonConnect>
        </div>
      </FormAuthBase>
    );
  };
}, {
  account: account$,
  loading: loadingSignUp$,
  error: error$,
});
