const React = require('react');
const Ripple = require('../../dumb/Ripple');

/* eslint-disable */

module.exports = (props) => {
  return (
    <Ripple
      {...props}
      className="layoutRow fvaC fhaC h40 c1B1B1B r3 f15 ph15 tdN ol tovE ws ov
        usN fw5 rlv ff$main dn bg01:h"
    >
      <div
        className="tdU tdN<1:h mr10"
      >Refresh</div>
      <svg
        className="dB w25 fillCT>path"
        viewBox="0 0 25 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0625 0C8.19896 0 3.425 4.64724 3.15104 10.4762H0L4.72604 16.675L9.375 10.4762H6.27604C6.54688 6.38838 9.93021 3.14286 14.0625 3.14286C18.3708 3.14286 21.875 6.66705 21.875 11C21.875 15.333 18.3708 18.8571 14.0625 18.8571C11.5823 18.8571 9.37292 17.6849 7.94167 15.8672L6.01771 18.4329C8.01667 20.6203 10.876 22 14.0625 22C20.1021 22 25 17.0741 25 11C25 4.92591 20.1021 0 14.0625 0Z"
        />
      </svg>
    </Ripple>
  );
};
