const React = require('react');
const map = require('mn-utils/map');
const ButtonBase = require('@material-ui/core/ButtonBase').default;

module.exports = (props) => {
  const activeValue = props.value;
  return (
    <div className={'layoutRow fhaS fvaS mh-15 ' + (props.className || '')}>
      {map(props.items, (item) => {
        const {label, value} = item;
        return (
          <div
            className={'layoutRow fhaS fvaC ph10 cr:not[.disabled] '
              + (item.disabled ? 'disabled' : '')}
            onClick={() => {
              props.onChange(value);
            }}
          >
            <div className="sq40 r ov rlv layoutRow fhaC fvaC">
              <div className="sq26 bs b1 bcE7E8EA layoutRow fhaC fvaC r ov">
                <div
                  className={'bgC4C4C4 sq16 r dn o0 xS0 (xS100|o100).active '
                    + (activeValue === value ? 'active' : '')
                  }
                />
              </div>
              {item.disabled ? null : (
                <ButtonBase
                  className="dB*2 abs*2 s"
                  component="div"
                  centerRipple={true}
                />
              )}
            </div>
            {
              label ? (
                <div className="pl3 rlv st1">{label}</div>
              ) : null
            }
          </div>
        );
      })}
    </div>
  );
};
