const React = require('react');
const routerProvider = require('mn-utils/browser/routerProvider');
const withReDelay = require('mn-utils/withReDelay');

const router = routerProvider({
  window,
  Component: React.Component,
  createElement: React.createElement,
});

/*
router.Link = router.HashLink;
router.NavLink = router.HashNavLink;
router.path$ = router.hashPath$;
router.pushLocation = router.pushHashLocation;
router.replaceLocation = router.replaceHashLocation;
*/

router.pushLocation = withReDelay(router.pushLocation, 50);
router.replaceLocation = withReDelay(router.replaceLocation, 50);

module.exports = router;
