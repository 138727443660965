const some = require('mn-utils/some');
const Emitter = require('mn-utils/Emitter');
const combine = require('mn-utils/Emitter/combine');
const {
  apiSettingsGet,
  apiSettingsSet,
} = require('../api/apiSettings');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');
const {infos$} = require('./popup');

export const settingsGetLoading$ = new Emitter(0);
export const settingsSetLoading$ = new Emitter(0);
export const settingsLoading$ = combine([
  settingsGetLoading$,
  settingsSetLoading$,
]).map(some);

const getUpdate = decorateByLoadingEmit(
    apiSettingsGet,
    settingsGetLoading$,
    (emit, response) => {
      emit(response);
    },
);

export const settings$ = (new Emitter((emit) => {
  getUpdate(emit);
}, {})).behave({
  getUpdate,
  change: decorateByLoadingEmit(
      apiSettingsSet,
      settingsSetLoading$,
      (emit, response, params, getValue) => {
        emit({
          ...getValue(),
          ...params,
        });
        infos$.push(['Settings', 'Settings has been saved.']);
      },
  ),
});
