const React = require('react');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const withState = require('../../../utils/withState');
const LayoutD = require('../../dumb/LayoutD');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const {
  exchangeFull$,
  exchangeFullUpdateLoading$,
} = require('../../../emitters/exchangeFull');
const UpdateExchangeBase = require('./UpdateExchangeBase');

module.exports = withState((setState, self, onMount) => {
  const changeProvider = changeProviderProvider(setState);
  const handleApiKey = changeProvider('apiKey');
  const handleApiSecret = changeProvider('apiSecret');
  const handleExchangeName = changeProvider('exchangeName');

  function handleExchangeType(exchangeType) {
    setState({exchangeType});
  }
  return function UpdateExchange(state, props) {
    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Add Exchange</CaptionB>
        </HeaderA>
        <UpdateExchangeBase
          {...state}
          onSave={exchangeFull$.create}
          handleApiKey={handleApiKey}
          handleApiSecret={handleApiSecret}
          handleExchangeName={handleExchangeName}
          handleExchangeType={handleExchangeType}
        />
      </LayoutD>
    );
  };
}, {
  loading: exchangeFullUpdateLoading$,
});
