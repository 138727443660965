const {errors$} = require('../popup');
const {decorateReconnect} = require('../account');

module.exports = (request, loading$, onResponse, authIgnore) => {
  const _request = authIgnore
    ? request
    : decorateReconnect(request);
  const loadingEmit = loading$.emit;
  let promise;
  return (emit, params, getValue) => {
    if (loading$.getValue()) return promise;
    loadingEmit(1);
    return promise = _request(params).finally((err, response) => {
      loadingEmit(0);
      if (err) {
        err.skip || (
          errors$.push(err.message),
          console.error(err)
        );
        return;
      }
      try {
        onResponse && onResponse(emit, response, params, getValue);
      } catch (ex) {
        console.error(ex);
        errors$.push(ex.message);
      }
    });
  };
};
