const React = require('react');
const DepositsStablecoins = require('./DepositsStablecoins');
const DepositsCryptocurrency = require('./DepositsCryptocurrency');

module.exports = (props) => {
  return (
    <>
      <DepositsStablecoins/>
      <DepositsCryptocurrency
        className="mt30"
      />
    </>
  );
};
