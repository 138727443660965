const React = require('react');
const CryptoCurrencyTable = require('./CryptoCurrencyTable');

module.exports = (props) => {
  return (
    <div {...props}>
      <CryptoCurrencyTable
        items={[
          {
            id: '00000000-0000-0000-0000-000000000001',
            name: 'BTC',
            amount: '1.53400000',
          },
          {
            id: '00000000-0000-0000-0000-000000000003',
            name: 'ETH',
            amount: 342.008651,
          },
          {
            id: '00000000-0000-0000-0000-000000000004',
            name: 'XTZ',
            amount: 134.858595,
          },
        ]}
      />
    </div>
  );
};
