const React = require('react');
const map = require('mn-utils/map');
const IconButton = require('@material-ui/core/IconButton').default;
const ArrowBackIcon = require('@material-ui/icons/ArrowBack').default;
const CaptionB = require('../../dumb/CaptionB');
const LayoutD = require('../../dumb/LayoutD');
const HeaderA = require('../../dumb/HeaderA');
const TableB = require('../../dumb/TableB');
const ButtonA = require('../../dumb/ButtonA');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const Close = require('../../icons/Close');
const Pencil = require('../../icons/Pencil');
const withState = require('../../../utils/withState');
const {
  backLocation,
} = require('../../../router');
const {
  removeAddressOpen$,
} = require('../../../emitters/popup');
const {
  address$,
  addressLoading$,
  addressEdit$,
} = require('../../../emitters/address');


module.exports = withState((setState) => {
  return (state, props) => {
    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Manage addresses</CaptionB>
          <ButtonA
            className="wmin160"
            onClick={() => {
              addressEdit$.emit({id: 'new', t: 1});
            }}
          >Add address</ButtonA>
        </HeaderA>
        <WrapperWithLoading
          loading={state.loading}
        >
          <TableB
            head={(
              <>
                <div className="fx1">Description</div>
                <div className="fx1">Address</div>
                <div className="fx1"/>
              </>
            )}
            body={(
              <div className="h100vh-424 hmin200 ovxH ovyA">
                <div className="ph15 pv10">
                  {map(state.items, (item, i) => {
                    const {id} = item;
                    return (
                      <div
                        key={id || i}
                        className="layoutRow fhaS fvaC h48
                          (ph5|tov|ws|ovH)>1 bs b bb1 bcF0F0F1"
                      >
                        <div className="fx1">{item.description}</div>
                        <div className="fx1">{item.address}</div>
                        <div className="fx1 layoutRow fhaE fvaC">
                          <IconButton
                            className="rlv z1"
                            onClick={() => {
                              addressEdit$.emit({id, t: 1});
                            }}
                          >
                            <Pencil
                              className="w16 cC5CAD0"
                            />
                          </IconButton>
                          <IconButton
                            className="rlv z1"
                            onClick={() => {
                              removeAddressOpen$.action(() => {
                                address$.remove({
                                  id,
                                });
                              });
                            }}
                          >
                            <Close
                              className="w16 cC5CAD0"
                            />
                          </IconButton>
                        </div>
                      </div>
                    );
                  }, [])}
                </div>
              </div>
            )}
          />
        </WrapperWithLoading>
        <div className="mt40 layoutRow fhaS fvaC">
          <ButtonA
            className="layoutRow fhaS fvaC"
            onClick={backLocation}
          >
            <ArrowBackIcon className="ml-10 dB*2 mr15"/>
            <div>Back</div>
          </ButtonA>
        </div>
      </LayoutD>
    );
  };
}, {
  loading: addressLoading$,
  items: address$,
});
