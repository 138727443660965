const React = require('react');
const isEqual = require('mn-utils/isEqual');
const LayoutD = require('../../dumb/LayoutD');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const Ripple = require('../../dumb/Ripple');
const TextFieldPassword = require('../../dumb/TextFieldPassword');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const ButtonF = require('../../dumb/ButtonA');
const withState = require('../../../utils/withState');
const {settings$, settingsLoading$} = require('../../../emitters/settings');
const {account$} = require('../../../emitters/account');
const {openChangePassword$} = require('../../../emitters/profile');
const version = require('../../../version.json');
const Tfa = require('./Tfa');
const AccountRemove = require('./AccountRemove');

const settingsChange = settings$.change;

module.exports = withState((setState) => {
  return (state) => {
    const settings = state.settings || {};
    const hasNeedTfa = settings.need_tfa || false;
    let enableTwoFactorAuthorization = state.enableTwoFactorAuthorization;

    enableTwoFactorAuthorization === undefined
      && (enableTwoFactorAuthorization = hasNeedTfa);

    const settingsNext = {
      ...settings,
      need_tfa: enableTwoFactorAuthorization,
    };

    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Settings</CaptionB>
        </HeaderA>
        <div className="h100vh-280 hmin200 ovxH ovyA">
          <WrapperWithLoading
            loading={state.loading}
          >
            <div className="hmin100vh-280 rlv pb100">
              <Ripple
                className="dB r4 ov pv5"
                onClick={openChangePassword$.enable}
              >
                <TextFieldPassword
                  readonly
                  label="Password"
                  className="w"
                  value="* * * * * * * * * * * * * *"
                />
              </Ripple>
              <Tfa
                className="mt15"
                enable={enableTwoFactorAuthorization || hasNeedTfa}
                onChange={(enableTwoFactorAuthorization) => {
                  setState({
                    enableTwoFactorAuthorization,
                  });

                  const settingsNext = {
                    ...settings,
                    need_tfa: enableTwoFactorAuthorization,
                  };

                  isEqual(settings, settingsNext)
                    || enableTwoFactorAuthorization
                    && settingsChange(settingsNext);
                }}
              />
              <AccountRemove/>
              <div className="abs shb h50 layoutRow fhaB fvaC">
                <div>
                  {
                    isEqual(settings, settingsNext)
                      || enableTwoFactorAuthorization ? null : (
                      <ButtonF
                        className="wmin170"
                        onClick={() => {
                          settingsChange(settingsNext);
                        }}
                      >Save</ButtonF>
                    )
                  }
                </div>
                <div className="f12 pl15">
                  Backend: {state.account.version}, Frontend: {version}
                </div>
              </div>
            </div>
          </WrapperWithLoading>
        </div>
      </LayoutD>
    );
  };
}, {
  account: account$,
  settings: settings$,
  loading: settingsLoading$,
});
