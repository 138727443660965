const {
  path$,
  routerForEmitterMapProvider,
  replaceLocation,
} = require('../router');
const {
  LINK_HOME,
} = require('../constants/links');

const PageArbitrage = require('../components/pages/Arbitrage');
const PageBlocked = require('../components/pages/Blocked');

const PageDemo = require('../components/pages/Demo');
const PublicWrapper = require('../components/pages/Demo/Base');

const PageHelp = require('../components/pages/Help');
const LogHelp = require('../components/pages/Log');

const PageManageAddresses = require('../components/pages/ManageAddresses');

const PageDeposits = require('../components/pages/Deposits');
const PageDepositHistory = require('../components/pages/Deposits/History');
// const PageDeposit = require('../components/pages/Deposits/Deposit');
const PageWithdraw = require('../components/pages/Deposits/Withdraw');

const PageSettings = require('../components/pages/Settings');
const PageDeleteAccount = require('../components/pages/Settings/DeleteAccount');

const PageExchanges = require('../components/pages/Exchanges/');
const PageAddExchange = require('../components/pages/Exchanges/AddExchange');
const PageUpdateExchange
  = require('../components/pages/Exchanges/UpdateExchange');

const PageTrades = require('../components/pages/Trades');
const PagePairs = require('../components/pages/Pairs');
const PageAddTradingPair = require('../components/pages/Pairs/Edit');

const PageSignUp = require('../components/pages/Auth/SignUp');
const PageThanks = require('../components/pages/Auth/Thanks');
const PageConfirmationEmail
  = require('../components/pages/Auth/ConfirmationEmail');
const PageSignIn = require('../components/pages/Auth/SignIn');
const PageForgotPassword = require('../components/pages/Auth/ForgotPassword');

function WrapperEmpty(props) {
  return props.children || null;
}

function setTitle(title) {
  document.querySelector('head title').innerHTML
    = 'Lunu Arbitrage &mdash; ' + title;
}

export const router$ = path$.map(routerForEmitterMapProvider([
  ['^/blocked(/([^/]+):email)?$', (params, path) => {
    setTitle('Account is blocked');
    return {
      views: {
        main: PageBlocked,
        wrapper: WrapperEmpty,
      },
      public: 1,
      params,
    };
  }],
  ['^/?deposits/?$', (params, path) => {
    setTitle('Deposits');
    return {
      views: {
        main: PageDeposits,
      },
      params,
    };
  }],
  ['^/?help/?$', (params, path) => {
    setTitle('Help');
    return {
      views: {
        main: PageHelp,
      },
      params,
    };
  }],
  ['^/?log/?$', (params, path) => {
    setTitle('Log');
    return {
      views: {
        main: LogHelp,
      },
      params,
    };
  }],
  ['^/?manage-addresses/?$', (params, path) => {
    setTitle('Manage addresses');
    return {
      views: {
        main: PageManageAddresses,
      },
      params,
    };
  }],
  ['^/?deposit-history/([^/]+):type/([^/]+):code$', (params, path) => {
    setTitle('Deposit history');
    return {
      views: {
        main: PageDepositHistory,
      },
      params,
    };
  }],
  /*
  ['^/?deposit/([^/]+):type/([^/]+):code/([^/]+):address$', (params, path) => ({
    views: {
      main: PageDeposit,
    },
    params,
  })],
  */
  ['^/?withdraw/([^/]+):type/([^/]+):code$', (params, path) => {
    setTitle('Withdraw');
    return {
      views: {
        main: PageWithdraw,
      },
      params,
    };
  }],
  ['^/?exchanges/?$', (params, path) => {
    setTitle('Exchanges');
    return {
      views: {
        main: PageExchanges,
      },
      params,
    };
  }],
  ['^/?settings/?$', (params, path) => {
    setTitle('Settings');
    return {
      views: {
        main: PageSettings,
      },
      params,
    };
  }],
  ['^/?settings/delete-account/?$', (params, path) => {
    setTitle('Delete account');
    return {
      views: {
        main: PageDeleteAccount,
      },
      params,
    };
  }],
  ['^/?add-exchange/?$', (params, path) => {
    setTitle('Add Exchange');
    return {
      views: {
        main: PageAddExchange,
      },
      params,
    };
  }],
  ['^/?update-exchange/([^/]+):id$', (params, path) => {
    setTitle('Update Exchange');
    return {
      views: {
        main: PageUpdateExchange,
      },
      params,
    };
  }],

  ['^/?sign-in/?$', (params, path) => {
    setTitle('Sign In');
    return {
      views: {
        main: PageSignIn,
        wrapper: WrapperEmpty,
      },
      params,
      public: 1,
    };
  }],
  ['^/sign-up/?$', (params, path) => {
    setTitle('Create your Lunu Account');
    return {
      views: {
        main: PageSignUp,
        wrapper: WrapperEmpty,
      },
      public: 1,
      params,
    };
  }],
  ['^/thanks(/([^/]+):email)?$', (params, path) => {
    setTitle('Thanks');
    return {
      views: {
        main: PageThanks,
        wrapper: WrapperEmpty,
      },
      public: 1,
      params,
    };
  }],
  ['^/?forgot-password/?$', (params, path) => {
    setTitle('Forgot password');
    return {
      views: {
        main: PageForgotPassword,
        wrapper: WrapperEmpty,
      },
      params,
      public: 1,
    };
  }],
  ['^/confirm_email(/([^/]+):token)?$', (params, path) => {
    setTitle('Confirm Email');
    return {
      views: {
        main: PageConfirmationEmail,
        wrapper: WrapperEmpty,
      },
      params,
      public: 1,
    };
  }],
  ['^/?trades/?$', (params, path) => {
    setTitle('Trades');
    return {
      views: {
        main: PageTrades,
      },
      params,
    };
  }],
  ['^/?pairs/?$', (params, path) => {
    setTitle('Pairs');
    return {
      views: {
        main: PagePairs,
      },
      params,
    };
  }],
  ['^/?pairs/edit(/([^/]+):id)?$', (params, path) => {
    const id = params.id;
    setTitle((id === 'new' ? 'Add' : 'Edit') + ' Trading Pairs');
    return {
      views: {
        main: PageAddTradingPair,
      },
      params,
    };
  }],
  ['^/?$', (params, path) => {
    setTitle('Dashboard');
    return {
      views: {
        main: PageArbitrage,
        public: PageDemo,
        publicWrapper: PublicWrapper,
      },
      params,
    };
  }],
], (params, path) => {
  replaceLocation({
    path: LINK_HOME,
  });
  return {
    views: {
      main: PageArbitrage,
      public: PageDemo,
      publicWrapper: PublicWrapper,
    },
    params,
  };
}));
