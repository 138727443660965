const CancelablePromise = require('mn-utils/CancelablePromise');
const filter = require('mn-utils/filter');
const map = require('mn-utils/map');
const find = require('mn-utils/find');
const {apiRequest} = require('./ws');
const {localStore} = require('../stores');
const ENTITY_PREFIX = 'cc_pair/';

const mockCcPair$ = localStore('__mockCcPairList', [
  {
    id: '1', cc_src: 'ETH', cc_dst: 'USDC',
    profit: 13.33,
    exchange_name: 'Binance', gain: 1.54, active: true,
  },
  {
    id: '2', cc_src: 'BTC', cc_dst: 'USDT',
    profit: 413.33,
    exchange_name: 'Poloniex', gain: 1.54, active: true,
  },
  {
    id: '3', cc_src: 'ETH', cc_dst: 'EURS',
    profit: 4,
    exchange_name: 'Kraken', gain: 1.54, active: true,
  },
  {
    id: '4', cc_src: 'ETH', cc_dst: 'EURS',
    profit: 4,
    exchange_name: 'Binance', gain: 1.154, active: true,
  },
  {
    id: '9b010a05-0fc2-4c6d-ba1b-6d5b34b0fc08',
    exchange_id: '7753c707-45bd-4724-a760-2a779307e7bd',
    exchange_name: 'Binance Test',
    cc_src: 'ETH',
    cc_dst: 'USDC',
    gain: '0.01',
    active: true,
    receive_to: 'd0878313-e5d3-48ea-a83b-c2671e0f9d8e',
    receive_to_address: '0x5f36f007eDbe4218C3bb7CAD085db7945E84C5e4',
    kind_of_gain: 'increase_stables',
    min_deal: '0',
    max_deal: '0',
  },
]);
let __lastId = require('../utils/getLastIdByEmitter')(mockCcPair$);

export function apiCcPairList(params) {
  params = params || {};
  const offset = params.offset || 0;
  const limit = params.limit || 50;
  if (process.env.MOCK) {
    const items = mockCcPair$.getValue();
    return CancelablePromise.delay(500, {
      list: items.slice(offset, offset + limit),
      count: items.length,
    });
  }
  return apiRequest(ENTITY_PREFIX + 'list', {
    offset,
    limit,
  });
}
export function apiCcPairGet(params) {
  if (process.env.MOCK) {
    return apiCcPairList()
        .then((v) => find(v.list, ['id', params.id]));
  }
  return apiRequest(ENTITY_PREFIX + 'get', params).then('pair');
}
export function apiCcPairCreate(params) {
  if (process.env.MOCK) {
    const item = {
      ...params,
      exchange_name: params.exchange_id,
      id: '' + __lastId++,
    };
    mockCcPair$.emit([
      item,
      ...mockCcPair$.getValue(),
    ]);
    return CancelablePromise.delay(500, item);
  }
  return apiRequest(ENTITY_PREFIX + 'add', params).then('pair');
}

export function apiCcPairRemove(params) {
  if (process.env.MOCK) {
    const id = params.id; // eslint-disable-line
    mockCcPair$.emit(filter(mockCcPair$.getValue(), (item) => id !== item.id));
    return CancelablePromise.delay(500);
  }
  return apiRequest(ENTITY_PREFIX + 'remove', params);
}

export function apiCcPairUpdate(params) {
  if (process.env.MOCK) {
    const id = params.id;
    mockCcPair$.emit(map(mockCcPair$.getValue(), (item) => {
      return id === item.id
        ? {
          ...item,
          ...params,
        }
        : item;
    }));
    return CancelablePromise.delay(500);
  }
  return apiRequest(ENTITY_PREFIX + 'update', params);
}

export function apiCcPairActivate(params) {
  const id = params.id;
  const value = params.value || false;
  if (process.env.MOCK) {
    return apiCcPairUpdate({
      id,
      active: value,
    });
  }
  return apiRequest(ENTITY_PREFIX + 'activate', {
    id,
    value,
  });
}
