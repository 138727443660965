const React = require('react');
const Popover = require('@material-ui/core/Popover').default;
const copyTextToClipboard = require('mn-utils/browser/copyTextToClipboard');
const withState = require('../../utils/withState');

module.exports = withState((setState, self) => {
  function handleOpen(e) {
    const {props} = self;
    const {onClick} = props;
    copyTextToClipboard(props.text);
    setState({anchorEl: e.currentTarget});
    onClick && onClick(e);
  }
  function handleClose() {
    setState({anchorEl: 0});
  }
  return (state, props) => {
    const {anchorEl} = state;
    const {children, component, tooltip, text, ..._props} = props; // eslint-disable-line
    const Wrapper = component || 'div';
    return (
      <>
        <Wrapper
          {..._props}
          onClick={handleOpen}
        >{children}</Wrapper>
        {
          tooltip ? (
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >{tooltip}</Popover>
          ) : null
        }
      </>
    );
  };
}, {});
