const {notifications$} = require('../api/ws');

function getNotifier(type) {
  const targetType = '/v1/processing/' + type;
  return notifications$
      .filter((v) => v.notification === targetType)
      .map('data');
}

export const notifierCash$ = getNotifier('balance/updated');
export const notifierCashFlow$ = getNotifier('cash_flow/updated');
export const notifierLog$ = getNotifier('log/pushed');
export const notifierTradesPushed$ = getNotifier('tx_history/pushed');
export const notifierTradesUpdate$ = getNotifier('tx_history/updated');
