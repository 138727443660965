const Emitter = require('mn-utils/Emitter');
const {
  apiLogList,
} = require('../api/apiLog');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');
const {notifierLog$} = require('./notifications');

export const logListLoading$ = new Emitter(0);

const getUpdate = decorateByLoadingEmit(
    apiLogList,
    logListLoading$,
    (emit, response) => emit(response),
);

export const log$ = (new Emitter((emit) => {
  getUpdate(emit);
  notifierLog$.on((item) => {
    const data = log$.getValue();
    log$.emit({
      count: (data.count || 0) + 1,
      list: [
        item,
        ...(data.list || []),
      ],
    });
  });
}, {})).behave({
  getUpdate,
});
