const React = require('react');
const map = require('mn-utils/map');
const formatNum = require('mn-utils/formatNum');
const Animate = require('../../dumb/Animate');

function handleRender(v) {
  return (
    <td className="w200 tr">
      <span
        className={'pt4 pb2 ph5 mh-5 r3 dn1000 (dn|bgBBF).active'
          + (v.active ? ' active' : '')}
      >{formatNum(v.value, 6)}</span>
    </td>
  );
}


module.exports = (props) => {
  const items = props.items || [];
  return (
    <div
      className={'h25 ov dn h0.close mh-20 ph20 '
        + (props.close ? 'close ' : '')}
    >
      {
        items.length > 0 ? (
          <table className="b0 bsp0 f14 lh b0 (b0|h25)>td w">
            <tbody>
              {
                map(items, (v, i) => {
                  const code = v.code;
                  return (
                    <tr key={code}>
                      <td>{code}</td>
                      <Animate
                        value={v.amount}
                        render={handleRender}
                      />
                    </tr>
                  );
                }, [])
              }
            </tbody>
          </table>
        ) : (
          <div>Still not received.</div>
        )
      }
    </div>
  );
};
