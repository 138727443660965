const React = require('react');

module.exports = (props) => {
  return (
    <div
      {...props}
      className={'f22 fw5 lts-\.01em ' + (props.className || '')}
    />
  );
};
