const wsAsyncRequestProvider
  = require('mn-utils/browser/wsAsyncRequestProvider');
const CancelablePromise = require('mn-utils/CancelablePromise');
const Emitter = require('mn-utils/Emitter');
const {
  API_URL_WS,
  API_PREFIX_WS,
} = require('../config/api');

const notifications$ = new Emitter();
const wsRequest = wsAsyncRequestProvider(API_URL_WS, {
  onMessage(value) {
    if (value.notification) {
      notifications$.emit(value);
      return false;
    }
  },
});

function apiRequest(method, params) {
  const handleError = params && params.handleError;
  if (handleError) {
    delete params.handleError;
  }
  return process.env.MOCK
    ? CancelablePromise.delay(1400, {})
    : (wsRequest(API_PREFIX_WS + method, params || {}).then((data) => {
      const {error} = data;
      if (error) {
        if (handleError) return handleError(error);
        throw error;
      }
      return data.response;
    }));
}

module.exports = {
  apiRequest,
  notifications$,
};
