const React = require('react');
const FormAuthBase = require('../Auth/components/FormAuthBaseB');

module.exports = (props) => {
  const params = props.params || {};
  return (
    <FormAuthBase>
      <div className="f20 fw4 tl lh140% c2 mhA wmax600 ph15">
        Account <span className="o70">{params.email}</span> is blocked.
        <br/>
        Perhaps attempts count to enter a password was exceed
        or the Lunu has detected a strange activity in your account.
        <br/>
        Please, write to our support - <a
          className="dn o70:h cCT"
          href="mailto:support@lunu.io"
        >support@lunu.io</a>.
      </div>
    </FormAuthBase>
  );
};
