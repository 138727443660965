const React = require('react');
const map = require('mn-utils/map');
const {
  PriceScaleMode,
} = require('lightweight-charts');
const ChartA = require('../../dumb/ChartA');
const withState = require('../../../utils/withState');
const {cashFlow$} = require('../../../emitters/stats');

module.exports = withState(() => {
  return (state, props) => {
    const {data} = state;
    return (
      <div className={'rlv ' + props.className}>
        <ChartA
          className="abs s5"
          init={(chart) => {
            chart.timeScale().setVisibleRange({
              from: (new Date()).getTime() * 0.001 - 30 * 24 * 3600,
              to: (new Date()).getTime() * 0.001,
            });
          }}
          options={chartOptions}
          actions={{
            addHistogramSeries: [
              {
                id: 1,
                options: lineOptions,
                data,
              },
            ],
          }}
        />
      </div>
    );
  };
}, {
  data: cashFlow$.map((flow) => map(flow, (v) => ({
    time: v.date,
    value: parseFloat(v.amount),
  }), [])),
});


const lineOptions = {
  base: 0,
  color: '#C4C4C4',
  lineWidth: 2,
  priceFormat: {
    type: 'volume',
  },
  overlay: true,
};
const chartOptions = {
  localization: {
    locale: 'en-EN',
  },
  crosshairMarkerVisible: false,
  handleScroll: {
    mouseWheel: false,
    pressedMouseMove: false,
  },
  handleScale: {
    axisPressedMouseMove: false,
    mouseWheel: false,
    pinch: false,
  },
  priceScale: {
    position: 'right',
    alignLabels: false,
    scaleMargins: {
      top: 0.4,
      bottom: 0.01,
    },
    borderVisible: false,
    mode: PriceScaleMode.IndexedTo100,
  },
  timeScale: {
    borderVisible: false,
  },
  layout: {
    backgroundColor: '#FFFFFF',
    lineColor: '#CCCCCC',
    textColor: '#222222',
  },
  watermark: {
    color: 'rgba(0, 0, 0, 0)',
    visible: false,
  },
  crosshair: {
    color: '#CCCCCC',
    mode: 1,
    vertLine: {
      color: '#6A5ACD',
      width: 0.5,
      style: 1,
    },
    horzLine: {
      color: '#6A5ACD',
      width: 0.5,
      style: 0,
    },
  },
  grid: {
    vertLines: {
      color: '#F8F8F8',
    },
    horzLines: {
      color: '#F8F8F8',
    },
  },
};
