const React = require('react');
const map = require('mn-utils/map');
const formatNum = require('mn-utils/formatNum');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const TableB = require('../../dumb/TableB');
const LayoutD = require('../../dumb/LayoutD');
const withState = require('../../../utils/withState');
const {
  stats$,
  statsLoading$,
} = require('../../../emitters/stats');
const {Link} = require('../../../router');
const {
  LINK_WITHDRAW,
} = require('../../../constants/links');
const ButtonA = require('./ButtonA');
const ButtonRefresh = require('./ButtonRefresh');


module.exports = withState(() => {
  return (state, props) => {
    const {stats} = state;
    const items = stats.internal_cc;
    return (
      <LayoutD {...props}>
        <HeaderA>
          <CaptionB>Cryptocurrency internal</CaptionB>
          <ButtonRefresh
            onClick={() => {
              stats$.getUpdate();
            }}
          />
        </HeaderA>
        <WrapperWithLoading
          loading={state.loading}
        >
          <TableB
            head={(
              <>
                <div className="w60 fx1">Cryptocurrency</div>
                <div className="fx1">Amount</div>
                <div className="fx1"></div>
                <div className="fx1">Withdraw</div>
              </>
            )}
            body={(
              <div className="ph15">
                {map(items, (item) => {
                  const code = item.code;
                  return (
                    <div
                      key={code}
                      className="rlv ov bs b0 bb1 bcF0F0F0"
                    >
                      <div
                        className="layoutRow fhaS fvaC h66 f15 fw5
                          (ph5|tov|ws|ovH|fx1)>1"
                      >
                        <div className="w60">{code}</div>
                        <div>{formatNum(item.amount, 6)}</div>
                        <div className="layoutRow fhaS fvaC">

                        </div>
                        <div className="layoutRow fhaS fvaC">
                          <ButtonA
                            component={Link}
                            href={LINK_WITHDRAW + '/cc/' + code}
                          >Withdraw</ButtonA>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          />
        </WrapperWithLoading>
      </LayoutD>
    );
  };
}, {
  stats: stats$,
  loading: statsLoading$,
});
