const React = require('react');

module.exports = (props) => {
  return (
    <div
      {...props}
      className={'bgF r8 pv40 ph50 ff$main c1B1B1B ' + (props.className || '')}
    />
  );
};
