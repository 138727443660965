const React = require('react');
const ButtonBase = require('@material-ui/core/ButtonBase').default;

/* eslint react/prop-types: 0 */
module.exports = function Ripple({
  disabled, component, children, onClick,
  ...props
}) {
  const Outer = component || 'a';
  return (
    <Outer
      {...props}
      className={'rlv crD.disabled@mouse ' + (disabled ? 'disabled ' : '')
        + (props.className || '')}
    >
      {children}
      {disabled ? null : (
        <ButtonBase
          onClick={disabled ? null : (onClick || null)}
          className="dB*2 abs*2 s"
          component="div"
        />
      )}
    </Outer>
  );
};
