const React = require('react');
const TextField = require('@material-ui/core/TextField').default;

/* eslint react/prop-types: 0 */

module.exports = function TextFielA(props) {
  return (
    <TextField
      margin="none"
      variant="outlined"
      {...props}
      className={
        `(pv16|hmin50|bxzBB|ph18)>.*OutlinedInput-input
        (dn|r6|b2)>.*-notchedOutline
        (bcE7E8EA)>.*OutlinedInput-root:not[.*-error]>.*-notchedOutline
        (bcC7C8CA)>(.*-focused|:h):not[.*-error]>.*-notchedOutline*2
        (f14|ff$main)>.*OutlinedInput-root `
          + (props.className || '')
      }
    />
  );
};
