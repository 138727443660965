export const LINK_HOME = '/';
export const LINK_TRADE = '/';
export const LINK_DEPOSITS = '/deposits';
export const LINK_EXCHANGES = '/exchanges';
export const LINK_SETTINGS = '/settings';
export const LINK_DELETE_ACCOUNT = '/settings/delete-account';
export const LINK_DEPOSIT_HISTORY = '/deposit-history';
export const LINK_SIGN_IN = '/sign-in';
export const LINK_SIGN_UP = '/sign-up';
export const LINK_SIGN_UP_THANKS = '/thanks';
export const LINK_FORGOT_PASSWORD = '/forgot-password';
export const LINK_DEPOSIT = '/deposit';
export const LINK_WITHDRAW = '/withdraw';
export const LINK_UPDATE_EXCHANGE = '/update-exchange';
export const LINK_ADD_EXCHANGE = '/add-exchange';
export const LINK_TRADES = '/trades';
export const LINK_AUTH_PIN = '/pin';
export const LINK_PAIRS = '/pairs';
export const LINK_TRADING_PAIR_EDIT = '/pairs/edit/';
export const LINK_LOG = '/log';
export const LINK_HELP = '/help';
export const LINK_MANAGE_ADDRESS = '/manage-addresses';
export const LINK_BLOCKED = '/blocked';
