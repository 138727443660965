const React = require('react');
const CryptoCurrencyTable = require('./CryptoCurrencyTable');

module.exports = (props) => {
  return (
    <div {...props}>
      <CryptoCurrencyTable
        items={[
          /*
          {
            id: '00000000-0000-0000-0000-000000000001',
            name: 'USDT',
            amount: 2484.98,
          },
          {
            id: '00000000-0000-0000-0000-000000000002',
            name: 'USDC',
            amount: 0.15,
          },
          {
            id: '00000000-0000-0000-0000-000000000005',
            name: 'TUSD',
            amount: 6.8,
          },
          */
          {
            id: '00000000-0000-0000-0000-000000000006',
            name: 'EURS',
            amount: 12444.45,
          },
        ]}
      />
    </div>
  );
};
