const React = require('react');
const Ripple = require('./Ripple');
const ClipboardText = require('./ClipboardText');
const CopyIcon = require('../icons/Copy');

/* eslint react/prop-types: 0 */

module.exports = ({name, value, ...props}) => {
  return (
    <div {...props}>
      {
        name ? (
          <div
            className="f18 fw5 ph20"
          >{name}</div>
        ) : null
      }
      <ClipboardText
        text={value}
        component={Ripple}
        className="layoutRow fhaB fvaC bg0\.1 r3 ph20 mt20 fw5 f14 lts\.065em
          h56 ov"
        tooltip={(
          <div className="layoutRow fhaC fvaC hmin40 pv10 ph20">
            Text has been copied to clipboard
          </div>
        )}
      >
        <div className="fx1 wmin0 tovE ws ov pt1">{value}</div>
        <CopyIcon className="w19 c0"/>
      </ClipboardText>
    </div>
  );
};
