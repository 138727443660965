const React = require('react');
const map = require('mn-utils/map');
const {Link} = require('../../../router');
const TabsA = require('./TabsA');
const ButtonA = require('../../dumb/ButtonA');
const {
  LINK_SIGN_UP,
} = require('../../../constants/links');

const PAIR_LIST = [
  {
    id: 1, cc_src: 'ETH', cc_dst: 'USDC',
    profit: 13.33,
    exchange_id: 'Binance', gain: 1.54, active: false,
  },
  {
    id: 2, cc_src: 'BTC', cc_dst: 'USDT',
    profit: 413.33,
    exchange_id: 'Poloniex', gain: 1.54, active: false,
  },
  {
    id: 3, cc_src: 'ETH', cc_dst: 'EURS',
    profit: 4,
    exchange_id: 'Kraken', gain: 1.54, active: true,
  },
];

module.exports = (props) => {
  return (
    <div {...props}>
      <TabsA
        className="mh-15"
        items={[
          {id: 'All', name: 'All', active: 1},
          {id: 'Active', name: 'Active'},
          {id: 'Paused', name: 'Paused'},
          {id: 'Deleted', name: 'Deleted'},
        ]}
      />
      <div className="mh-20 mt20">
        <div
          className="layoutRow fhaS fvaC w h44 bgF8F8F9 r4 cB8BDC0 ttU f13
            fw5 lts-\.015em ph15 pr25 (fx1|ph5)>1"
        >
          <div className="w(|min)85">Pair</div>
          <div className="w(|min)85">Exchange</div>
          <div className="w(|min)100">Profit</div>
          <div className="w(|min)55 tr">Gain</div>
        </div>
        <div className="ovyA ovxH hmax135">
          {map(PAIR_LIST, (item, i) => {
            const currency = item.cc_dst;
            return (
              <div
                key={item.id || i}
                className="layoutRow fhaS fvaC w c1B1B1B h45 ph15
                  (fx1|ph5)>1"
              >
                <div className="w(|min)85">{item.cc_src}/{currency}</div>
                <div className="w(|min)85">{item.exchange_id}</div>
                <div className="w(|min)100">{item.profit} {currency}</div>
                <div className="w(|min)55 tr">+{item.gain}%</div>
              </div>
            );
          }, [])}
        </div>
      </div>
      <div className="mt10 layoutRow fvaC fhaE">
        <ButtonA
          component={Link}
          href={LINK_SIGN_UP}
          className="wmin160"
        >Add Arbitrage Pair</ButtonA>
      </div>
    </div>
  );
};
