const React = require('react');
const formatDate = require('mn-utils/formatDate');
const map = require('mn-utils/map');
const formatNum = require('mn-utils/formatNum');
const withState = require('../../../utils/withState');
const TabsA = require('./TabsA');
const {Link} = require('../../../router');
const {
  LINK_SIGN_UP,
} = require('../../../constants/links');

const {
  lastDeals$,
} = require('../../../emitters/demo');

const TRADES_CATEGORIES = [
  {id: '1', name: 'My Deals', active: 1},
  {id: '2', name: 'All Deals'},
];

module.exports = withState((setState) => {
  return (state, props) => {
    return (
      <>
        <div className="layoutRow fhaB fvaC rlv">
          <TabsA
            className="mh-15"
            items={TRADES_CATEGORIES}
          />
          <Link
            className="c1B1B1B f15 tdU dn o70:h"
            href={LINK_SIGN_UP}
          >Open in new window</Link>
        </div>
        <div className="mt20">
          <div className="mh-20">
            <div
              className="layoutRow fhaS fvaC w h44 bgF8F8F9 r4 cB8BDC0 ttU f13
                fw5 lts-\.015em ph15 pr25 ph5>1"
            >
              <div className="fx200">Time</div>
              <div className="fx220">Buy</div>
              <div className="fx140">Sell</div>
              <div className="fx160">Exchange</div>
              <div className="fx140">Gain</div>
              <div className="fx140">Price</div>
              <div className="fx140 tr">Profit</div>
            </div>
            <div className="pv10 hmax200 ovxH ovyA">
              {map(state.deals, (item, i) => {
                const sellCurrency = item.sell_currency;
                return (
                  <div
                    key={i}
                    className="w layoutRow fhaS fvaC h45 ph15 ph5>1"
                  >
                    <div className="fx200 ws tov ov">
                      {formatDate(new Date(item.time),
                          'dd.mm.yyyy, HH:MM:ss')}
                    </div>
                    <div className="fx220 ws tov ov">
                      {item.buy_amount} {item.buy_currency}
                    </div>
                    <div className="fx140 ws tov ov">
                      {formatNum(item.sell_amount, 2)} {sellCurrency}
                    </div>
                    <div className="fx160 ws tov ov">
                      {item.exchange}
                    </div>
                    <div className="fx140 ws tov ov">{item.gain}%</div>
                    <div
                      className="fx140 ws tov ov"
                    >{item.price}</div>
                    <div
                      className="fx140 ws tov ov tr"
                    >{formatNum(item.profit, 4)} {sellCurrency}</div>
                  </div>
                );
              }, [])}
            </div>
          </div>
        </div>
      </>
    );
  };
}, {
  deals: lastDeals$,
});
