const React = require('react');
const ButtonBase = require('@material-ui/core/ButtonBase').default;
const IconButton = require('@material-ui/core/IconButton').default;
const {backLocation} = require('../../router');
const CloseIcon = require('../icons/Close');
const State = require('./State');

const STYLE = {
  background: 'rgba(24, 26, 28, 0.95)',
};

module.exports = (props) => {
  const {open$} = props;
  return (
    <State
      state={{
        open: open$,
        animate: open$.delay(50),
        lazyOpen: open$.delay(500),
      }}
      render={(state) => {
        const {open, animate} = state;
        const show = open && animate;
        const value = open || animate || state.lazyOpen;
        return value ? (
          <div
            style={STYLE}
            className={'fixed s z2001 ovxH ovyA o0 dn o100.active'
              + (show ? ' active' : '')}
          >
            <div className="tbl sq w hmin rlv p10 p5@m5">
              <div>
                <ButtonBase
                  className="dB*2 abs*2 s dn"
                  component="div"
                  onClick={backLocation}
                />
                <div
                  className={`
                    w wmax540 mhA bgF ph70 ph15@m4 pv40 rlv xS0 dn400 r8
                    c1B1B1B fw5 lts-\\.01.em f15 lh160%
                    (delay250|xS100).open (dn400|o0|(delay250|o100).open)>1
                  ` + (show ? ' open' : '')}
                >
                  <div>
                    <div className="abs srt15 z1">
                      <IconButton
                        className="c0*2 bgE8E9EA*2 bgD8D9DA:h*2 r ov"
                        component="div"
                        onClick={backLocation}
                      >
                        <CloseIcon className="sq10 m-2"/>
                      </IconButton>
                    </div>
                    {props.render(value)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null;
      }}
    />
  );
};
