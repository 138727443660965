const React = require('react');
const trim = require('mn-utils/trim');
const Emitter = require('mn-utils/Emitter');
const ButtonA = require('../../dumb/ButtonA');
const TextFieldB = require('../../dumb/TextFieldB');
const LayoutD = require('../../dumb/LayoutD');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const {
  apiHelp,
} = require('../../../api/apiHelp');
const withState = require('../../../utils/withState');
const decorateByLoading = require('../../../emitters/utils/decorateByLoading');
const {
  infos$,
} = require('../../../emitters/popup');

const helpRequestLoading$ = new Emitter(0);
const helpRequest = decorateByLoading(
    apiHelp,
    helpRequestLoading$,
    () => {
      infos$.push(['Help', 'Your question has been sent.']);
    },
);

module.exports = withState((setState) => {
  function handleChangeQuestion(e) {
    setState({
      question: e.target.value,
    });
  }
  return (state) => {
    const {
      clicked,
    } = state;
    const question = state.question || '';
    const invalidQuestion = !trim(question);

    return (
      <LayoutD>
        <WrapperWithLoading loading={state.loading}>
          <HeaderA>
            <CaptionB>Support</CaptionB>
          </HeaderA>
          <div className="f16 mt10">Enter your question:</div>
          <div className="mt10">
            <TextFieldB
              error={clicked && invalidQuestion}
              placeholder="Ask us something"
              className="w"
              multiline
              rows={8}
              value={question}
              onChange={handleChangeQuestion}
            />
          </div>
          <div className="pt45 layoutRow fhaS fvaC">
            <ButtonA
              disabled={clicked && invalidQuestion}
              className="wmin170"
              onClick={() => {
                invalidQuestion
                  ? setState({clicked: 1})
                  : helpRequest({
                    question,
                  }).then(() => {
                    setState({
                      clicked: 0,
                      question: '',
                    });
                  });
              }}
            >Ask</ButtonA>
          </div>
        </WrapperWithLoading>
      </LayoutD>
    );
  };
}, {
  loading: helpRequestLoading$,
});
