const React = require('react');
const Loading = require('./Loading');

/* eslint react/prop-types: 0 */

module.exports = ({
  loading, children, _ref,
  innerClassName,
  ...props
}) => {
  return (
    <div
      {...props}
      ref={_ref || null}
      className={'rlv hmin50 ' + (props.className || '')}
    >
      <div
        className={
          'ftBlur4.loading dn '
          + (innerClassName || '')
          + (loading ? ' loading' : '')}
      >{children || null}</div>
      {loading ? (<Loading/>) : null}
    </div>
  );
};
