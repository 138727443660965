const React = require('react');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const {
  replaceLocation,
} = require('../../../router');
const {
  LINK_SIGN_IN,
  LINK_HOME,
} = require('../../../constants/links');
const {
  account$,
  accountUpdate,
} = require('../../../emitters/account');

let stop;
module.exports = (props) => {
  const params = props.params || {};
  const token = params.token;

  stop || token && (stop = 1, account$.emailConfirm({
    token,
    handleError() {
      replaceLocation({
        path: LINK_SIGN_IN,
      });
      throw {skip:1}; // eslint-disable-line
    },
  }).then(() => {
    accountUpdate().then((v) => {
      v && replaceLocation({
        path: LINK_HOME,
      });
    });
  }));

  return (
    <WrapperWithLoading
      className="h100vh"
      loading={true}
    />
  );
};
