const React = require('react');
const AppIcon = require('../../../smart/AppIcon');
const WrapperWithLoading = require('../../../dumb/WrapperWithLoading');


module.exports = (props) => {
  return (
    <div
      className="fixed s ovA"
      style={{
        background: 'linear-gradient(238.09deg, #F9F9F9 0%, #F0F0F0 95.31%)',
      }}
    >
      <div
        className="hmin layoutRow fvaC fhaC pv50 rlv"
        style={{
          backgroundImage: 'url('
            + require('../../../../assets/images/bg.png') + ')',
        }}
      >
        <div>
          <div className="h80 layoutRow fhaC fvaE">
            <div>
              <div className="layoutRow fvaC fhaC">
                <AppIcon className="w150 fill0>path"/>
              </div>
              <div
                className="c1B1B1B fw5 f16 tc lts\.005em mt10"
              >Arbitrage</div>
            </div>
          </div>
          <WrapperWithLoading
            loading={props.loading}
          >
            <div className="hmin620 hmin400@x-800 pv20 layoutRow fvaC fhaC">
              <div>
                <div
                  className="w(|max)300 mhA"
                >{props.children || null}</div>
                {props.subfooter || null}
              </div>
            </div>
          </WrapperWithLoading>
          <div className="h80 layoutRow fhaC fvaS">
            <div>{props.footer || null}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
