const delay = require('mn-utils/delay');
const React = require('react');
const {backLocation} = require('../../router');
const {confirm$} = require('../../emitters/popup');
const ButtonH = require('../dumb/ButtonH');
const PopupA = require('../containers/PopupA');

module.exports = () => {
  return (
    <PopupA
      open$={confirm$}
      render={(options) => {
        options = options || {};
        const title = '' + (options.title || '');
        const text = '' + (options.text || '');
        return (
          <>
            {title ? (<div
              className="mb25 pr30 f18 lh fw6"
            >{title}</div>) : null}
            {text ? (<div
              dangerouslySetInnerHTML={{__html: text}}
            />) : null}
            <div className="mt35 layoutRow fhaC fvaC mhA">
              <div className="cfx mh-8 (lt|ph8)>1">
                <div>
                  <ButtonH
                    className="bg0*2 bg0D:h*2"
                    onClick={backLocation}
                  >{options.textCancel || 'Cancel'}</ButtonH>
                </div>
                <div>
                  <ButtonH
                    className="bgD*2 bgE:h*2 c1D1E20*2"
                    onClick={() => {
                      backLocation();
                      delay(options.callback, 100);
                    }}
                  >{options.textConfirm || 'Yes'}</ButtonH>
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};
