const React = require('react');
const PopupA = require('../containers/PopupA');
const {info$} = require('../../emitters/popup');

module.exports = () => {
  return (
    <PopupA
      open$={info$}
      render={(info) => {
        info = info || [];
        const name = '' + (info[0] || '');
        const text = '' + (info[1] || '');
        return (
          <>
            {name ? (<div className="mb25 pr30 f18 lh fw6">{name}</div>) : null}
            {text ? (<div>{text}</div>) : null}
          </>
        );
      }}
    />
  );
};
