const Emitter = require('mn-utils/Emitter');
const combine = require('mn-utils/Emitter/combine');
const delay = require('mn-utils/delay');
const {backLocation} = require('../router');
const {paramStore} = require('../stores');
const behaveArray = {
  push(emit, v, get) {
    delay(emit, 100, [
      (get() || []).concat([v]),
    ]);
  },
};

export const popup$ = paramStore('popup');

export const infos$ = paramStore('info').behave(behaveArray);
export const info$ = infos$.map('0');

export const errors$ = paramStore('errors').behave(behaveArray);
export const error$ = errors$.map('0');

export const disconnectOpen$ = popup$.is('disconnect');
const confirmOpen$ = popup$.is('confirm');
const confirmOptions$ = new Emitter();
export const confirm$ = combine([
  confirmOpen$,
  confirmOptions$,
]).map((v) => {
  const options = v[1];
  return v[0] && options ? options : 0;
});

export function confirmOpen(options, callback) {
  confirmOptions$.emit({
    ...options,
    callback,
  });
  confirmOpen$.emit(1);
}
confirmOpen$.getValue() && backLocation();


// remove popup
export const removeOpen$ = popup$.is('remove').behave({
  action(emit, callback) {
    removeCallback$.emit(callback);
    emit(1);
  },
});
export const removeCallback$ = new Emitter(0);

removeOpen$.getValue() && backLocation();


// remove address popup
export const removeAddressOpen$ = popup$.is('removeAddress').behave({
  action(emit, callback) {
    removeAddressCallback$.emit(callback);
    emit(1);
  },
});

export const removeAddressCallback$ = new Emitter(0);
removeAddressOpen$.getValue() && backLocation();
