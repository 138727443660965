const React = require('react');
const {
  toDataURL,
} = require('qrcode');

/* eslint-disable */

class QRCode extends React.Component {
  constructor(props) {
    super(props);
    const self = this;
    let _text;
    let _promise;
    function update(text, options) {
      if (text === _text) return;
      _promise = toDataURL(_text = text, options).then((qrcodeUrl) => {
        _promise && self.setState({
          qrcodeUrl,
        });
      }, (error) => {
        console.error(error);
      });
    }
    self.state = {};
    self.componentWillUnmount = () => {
      if (_promise) _promise = null;
    };
    self.render = () => {
      const {text, options, ...props} = self.props;
      const {qrcodeUrl} = self.state;
      if (!text) return null;
      update(text, options);
      return qrcodeUrl ? (
        <img
          {...props}
          src={qrcodeUrl}
        />
      ) : null;
    };
  }
}

module.exports = QRCode;
