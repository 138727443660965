const React = require('react');
const trim = require('mn-utils/trim');
const get = require('mn-utils/get');
const delay = require('mn-utils/delay');
const {validate: validateVA} = require('wallet-address-validator');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const withState = require('../../../utils/withState');
const {
  LINK_MANAGE_ADDRESS,
} = require('../../../constants/links');
const {
  assetsAvailableLoading$,
  assets$,
} = require('../../../emitters/assets');
const {
  Link,
  backLocation,
} = require('../../../router');
const {decorateReconnect} = require('../../../emitters/account');
const {
  addressEdit$,
  address$,
  addressUpdateLoading$,
  addressId$,
} = require('../../../emitters/address');
const {
  infos$,
  error$,
} = require('../../../emitters/popup');
const {
  apiAddressGet,
} = require('../../../api/apiAddress');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const TextFieldA = require('../../dumb/TextFieldB');
const ButtonH = require('../../dumb/ButtonH');
const SelectA = require('../../dumb/SelectA');
const PopupC = require('../../containers/PopupC');

const addressGet = decorateReconnect(apiAddressGet);

const AddressEditSmart = withState((setState, self, onMount) => {
  const changeProvider = changeProviderProvider(setState);
  const handleChangeDescription = changeProvider('description');
  const handleChangeAddress = changeProvider('address');

  const params = self.props.params || {};
  const id = '' + params.id;
  const showManage = !params.t;
  const hasEdit = id !== 'new';
  function handleChangeCode(code) {
    setState({asset_code: code});
  }

  hasEdit && onMount(() => {
    setState({getLoading: 1});
    return addressGet({id}).finally((err, item) => {
      err && error$.push(err);
      setState({
        ...(item || {}),
        getLoading: 0,
      });
    }).cancel;
  });

  return (state) => {
    const {assets, hasEdit, clicked} = state;
    const description = state.description || '';
    const address = state.address || '';
    const code = (state.asset_code || get(assets, '0.id') || '').toUpperCase();
    const invalidDescription = !trim(description);

    const invalidAddress = !validateVA(address, code === 'EURS' ? 'ETH' : code);
    const disabled = invalidDescription || invalidAddress;

    return (
      <WrapperWithLoading
        className="stroke0>circle"
        loading={state.loading || state.getLoading
          || state.assetsAvailableLoading}
      >
        <div
          className="fw5 f18 tc c1D1E20 hmin26"
        >{hasEdit ? 'Edit address' : 'Add new address'}</div>
        <div className="mt45 pv20 rlv">
          <div className="abs sl st">Description</div>
          <TextFieldA
            className="w"
            error={clicked && invalidDescription}
            type="text"
            value={description}
            onChange={handleChangeDescription}
          />
        </div>
        <div className="pv20 rlv">
          <div className="abs sl st">Address</div>
          <TextFieldA
            className="w"
            error={clicked && invalidAddress}
            type="text"
            value={address}
            onChange={handleChangeAddress}
          />
        </div>
        <div className="pv20 rlv">
          <div className="abs sl st">Asset</div>
          <SelectA
            className="w"
            items={assets}
            propName="name"
            propValue="id"
            value={code}
            onChange={handleChangeCode}
            disabled={hasEdit}
          />
        </div>
        <div className="mt60 layoutRow fhaB fvaC">
          <ButtonH
            className="bgE*2 bgD:h c1D1E20*2"
            onClick={backLocation}
          >Cancel</ButtonH>
          <div className="layoutRow fhaE fvaC">
            {
              showManage ? (
                <ButtonH
                  className="bgE*2 bgD:h c1D1E20*2"
                  component={Link}
                  href={LINK_MANAGE_ADDRESS}
                >Manage</ButtonH>
              ) : null
            }
            <ButtonH
              className="bg1D1F20*2 o90:h ml15"
              disabled={clicked && disabled}
              onClick={() => {
                if (disabled) {
                  setState({clicked: 1});
                  return;
                }
                const params = {
                  description,
                  address,
                  asset_code: code,
                };

                (
                  hasEdit
                    ? address$.edit({
                      ...params,
                      id,
                    })
                    : address$.add(params)
                ).then((v) => {
                  console.log(v);
                  backLocation();
                  delay(() => {
                    addressId$.emit(v.id);
                    infos$.push([
                      'Information',
                      hasEdit
                        ? 'Address has been updated'
                        : 'Address has been appended',
                    ]);
                  }, 50);
                });
              }}
            >Save</ButtonH>
          </div>
        </div>
      </WrapperWithLoading>
    );
  };
}, {
  loading: addressUpdateLoading$,
  assetsAvailableLoading: assetsAvailableLoading$,
  assets: assets$,
});

module.exports = () => {
  return (
    <PopupC
      open$={addressEdit$}
      render={(params) => {
        return (
          <AddressEditSmart
            params={params}
          />
        );
      }}
    />
  );
};
