const React = require('react');

module.exports = (props) => {
  return (
    <div
      {...props}
      className={'layoutRow fhaB fvaC h100 mt-40 ' + (props.className || '')}
    />
  );
};
