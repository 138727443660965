const React = require('react');
const {backLocation} = require('../../router');
const ButtonH = require('../dumb/ButtonH');
const PopupB = require('../containers/PopupB');
const {
  removeOpen$,
  removeCallback$,
} = require('../../emitters/popup');

function handleRemove() {
  const remove = removeCallback$.getValue();
  backLocation();
  remove();
}

module.exports = () => {
  return (
    <PopupB
      open$={removeOpen$}
    >
      <div className="cF tc">
        <div className="f18 lh168% fw5">
          Would you like to delete item?
        </div>
        <div className="mt35 layoutRow fhaC fvaC mhA">
          <div className="cfx mh-8 (lt|ph8)>1">
            <div>
              <ButtonH
                className="bgF\.12:h"
                onClick={backLocation}
              >Cancel</ButtonH>
            </div>
            <div>
              <ButtonH
                className="bgEFF0F0*2 bgF:h c1D1E20*2"
                onClick={handleRemove}
              >Delete</ButtonH>
            </div>
          </div>
        </div>
      </div>
    </PopupB>
  );
};
