const CancelablePromise = require('mn-utils/CancelablePromise');
const Emitter = require('mn-utils/Emitter');
const map = require('mn-utils/map');
const isNumber = require('mn-utils/isNumber');
const {localStore} = require('../stores');
const {
  apiDemo,
  DEFAULT_DATA,
} = require('../api/apiDemo');

export const demoLoading$ = new Emitter(0);
let promise = new CancelablePromise();
function getDemo() {
  if (demoLoading$.getValue()) return promise;
  demoLoading$.emit(1);
  return promise = apiDemo().finally(() => {
    demoLoading$.emit(0);
  });
}

export const demo$ = localStore('__demo', (emit) => {
  emit(getDemo());
}, DEFAULT_DATA).behave({
  getUpdate: (emit) => {
    emit(getDemo());
  },
});

export const lastDeals$ = demo$.map('last_deals');
export const chart$ = demo$.map((v) => {
  return map(v.chart, (item) => {
    const {date} = item;
    return {
      time: isNumber(date)
        ? date
        : ((new Date(date)).getTime() * 0.001),
      value: parseFloat(item.amount),
    };
  }, []);
});

export const total$ = demo$.map('total');
