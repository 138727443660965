const React = require('react');
const delay = require('mn-utils/delay');
const withState = require('../../../../utils/withState');
const {
  account$,
  accountUpdateWithoutLoading,
} = require('../../../../emitters/account');
const LayoutD = require('../../../dumb/LayoutD');
const CaptionB = require('../../../dumb/CaptionB');
const HeaderA = require('../../../dumb/HeaderA');
const Loading = require('../../../dumb/Loading');
const FormDelete = require('./FormDelete');
const FormWithdraw = require('./FormWithdraw');

const LIFEFLOW_REMOVED = 'removed';

const LIFEFLOW_REMOVING_PREPARING = 'removing_preparing';
const LIFEFLOW_REMOVING_WAIT_PENDING_OPERATION = 'removing_wait_pending_operation'; // eslint-disable-line
const LIFEFLOW_REMOVING_WITHDRAWING = 'removing_withdrawing';
const LIFEFLOW_REMOVING_FINALIZING = 'removing_finalizing';
const LIFEFLOW_MAP = {
  [LIFEFLOW_REMOVING_PREPARING]: 'Preparations are underway to delete the account', // eslint-disable-line
  [LIFEFLOW_REMOVING_WAIT_PENDING_OPERATION]: 'The account is waiting for all operations with associated resources to complete', // eslint-disable-line
  [LIFEFLOW_REMOVING_WITHDRAWING]: 'The account is awaiting the final withdrawal of user funds', // eslint-disable-line
  [LIFEFLOW_REMOVING_FINALIZING]: 'Completing account deletion',
};

const LIFEFLOW_STATUS_CHECK_TIMEOUT = 10000;

function isLoadingRemove(lifeflowStatus) {
  return lifeflowStatus != LIFEFLOW_REMOVED;
}

function lifeflowStatusCheck() {
  function base() {
    _cancel = accountUpdateWithoutLoading().finally((err, v) => {
      if (!v || isLoadingRemove((v.lifeflow_status || '').toLowerCase())) {
        return;
      }
      _cancel = delay(base, LIFEFLOW_STATUS_CHECK_TIMEOUT);
    }).cancel;
  }
  _cancel = delay(base, LIFEFLOW_STATUS_CHECK_TIMEOUT);
  return () => {
    _cancel();
  };
}

const DeleteAccount = withState((setState, self, onMount) => {
  onMount(lifeflowStatusCheck);

  return (state) => {
    const account = state.account || {};
    const removalState = account.removal_state || {};
    const lifeflowStatus = (account.lifeflow_status || '').toLowerCase();
    const removingText = LIFEFLOW_MAP[lifeflowStatus];

    if (lifeflowStatus == LIFEFLOW_REMOVED) {
      return (<div>Account already deleted</div>);
    }

    if (removingText) {
      return (
        <>
          <div className="mb20">
            Account deletion process is already running
          </div>
          <div className="bs bcE b bv1 pv10 mb20 rlv">
            <div className="f11 mb5">Lifeflow current state</div>
            <div>{removingText}</div>
            {
              isLoadingRemove(lifeflowStatus) ? (
                <div className="sq50 abs svr">
                  <Loading/>
                </div>
              ) : null
            }
          </div>
          {
            lifeflowStatus == LIFEFLOW_REMOVING_WITHDRAWING ? (
              <FormWithdraw
                remainingBalances={removalState.remaining_balances}
              />
            ) : null
          }
        </>
      );
    }

    return (<FormDelete
      hasTfa={account.tfa_protected}
    />);
  };
}, {
  account: account$,
});


module.exports = (props) => {
  return (
    <LayoutD>
      <HeaderA>
        <CaptionB>Delete account</CaptionB>
      </HeaderA>
      <div className="hmin100vh-280">
        <DeleteAccount/>
      </div>
    </LayoutD>
  );
};
