const React = require('react');
const TableHeadB = require('./TableHeadB');

module.exports = ({head, body, headWidth, className, ...props}) => {
  return (
    <div
      {...props}
      className={'mh-20 ' + (className || '')}
    >
      <div className="bgF8F8F9 r4">
        <TableHeadB style={headWidth ? {
          width: '' + headWidth + 'px',
        } : null}>{head}</TableHeadB>
      </div>
      {body}
    </div>
  );
};
