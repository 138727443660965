const React = require('react');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const sha512 = require('mn-utils/sha512');
const IconButton = require('@material-ui/core/IconButton').default;
const Visibility = require('@material-ui/icons/Visibility').default;
const VisibilityOff = require('@material-ui/icons/VisibilityOff').default;
const validatePassword = require('../../../../utils/validatePassword');
const withState = require('../../../../utils/withState');
const {
  accountDelete,
  loadingSignIn$,
} = require('../../../../emitters/account');
const TextFieldReg = require('../../../dumb/TextFieldReg');
const OneTimePassword = require('../../../dumb/OneTimePassword');
const FormWrapper = require('./FormWrapper');

module.exports = withState((setState) => {
  const changeProvider = changeProviderProvider(setState);
  const handleChangePassword = changeProvider('password');
  function handleChangeOneTimePassword(oneTimePassword) {
    setState({
      oneTimePassword,
    });
  }

  return (state, props) => {
    const {hasTfa} = props;
    const {clicked} = state;
    const showPassword = state.showPassword || 0;
    const password = state.password || '';
    const oneTimePassword = state.oneTimePassword || '';
    const invalidPassword = !validatePassword(password);
    const invalidOneTimePassword = hasTfa && oneTimePassword.length !== 6;
    const disabled = invalidPassword || invalidOneTimePassword;
    const VisibilityIcon = showPassword ? VisibilityOff : Visibility;

    function handleSubmit() {
      disabled
        ? setState({clicked: 1})
        : accountDelete({
          password: sha512(password),
          tfa_code: hasTfa ? oneTimePassword : null,
        }).then(() => {
          setState({
            password: '',
            oneTimePassword: '',
          });
        });
    }

    return (
      <FormWrapper
        loading={state.loading}
        onSubmit={handleSubmit}
        submitText="Delete"
        submitDisabled={disabled && clicked}
      >
        <div className="rlv">
          {
            clicked && invalidPassword ? (<div
              className="abs sh sb-12 f11 cEC4C4C fw5 ws"
            >
              password must contain Latin letters, numbers and symbols
            </div>) : null
          }
          <TextFieldReg
            className="w"
            error={clicked && invalidPassword}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            value={password}
            onChange={handleChangePassword}
            InputProps={{
              endAdornment: (
                <IconButton
                  className="rlv sl10 mv-5*2"
                  aria-label="Toggle password visibility"
                  onClick={() => {
                    setState({
                      showPassword: !showPassword,
                    });
                  }}
                >
                  <VisibilityIcon className="c1B1B1B"/>
                </IconButton>
              ),
            }}
          />
        </div>
        {
          hasTfa ? (
            <OneTimePassword
              className="mt35"
              error={clicked && invalidOneTimePassword}
              value={oneTimePassword}
              onChange={handleChangeOneTimePassword}
              onEnd={handleSubmit}
            />
          ) : null
        }
      </FormWrapper>
    );
  };
}, {
  loading: loadingSignIn$,
});
