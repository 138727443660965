const React = require('react');
const {disconnectOpen$} = require('../../emitters/popup');
const {account$} = require('../../emitters/account');
const PopupA = require('../containers/PopupA');
const Button = require('../dumb/ButtonA');

module.exports = () => {
  return (
    <PopupA
      open$={disconnectOpen$}
      render={() => {
        return (
          <>
            <div className="mb25 pr30 f18 lh fw6">Warning</div>
            <div>
              For some reasons your connection is closed. Please sign in again.
            </div>
            <div className="layoutRow fhaC mt35">
              <Button
                onClick={() => {
                  account$.signOut();
                }}
                className="wmin190 h48*2 f15*2"
              >Sign in</Button>
            </div>
          </>
        );
      }}
    />
  );
};
