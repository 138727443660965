const CancelablePromise = require('mn-utils/CancelablePromise');
const {apiRequest} = require('./ws');
const ENTITY_PREFIX = 'help/';

export function apiHelp(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {});
  }
  return apiRequest(ENTITY_PREFIX + 'ask', params);
}
