const React = require('react');

/* eslint-disable */

module.exports = (props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={'dB fillCT>path ' + (props.className || '')}
    >
      <path
        d="M16 13.4587L10.4567 7.986L15.9233 2.46467L13.4587 0L7.984 5.54533L2.444 0.0766667L0 2.52067L5.54733 8.014L0.0766667 13.556L2.52067 16L8.012 10.4547L13.5353 15.9233L16 13.4587Z"
      />
    </svg>
  );
};
