const React = require('react');
const throttle = require('mn-utils/throttle');
const getTime = require('mn-utils/time');
const Collapse = require('@material-ui/core/Collapse').default;
const CheckboxB = require('../../dumb/CheckboxB');
const FieldId = require('../../dumb/FieldId');
const ButtonF = require('../../dumb/ButtonA');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const OneTimePassword = require('../../dumb/OneTimePassword');
const withState = require('../../../utils/withState');
const {
  bind2FALoading$,
  bind2FA,
  activate2FA,
} = require('../../../emitters/profile');

const __bind2FA = throttle(bind2FA, 1000);

const TfaInner = withState((setState, self, onMount) => {
  onMount(() => {
    return __bind2FA().finally((error, bind) => {
      setState({
        error,
        bind,
        time: getTime(),
      });
    }).cancel;
  });

  function handleChangeOneTimePassword(oneTimePassword) {
    setState({
      oneTimePassword,
    });
  }

  return (state, props) => {
    const oneTimePassword = state.oneTimePassword || '';
    const time = state.time;
    const bind = state.bind || {};
    const qrcodeUrl = bind.qrcode_url || '';
    const disabled = oneTimePassword.length !== 6;
    const onChange = props.onChange;

    function handleSubmit() {
      disabled || activate2FA({
        code: oneTimePassword,
      })
          .finally((err) => {
            const needTfa = !err;
            setState({
              oneTimePassword: '',
              needTfa,
            });
            needTfa && onChange(needTfa);
          });
    }

    return (
      <WrapperWithLoading
        className={props.className || ''}
        loading={state.loading}
      >
        <FieldId value={bind.secret}/>
        <div
          className="layoutRow fhaC fvaC mt15 hmin200 bs b bv2 bcF2 ov pv10"
        >
          {qrcodeUrl ? (
            <img
              className="wmax w200"
              src={'/v1/auth/tfa/qrcode?time=' + time}
            />
          ) : null}
        </div>
        <OneTimePassword
          className="mt45"
          value={oneTimePassword}
          onChange={handleChangeOneTimePassword}
          onEnd={handleSubmit}
        />
        <div className="mt40 layoutRow fhaS fvaC">
          <ButtonF
            disabled={disabled}
            className="wmin190"
            onClick={handleSubmit}
          >Activate 2FA</ButtonF>
        </div>
      </WrapperWithLoading>
    );
  };
}, {
  loading: bind2FALoading$,
});


module.exports = withState((setState) => {
  return (state, props) => {
    const hasNeedTfa = props.enable || false;
    const onChange = props.onChange;
    let enable = state.enable;
    enable === undefined && (enable = hasNeedTfa);

    return (
      <div className={props.className || ''}>
        <div className="layoutRow fhaS fvaC ml-5">
          <CheckboxB
            className="c575960"
            onChange={() => {
              const _enable = !enable;
              setState({enable: _enable});
              onChange(hasNeedTfa && _enable);
            }}
            checked={enable}
          />
          <div className="ml20 c2 f16">
            Enable Two-Factor authorization
          </div>
        </div>
        {hasNeedTfa ? null : (
          <Collapse
            in={enable}
            timeout="auto"
            unmountOnExit
            className="w"
          >
            <TfaInner
              onChange={props.onChange}
            />
          </Collapse>
        )}
      </div>
    );
  };
}, {});
