const CancelablePromise = require('mn-utils/CancelablePromise');
const {API_URL_HTTP} = require('../config/api');

function httpMethodProvider(method) {
  return function(apiMethod, data) {
    const handleError = data && data.handleError;
    if (handleError) {
      delete data.handleError;
    }
    return CancelablePromise.resolve(fetch(API_URL_HTTP + apiMethod, {
      method,
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data ? JSON.stringify(data) : null,
    }))
        .then((response) => {
          const {status} = response;
          return response.json().catch((e) => {
            throw status > 199 && status < 400
                ? e
                : new Error('HTTP status: ' + status);
          });
        })
        .then((response) => {
          const {error} = response;
          if (error) {
            if (handleError) return handleError(error);
            throw error;
          }
          return response.response;
        })
        .finally((error, response) => {
          error && console.error(error);
        });
  };
}

export const post = httpMethodProvider('POST');
export const get = httpMethodProvider('GET');
