const React = require('react');
const formatNum = require('mn-utils/formatNum');
const formatDate = require('mn-utils/formatDate');
const ArrowBackIcon = require('@material-ui/icons/ArrowBack').default;
const CaptionB = require('../../dumb/CaptionB');
const Ripple = require('../../dumb/Ripple');
const ButtonA = require('../../dumb/ButtonA');
const LayoutD = require('../../dumb/LayoutD');
const HeaderA = require('../../dumb/HeaderA');
const TableC = require('../../dumb/TableC');
const withState = require('../../../utils/withState');
const {
  apiDepositSCHistory,
} = require('../../../api/apiDepositSC');
const {Link} = require('../../../router');
const {
  LINK_DEPOSITS,
} = require('../../../constants/links');


const History = require('../../../utils/virtualOutputProvider')({
  rowHeight: 48,
  render(item) {
    const {address} = item;
    return (
      <Ripple
        className="ph15 w olN tdN cCT"
        href={'https://etherscan.io/address/' + address}
        target="_blank"
      >
        <div
          className="layoutRow fhaS fvaC h48 (ph5|tovE|ws|ovH)>1 bs b bb1
            bcF0F0F1"
        >
          <div className="ws tov ov fx1">
            {formatDate(new Date(item.datetime),
                'dd.mm.yyyy, HH:MM:ss')}
          </div>
          <div className="fx1">{formatNum(item.amount, 2)}</div>
          <div className="fx2">{address}</div>
        </div>
      </Ripple>
    );
  },
  request: apiDepositSCHistory,
});

module.exports = withState((setState) => {
  return (state, props) => {
    const {width} = state;
    const params = props.params || {};
    const {code} = params;
    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>{code} Deposit History</CaptionB>
        </HeaderA>
        <TableC
          headWidth={width}
          head={(
            <>
              <div className="fx1">Time</div>
              <div className="fx1">Amount</div>
              <div className="fx2">Address</div>
            </>
          )}
          body={(
            <div className="h100vh-408 hmin200 pv10 ovxH ovyA">
              <History
                params={{
                  code,
                }}
                onWidth={(_width) => {
                  width === _width || setState({width: _width});
                }}
              />
            </div>
          )}
        />
        <div className="mt20 layoutRow fhaS fvaC">
          <ButtonA
            className="layoutRow fhaS fvaC"
            component={Link}
            href={LINK_DEPOSITS}
          >
            <ArrowBackIcon className="ml-10 dB*2 mr15"/>
            <div>Back to Deposits</div>
          </ButtonA>
        </div>
      </LayoutD>
    );
  };
}, {});
