const React = require('react');
const trim = require('mn-utils/trim');
const {
  EXCHANGE_TYPES,
} = require('../../../constants');
const withState = require('../../../utils/withState');
const {
  backLocation,
} = require('../../../router');
const {
  infos$,
} = require('../../../emitters/popup');
const SelectA = require('../../dumb/SelectA');
const TextFieldA = require('../../dumb/TextFieldB');
const ButtonA = require('../../dumb/ButtonA');
const ButtonB = require('../../dumb/ButtonB');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');


module.exports = withState((setState) => {
  return (state, props) => {
    const {
      clicked,
      invalidCheck,
    } = state;
    const {
      onSave,
      id,
    } = props;

    const apiKey = props.apiKey || '';
    const apiSecret = props.apiSecret || '';
    const exchangeName = props.exchangeName || '';

    const exchangeType = props.exchangeType || EXCHANGE_TYPES[0].id;
    const invalidApiKey = !trim(apiKey);
    const invalidApiSecret = !trim(apiSecret);
    const invalidExchangeName = !trim(exchangeName);
    const disabled = invalidExchangeName || invalidApiKey || invalidApiSecret
      || invalidCheck;

    return (
      <WrapperWithLoading
        className="wmax540"
        loading={props.loading}
      >
        <div>
          <div className="mb5">Name</div>
          <TextFieldA
            className="w"
            placeholder="Binance 1"
            error={clicked && invalidExchangeName}
            value={exchangeName}
            onChange={props.handleExchangeName}
          />
        </div>
        <div className="mt20">
          <div className="mb5">Exchange type</div>
          <SelectA
            disabled={id}
            className="w"
            items={EXCHANGE_TYPES}
            propValue="id"
            propName="name"
            value={exchangeType}
            onChange={props.handleExchangeType}
          />
        </div>
        <div className="mt20">
          <div className="mb5">API Key</div>
          <TextFieldA
            className="w"
            placeholder="0xFe4b206A7B02bBD6780..."
            error={clicked && invalidApiKey || invalidCheck}
            value={apiKey}
            onChange={(v) => {
              invalidCheck && setState({invalidCheck: 0});
              props.handleApiKey(v);
            }}
          />
        </div>
        <div className="mt20">
          <div className="mb5">API Secret</div>
          <TextFieldA
            className="w"
            placeholder="BD67800df9E14B88B1707..."
            error={clicked && invalidApiSecret || invalidCheck}
            value={apiSecret}
            onChange={(v) => {
              invalidCheck && setState({invalidCheck: 0});
              props.handleApiSecret(v);
            }}
          />
        </div>
        <div className="layoutRow fhaE fvaC mt30">
          <ButtonB
            className="wmin120"
            onClick={backLocation}
          >Cancel</ButtonB>
          <ButtonA
            className="ml20"
            disabled={clicked && disabled}
            onClick={() => {
              const params = {
                exchange_type: exchangeType,
                exchange_name: exchangeName,
                api_key: apiKey,
                api_secret: apiSecret,
                handleError(err) {
                  if (err.code === 146) return {invalid: 1};
                  throw new Error(err.message);
                },
              };
              id && (params.id = id);
              disabled
                ? setState({clicked: 1})
                : onSave(params).then((v) => {
                  v.invalid
                    ? setState({
                      invalidCheck: 1,
                    })
                    : (
                      backLocation(),
                      infos$.push([
                        'Exchange',
                        'Exchange is successfully saved',
                      ])
                    );
                });
            }}
          >Test and Save</ButtonA>
        </div>
      </WrapperWithLoading>
    );
  };
}, {});
