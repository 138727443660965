const CancelablePromise = require('mn-utils/CancelablePromise');
const map = require('mn-utils/map');
const loopMap = require('mn-utils/loopMap');
const filter = require('mn-utils/filter');
const {apiRequest} = require('./ws');
const {localStore} = require('../stores');
const ENTITY_PREFIX = 'deposit/';

const MOCK_ITEMS = loopMap(150, (i) => {
  return {
    id: '' + (1 + i), // id записи.
    arbitrator_id: 2, // id арбитражёра.
    code: 'USDC', // код стейблкойна.
    amount: 10, // пришедшая сумма.
    address: '2467df3243', // адрес, использовавшийся для зачисления.
    datetime: '2020-04-09T14:15:45.502501+00:00',
  };
});

const mockDepositSC$ = localStore('mockDepositSCList', [
  {
    id: '00000000-0000-0000-0000-000000000001',
    stablecoin: 'USDT',
    amount: 2484.98,
    address: '8e45sr50r205064932424020414232430532',
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    stablecoin: 'USDC',
    amount: 0.15,
    address: 'fdfdfa4371368egsid26e828203364',
  },
  {
    id: '00000000-0000-0000-0000-000000000005',
    stablecoin: 'TUSD',
    amount: 6.8,
    address: '30864743569234365723p28456798699855432',
  },
  {
    id: '00000000-0000-0000-0000-000000000006',
    stablecoin: 'EURS',
    amount: 12444.45,
    address: '0096596365434052323034547385435434343254254',
  },
]);
let __lastId = require('../utils/getLastIdByEmitter')(mockDepositSC$);

export function apiDepositSCList(params) {
  return (process.env.MOCK
    ? CancelablePromise.delay(500, {
      list: [...mockDepositSC$.getValue()],
    })
    : apiRequest(ENTITY_PREFIX + 'list', params)
  ).then((v) => {
    return {
      ...v,
      list: map(v.list, (v) => {
        const code = v.stablecoin;
        return {
          ...v,
          id: v.id,
          arbitrator_id: v.arbitrator_id,
          code,
          name: code,
          amount: v.amount || 0,
          address: v.address || '',
        };
      }),
    };
  });
}
export function apiDepositSCHistory(params) {
  params = params || {};
  const offset = params.offset || 0;
  const limit = params.limit || 50;
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {
      list: MOCK_ITEMS.slice(offset, offset + limit),
      count: MOCK_ITEMS.length,
    });
  }
  return apiRequest(ENTITY_PREFIX + 'history', {
    offset,
    limit,
    sc_code: params.code,
  }).then((v) => {
    return {
      list: map(v.list, (v) => {
        return {
          ...v,
          id: v.id, // id записи.
          arbitrator_id: v.arbitrator_id, // id арбитражёра.
          code: v.sc_code, // код стейблкойна.
          amount: v.sc_amount, // пришедшая сумма.
          address: v.sc_address, // адрес, использовавшийся для зачисления.
        };
      }),
    };
  });
}

export function apiDepositSCCreate(params) {
  const {code} = params;
  if (process.env.MOCK) {
    mockDepositSC$.emit([
      {
        ...params,
        sc_code: code,
        address: 'gsfggdgfdnsddashgh876756rfgbcr6rv8gyhu87r6r56dfhgfe7rd',
        id: __lastId++,
      },
      ...mockDepositSC$.getValue(),
    ]);
    return CancelablePromise.delay(500, {
      address: 'gsfggdgfdnsddasjifdb792brrye80few0t43673ejqwe39wru0asjd'
          .toUpperCase(),
    });
  }
  return apiRequest(ENTITY_PREFIX + 'create', {
    sc_code: code,
  }).then((v) => {
    return {
      code: v.sc_code,
      address: v.deposit_address,
    };
  });
}
export function apiDepositSCRemove(params) {
  if (process.env.MOCK) {
    console.log({params});
    const {id} = params; // eslint-disable-line
    mockDepositSC$.emit(filter(mockDepositSC$.getValue(), (item) => {
      return id !== item.id;
    }));
    return CancelablePromise.delay(500, {});
  }
  return apiRequest(ENTITY_PREFIX + 'remove', {
    id: params.id,
  });
}
export function apiDepositSCWithdraw(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {});
  }
  return apiRequest(ENTITY_PREFIX + 'withdraw', {
    deposit_address: params.deposit_address,
    withdraw_address: params.withdraw_address,
    amount: params.amount,
  });
}
