const CancelablePromise = require('mn-utils/CancelablePromise');
const map = require('mn-utils/map');
const filter = require('mn-utils/filter');
const find = require('mn-utils/find');
const {apiRequest} = require('./ws');
const {localStore} = require('../stores');
const ENTITY_PREFIX = 'receive_to_address/';

const mockAddress$ = localStore('mockAddress', [
  {
    id: '1',
    address: '00000000-0000-0000-0000-000000000001',
    description: 'Binance',
    created_at: '2020-06-12T08:41:06.823223+00:00',
    updated_at: '2020-06-12T08:41:06.823223+00:00',
    arbitrator_id: '111c397a-1da2-4014-8bdc-101ddc03c53c',
    asset_code: 'BTC',
  },
  {
    id: '2',
    address: '00000000-0000-0000-0000-000000000002',
    description: 'Binance 2',
    created_at: '2020-06-12T08:41:06.823223+00:00',
    updated_at: '2020-06-12T08:41:06.823223+00:00',
    arbitrator_id: '111c397a-1da2-4014-8bdc-101ddc03c53c',
    asset_code: 'BTC',
  },
  {
    id: '3',
    address: '00000000-0000-0000-0000-000000000003',
    description: 'Binance 1',
    created_at: '2020-06-12T08:41:06.823223+00:00',
    updated_at: '2020-06-12T08:41:06.823223+00:00',
    arbitrator_id: '111c397a-1da2-4014-8bdc-101ddc03c53c',
    asset_code: 'BTC',
  },
]);
let __lastId = require('../utils/getLastIdByEmitter')(mockAddress$);

export function apiAddressCreate(params) {
  if (process.env.MOCK) {
    const data = {
      ...params,
      id: '' + __lastId++,
    };
    mockAddress$.emit([
      ...mockAddress$.getValue(),
      data,
    ]);
    return CancelablePromise.delay(500, data);
  }
  return apiRequest(ENTITY_PREFIX + 'add', params);
}
export function apiAddressUpdate(params) {
  if (process.env.MOCK) {
    const id = params.id;
    mockAddress$.emit(map(mockAddress$.getValue(), (item) => {
      return id === item.id
        ? {
          ...item,
          ...params,
        }
        : item;
    }));
    return CancelablePromise.delay(500, {id});
  }
  return apiRequest(ENTITY_PREFIX + 'update', params);
}
export function apiAddressList(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, {
      list: mockAddress$.getValue(),
    });
  }
  return apiRequest(ENTITY_PREFIX + 'list', {
    // asset_code: 'EURS',
    ...params,
  });
}
export function apiAddressGet(params) {
  if (process.env.MOCK) {
    return apiAddressList()
        .then((v) => find(v.list, ['id', params.id]));
  }
  return apiRequest(ENTITY_PREFIX + 'get', params).then('obj');
}
export function apiAddressRemove(params) {
  if (process.env.MOCK) {
    const id = params.id;
    mockAddress$.emit(filter(mockAddress$.getValue(), (item) => {
      return id !== item.id;
    }));
    return CancelablePromise.delay(500, {});
  }
  return apiRequest(ENTITY_PREFIX + 'remove', {
    id: params.id,
  });
}
