const React = require('react');
const delay = require('mn-utils/delay');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const LayoutD = require('../../dumb/LayoutD');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const withState = require('../../../utils/withState');
const {
  apiExchangeGet,
} = require('../../../api/apiExchange');
const {decorateReconnect} = require('../../../emitters/account');
const {error$} = require('../../../emitters/popup');
const {
  exchangeFull$,
  exchangeFullUpdateLoading$,
} = require('../../../emitters/exchangeFull');
const UpdateExchangeBase = require('./UpdateExchangeBase');

const exchangeGet = decorateReconnect(apiExchangeGet);

module.exports = withState((setState, self, onMount) => {
  const changeProvider = changeProviderProvider(setState);
  const handleApiKey = changeProvider('apiKey');
  const handleApiSecret = changeProvider('apiSecret');
  const handleExchangeName = changeProvider('exchangeName');
  let _id;
  let _stop;

  function handleExchangeType(exchangeType) {
    setState({exchangeType});
  }

  function update(id) {
    delay(() => {
      if (_stop) return;
      setState({getLoading: 1});
      exchangeGet({id}).finally((err, item) => {
        if (_stop) return;
        err && error$.push(err);
        item = item || {};
        setState({
          getLoading: 0,
          apiKey: item && item.api_key || '',
          apiSecret: item && item.api_secret || '',
          exchangeName: item && item.exchange_name || '',
          exchangeType: item && item.exchange_type || 0,
        });
      });
    });
  }

  onMount(() => {
    return () => {
      _stop = 1;
    };
  });

  return (state, props) => {
    const id = '' + props.params.id;
    _id === id || update(_id = id);
    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Update Exchange</CaptionB>
        </HeaderA>
        <UpdateExchangeBase
          {...state}
          id={id}
          loading={state.loading || state.getLoading}
          onSave={exchangeFull$.update}
          handleApiKey={handleApiKey}
          handleApiSecret={handleApiSecret}
          handleExchangeName={handleExchangeName}
          handleExchangeType={handleExchangeType}
        />
      </LayoutD>
    );
  };
}, {
  getLoading: 0,
  loading: exchangeFullUpdateLoading$,
});
