const React = require('react');
const formatDate = require('mn-utils/formatDate');
const formatNum = require('mn-utils/formatNum');
const withState = require('../../../utils/withState');
const {
  trades$,
  tradesLoading$,
} = require('../../../emitters/trades');
const LayoutD = require('../../dumb/LayoutD');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const TableC = require('../../dumb/TableC');
const Animate = require('../../dumb/Animate');


function renderItem(props) {
  const {item} = props;
  const cc = item.cc || {};
  const sc = item.sc || {};
  const ccAmount = cc.amount;
  const scAmount = sc.amount;
  const scCode = sc.code || '';
  const profit = parseFloat(item.profit);
  return (
    <div className="ph15 w">
      <div
        className={`w layoutRow fhaS fvaC h62 ph5>1 bs b1 b00 bb1
          bcF0F0F1 cA00.minus dn1000 (dn|bgBBF).active`
            + (profit < 0 ? ' minus' : '')
            + (props.active ? ' active' : '')}
      >
        <div className="fx230 ws tov ov">
          {formatDate(new Date(item.timestamp), 'dd.mm.yyyy, HH:MM:ss')}
        </div>
        <div className="fx190 ws tov ov">
          {formatNum(ccAmount, 6)} {cc.code || ''}
        </div>
        <div className="fx140 ws tov ov">
          {formatNum(scAmount, 4)} {scCode}
        </div>
        <div className="fx160 ws tov ov">
          {item.exchange_name}
        </div>
        <div
          className="fx140 ws tov ov"
        >{formatNum(item.gain, 2)}%</div>
        <div
          className="fx140 ws tov ov tr"
        >{formatNum(profit, 5)} {scCode}</div>
      </div>
    </div>
  );
}


const Trades = require('../../../utils/virtualOutputProvider')({
  data$: trades$,
  listLoading$: tradesLoading$,
  rowHeight: 62,
  render(item) {
    return (<Animate
      item={item}
      render={renderItem}
    />);
  },
  request: require('../../../api/apiTxHistory').apiTxHistory,
});

module.exports = withState((setState) => {
  return (state) => {
    const {width} = state;
    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Trades</CaptionB>
        </HeaderA>
        <TableC
          headWidth={width}
          head={(
            <>
              <div className="fx230">Time</div>
              <div className="fx190">Buy</div>
              <div className="fx140">Sell</div>
              <div className="fx160">Exchange</div>
              <div className="fx140">Gain</div>
              <div className="fx140 tr">Profit</div>
            </>
          )}
          body={(
            <div className="h100vh-330 hmin200">
              <Trades
                onWidth={(_width) => {
                  width === _width || setState({width: _width});
                }}
              />
            </div>
          )}
        />
      </LayoutD>
    );
  };
}, {});
