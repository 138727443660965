const React = require('react');
const {createChart, LineStyle} = require('lightweight-charts');
const attachEvent = require('mn-utils/attachEvent');
const map = require('mn-utils/map');
const forEach = require('mn-utils/forEach');
const delay = require('mn-utils/delay');
const childClass = require('mn-utils/childClass');
const cloneDepth = require('mn-utils/cloneDepth');

module.exports = childClass(React.Component, function(props) {
  const self = this; // eslint-disable-line
  let _cache = {};
  let _ref;
  let _refContainer;
  let _chart;
  let _onRef;
  let _actions = {};
  let _options = {};
  let _lastOptions = _options;
  let _cancelResizeListener;
  const _init = self.props.init || noop;

  function updateSeries(actionName, lastCache, series) {
    const next = {};
    series && forEach(series, (
        item, index, instance, origin, prevRenderedItem,
    ) => {
      const _id = item.id || index;
      prevRenderedItem = lastCache[_id];
      function setData(data) {
        instance.setData(cloneDepth(data || [], 10));
      }
      function setBar(bar$) {
        const unsubscribeBar = prevRenderedItem.unsubscribeBar;
        unsubscribeBar && unsubscribeBar();
        prevRenderedItem.unsubscribeBar = bar$ ? bar$.on((bar) => {
          instance.update({...bar});
        }) : 0;
      }
      function setMarkers(markers) {
        forEach(prevRenderedItem.markersInstnaces, (priceLine) => {
          instance.removePriceLine(priceLine);
        }),
        prevRenderedItem.markersInstnaces = map(markers, (priceLine) => {
          return instance.createPriceLine({
            lineWidth: 2,
            lineStyle: LineStyle.Dotted,
            axisLabelVisible: true,
            ...priceLine,
          });
        });
      }
      if (prevRenderedItem) {
        delete lastCache[_id];
        next[_id] = prevRenderedItem;
        instance = prevRenderedItem.instance;
        origin = prevRenderedItem.origin;
        if (origin === item) return;
        prevRenderedItem.origin = item;
        const {data, options, markers, bar$} = item;
        origin.options === options || instance.applyOptions(options || {});
        origin.data === data || setData(data);
        origin.markers === markers || setMarkers(markers);
        origin.bar$ === bar$ || setBar(bar$);
        return;
      }
      instance = _chart[actionName](item.options || {});
      setData(item.data);
      prevRenderedItem = next[_id] = {
        origin: item,
        instance,
      };
      setMarkers(item.markers);
      setBar(item.bar$);
    });
    let id, item, unsubscribeBar; // eslint-disable-line
    for (id in lastCache) { // eslint-disable-line
      item = lastCache[id];
      unsubscribeBar = item.unsubscribeBar;
      unsubscribeBar && unsubscribeBar();
      item.instance.destroy();
    }
    return next;
  }

  function containerRef(ref) {
    _onRef && _onRef(ref);
    if (!ref || ref === _refContainer) return;
    _refContainer = ref;
    _cancelResizeListener || (_cancelResizeListener
      = attachEvent(window, 'resize', handleResize));
    delay(handleResize);
  }
  function handleResize() {
    _ref && _chart.resize(_ref.offsetHeight, _ref.offsetWidth);
  }
  function handleRef(ref) {
    _onRef && _onRef(ref);
    if (!ref || ref === _ref) return;
    _ref = ref;
    _chart && _chart.remove();
    _chart = createChart(_ref);
    update();
  }

  function update() {
    if (!_chart) return;
    _lastOptions === _options
      || _chart.applyOptions((_lastOptions = _options) || {});

    _init && _init(_chart);

    let actionName;
    const rendered = {};
    for (actionName in _actions) { // eslint-disable-line
      _cache[actionName] = updateSeries(
          actionName,
          _cache[actionName] || {},
          _actions[actionName],
      );
      rendered[actionName] = true;
    }
    for (actionName in _cache) { // eslint-disable-line
      rendered[actionName] || (_cache[actionName] = updateSeries(
          actionName, _cache[actionName],
      ));
    }
  }

  self.componentWillUnmount = () => {
    if (_cancelResizeListener) {
      _cancelResizeListener();
      _cancelResizeListener = null;
    }
    if (_chart) {
      _chart.remove();
      _ref = _chart = null;
      _cache = {};
    }
  };
  self.render = () => {
    const {
      actions,
      options,
      init, //eslint-disable-line
      ref,
      ...props
    } = self.props;
    _onRef = ref;
    _actions = actions;
    _options = options;
    update();
    return (
      <div
        {...props}
        ref={containerRef}
        className={'rlv ' + (props.className || '')}
      >
        <div
          ref={handleRef}
          className="abs s"
        />
      </div>
    );
  };
});
