const React = require('react');
const formatNum = require('mn-utils/formatNum');
const Ripple = require('../../dumb/Ripple');
const AppIcon = require('../../smart/AppIcon');
const {
  NavLink,
} = require('../../../router');
const {
  LINK_SIGN_UP,
} = require('../../../constants/links');

const {
  total$,
} = require('../../../emitters/demo');

const withState = require('../../../utils/withState');

const Cash = withState(() => {
  return (state) => {
    return (
      <div>
        <span className="fw5 pr5">Total processing</span> <span
          className="f32"
        >{formatNum(state.total, 2)} €</span>
      </div>
    );
  };
}, {
  total: total$,
});


const links = [
  {name: 'Trades'},
  {name: 'Deposits'},
  {name: 'Exchanges'},
  {name: 'Settings'},
].map(({name}) => {
  return (
    <Ripple
      key={name}
      component={NavLink}
      className="layoutRow fhaC fvaC h f16 cCT tdN olN fw6.active ph20 rlv"
      href={LINK_SIGN_UP}
    >{name}</Ripple>
  );
});

module.exports = (props) => {
  return (
    <div
      className="hmin100vh wmin1270 rlv ov"
      style={{
        background: 'linear-gradient(238.09deg, #F9F9F9 0%, #F0F0F0 95.31%)',
      }}
    >
      <div
        className="abs s"
        style={{
          backgroundImage: 'url('
            + require('../../../assets/images/bg.png') + ')',
        }}
      />
      <div className="w1270 ph15 pb20 mhA rlv">
        <div className="h116 layoutRow fvaC fhaC ff$main c1B1B1B ml-20">
          <div className="fx1 layoutRow fhaS fvaC">
            <Ripple
              component={NavLink}
              className="h70 tdN olN layoutRow fvaC fhaS cCT rlv ph20 r4 ov"
              href={LINK_SIGN_UP}
            >
              <AppIcon className="w150 fill0>path rlv st1"/>
              <div className="fw5 f16 pl25 rlv st1 lts-\.01em rlv">
                Arbitrage
              </div>
            </Ripple>
          </div>
          <div className="fx2 layoutRow fhaC fvaC h50">
            {links}
          </div>
          <div className="fx1 layoutRow fhaE fvaE lh">
            <Cash/>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};
