const React = require('react');
const TextField = require('@material-ui/core/TextField').default;
const PencilIcon = require('../icons/Pencil');

const INPUT_PROPS = {
  endAdornment: (
    <PencilIcon
      className="w(|min)14 c787A7E mr10"
    />
  ),
};
/* eslint-disable */


module.exports = (props) => {
  const {
    InputProps,
  } = props;
  return (
    <TextField
      margin="none"
      variant="outlined"
      {...props}
      InputProps={InputProps ? {
        ...INPUT_PROPS,
        ...InputProps,
      } : INPUT_PROPS}
      className={
        `(pv16|pt20|hmin56|bxzBB|c1B1B1B|ff$main|cr)>.*OutlinedInput-input
        pv0>textarea.*OutlinedInput-input
        (dn|f15|r8|bgF2:h@mouse)>.*OutlinedInput-root*2
        (bgF4.*-focused!)>.*OutlinedInput-root*3
        (b2|bc1B1B1B\\.15(>*|:h>*|>.*-focused):not[.*-error])>.*-notchedOutline*2
        (f15|ff$main|fw5|x20y20s100|c1B1B1B:not[.*-error]!)>.*InputLabel
        c1B1B1B>[data-shrink=true].*InputLabel:not[.*-error]
        (bgFF7070\\.05|bcEC4C4C)>.*-error>.*Input-notchedOutline*2
        TextFieldReg TextFieldReg dn>input:-webkit-autofill ` + (props.className || '')
      }
    />
  );
};
