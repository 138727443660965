const CancelablePromise = require('mn-utils/CancelablePromise');
const loopMap = require('mn-utils/loopMap');
const map = require('mn-utils/map');
const {post} = require('./http');

const INIT_TIME = (new Date()).getTime() * 0.001;
const CHART = map([
  521,
  397,
  332,
  446,
  426,
  356,
  225,
  220,
  225,
  238,
  275,
  397,
  426,
  178,
  53,
  44,
  76,
  60,
  84,
  114,
  127,
  211,
  238,
  309,
  335,
  325,
  191,
  302,
  273,
  383,
  409,
  365,
  174,
].reverse(), (v, i) => {
  return {
    date: INIT_TIME - i * 24 * 3600,
    amount: v,
  };
}).reverse();

export const DEFAULT_DATA = {
  last_deals: loopMap(4, (i) => ({
    time: '2020-04-09T14:15:45.502501+00:00',
    buy_amount: 0.004345435,
    buy_currency: 'ETH',
    sell_amount: 10,
    sell_currency: 'USDC',
    exchange: 'Binance',
    gain: 1.54,
    price: 169.49,
    profit: 0.00145,
  })),
  chart: CHART,
  total: 1302,
};

export function apiDemo(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, DEFAULT_DATA);
  }
  return post('demo/arbitrage', params);
}
