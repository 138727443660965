const React = require('react');
const map = require('mn-utils/map');

/* eslint-disable */

module.exports = (props) => {
  return (
    <div className={'h100 ovxH ovyA ' + (props.className || '')}>
      <table className="b0 bsp0 f14 lh b0 (b0|h25)>td w">
        <tbody>
          {
            map(props.items, (v, i) => (
              <tr key={v.id || i}>
                <td>{v.name}</td>
                <td className="w75">{v.amount}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};
