const React = require('react');
const {validate: validateVA} = require('wallet-address-validator');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const find = require('mn-utils/find');
const withState = require('../../../../utils/withState');
const TextFieldB = require('../../../dumb/TextFieldB');
const {
  accountUpdate,
} = require('../../../../emitters/account');
const {
  infos$,
} = require('../../../../emitters/popup');
const {
  withdrawAll,
  loadingWithdraw$,
} = require('../../../../emitters/withdraw');
const FormWrapper = require('./FormWrapper');

module.exports = withState((setState) => {
  const changeProvider = changeProviderProvider(setState);
  const handleChangeEurs = changeProvider('eurs');
  const handleChangeBtc = changeProvider('btc');
  const handleChangeEth = changeProvider('eth');

  return (state, props) => {
    const remainingBalances = props.remainingBalances || [];
    const remainingBalancesLength = remainingBalances.length || 0;
    const {clicked} = state;
    const eurs = state.eurs || '';
    const btc = state.btc || '';
    const eth = state.eth || '';
    const eursBalance = find(remainingBalances, ['asset_code', 'EURS']);
    const btcBalance = find(remainingBalances, ['asset_code', 'BTC']);
    const ethBalance = find(remainingBalances, ['asset_code', 'ETH']);
    const invalidEurs = eurs && !validateVA(eurs, 'ETH');
    const invalidBtc = btc && !validateVA(btc, 'BTC');
    const invalidEth = eth && !validateVA(eth, 'ETH');
    const disabled = eursBalance && invalidEurs
      || btcBalance && invalidBtc
      || ethBalance && invalidEth;

    return (
      <FormWrapper
        loading={state.loading}
        onSubmit={remainingBalancesLength ? (() => {
          const args = [];
          eurs && args.push({
            asset: 'EURS',
            address: eurs,
            amount: eursBalance.amount,
          });
          btc && args.push({
            asset: 'BTC',
            address: btc,
            amount: btcBalance.amount,
          });
          eth && args.push({
            asset: 'ETH',
            address: eth,
            amount: ethBalance.amount,
          });
          withdrawAll(args).then(() => {
            accountUpdate();
            infos$.push(['', 'Withdrawal Success']);
          });
        }) : null}
        submitText="Withdraw"
        submitDisabled={disabled && clicked}
      >
        {
          eursBalance ? (
            <div className="pv20 rlv">
              <div className="abs sl st">EURS</div>
              <TextFieldB
                className="w"
                error={invalidEurs}
                value={eurs}
                onChange={handleChangeEurs}
              />
            </div>
          ) : null
        }
        {
          btcBalance ? (
            <div className="pv20 rlv">
              <div className="abs sl st">BTC</div>
              <TextFieldB
                className="w"
                error={invalidBtc}
                value={btc}
                onChange={handleChangeBtc}
              />
            </div>
          ) : null
        }
        {
          ethBalance ? (
            <div className="pv20 rlv">
              <div className="abs sl st">ETH</div>
              <TextFieldB
                className="w"
                error={invalidEth}
                value={eth}
                onChange={handleChangeEth}
              />
            </div>
          ) : null
        }
      </FormWrapper>
    );
  };
}, {
  loading: loadingWithdraw$,
});
