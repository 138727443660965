const CancelablePromise = require('mn-utils/CancelablePromise');
const {API_URL_HTTP} = require('../config/api');

export const DEFAULT_ASSETS_AVAILABLE = ['BTC', 'ETH', 'LTC', 'DASH', 'EURS'];

export function apiAssetsAvailable(params) {
  if (process.env.MOCK) {
    return CancelablePromise.delay(500, DEFAULT_ASSETS_AVAILABLE);
  }
  return CancelablePromise.resolve(fetch(API_URL_HTTP + 'proccesinginfo', {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
  }))
      .then((response) => {
        const {status} = response;
        return response.json().catch((e) => {
          throw status > 199 && status < 400
            ? new Error('HTTP status: ' + status)
            : e;
        });
      })
      .finally((error, response) => {
        error
          ? console.error(error)
          : console.log('response:', response);
      });
}
