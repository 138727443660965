const Emitter = require('mn-utils/Emitter');
const map = require('mn-utils/map');
const {apiTxHistory} = require('../api/apiTxHistory');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');
const {
  notifierTradesPushed$,
  notifierTradesUpdate$,
} = require('./notifications');

export const tradesLoading$ = new Emitter(0);
export const trades$ = new Emitter((emit) => {
  getTxHistory(emit);

  notifierTradesPushed$.on((next) => {
    const data = trades$.getValue();
    trades$.emit({
      count: (data.count || 0) + 1,
      list: [
        next,
        ...(data.list || []),
      ],
    });
  });

  notifierTradesUpdate$.on((next) => {
    const data = trades$.getValue();
    const {id} = next;
    trades$.emit({
      ...data,
      list: map(data.list, (v) => v.id === id ? next : v),
    });
  });
}, {});

const getTxHistory = decorateByLoadingEmit(
    apiTxHistory,
    tradesLoading$,
    (emit, response) => {
      emit(response);
    },
);
