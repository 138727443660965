const React = require('react');
const map = require('mn-utils/map');
const Emitter = require('mn-utils/Emitter');
const viewport$ = require('mn-utils/emitters/viewport');
const inViewportProvider = require('mn-utils/browser/InViewportProvider');
const {Link} = require('../../../router');
const ButtonA = require('../../dumb/ButtonA');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const LayoutD = require('../../dumb/LayoutD');
const CaptionB = require('../../dumb/CaptionB');
const HeaderA = require('../../dumb/HeaderA');
const withState = require('../../../utils/withState');
const {decorateReconnect} = require('../../../emitters/account');
const {
  exchangeFull$,
  exchangeFullLoading$,
} = require('../../../emitters/exchangeFull');
const {
  errors$,
} = require('../../../emitters/popup');
const {
  scroll$,
} = require('../../../emitters/scroll');
const {
  apiExchangeListFull,
} = require('../../../api/apiExchange');
const {
  LINK_ADD_EXCHANGE,
} = require('../../../constants/links');
const ExchangeItem = require('./ExchangeItem');

const OnView = inViewportProvider(React);

const exchangeListFull = decorateReconnect(apiExchangeListFull);

const nextLoading$ = new Emitter(0);
function next() {
  if (exchangeFullLoading$.getValue() || nextLoading$.getValue()) return;
  const state = exchangeFull$.getValue();
  if (!state) return;

  const items = state.list || [];
  const offset = items.length || 0;
  const count = state.count || offset;
  return offset === count || (nextLoading$.emit(1), exchangeListFull({
    offset,
  }).finally((err, response, list) => {
    nextLoading$.emit(0);
    err
      ? (
        errors$.push(err.message),
        console.error(err)
      )
      : exchangeFull$.emit({
        list: list = items.concat(response.list || []),
        count: response.count || list.length,
      });
  }));
}

module.exports = withState(() => {
  return (state, props) => {
    const exchanges = state.exchanges || {};
    const items = exchanges.list || [];
    const length = items.length;
    const count = exchanges.count || length;

    return (
      <LayoutD>
        <HeaderA>
          <CaptionB>Exchanges</CaptionB>
          <ButtonA
            component={Link}
            href={LINK_ADD_EXCHANGE}
            className="wmin170"
          >Add Exchange</ButtonA>
        </HeaderA>
        <WrapperWithLoading loading={state.loading}>
          <div className="hmin100vh-280">
            {map(items, (exchange) => {
              const {id} = exchange;
              return (
                <ExchangeItem
                  key={id}
                  exchange={exchange}
                />
              );
            })}
            <OnView
              state={{
                scroll$,
                viewport$,
              }}
              render={() => {
                if (count === length) return null;
                next();
                return (
                  <WrapperWithLoading
                    loading={state.nextLoading}
                  />
                );
              }}
            />
          </div>
        </WrapperWithLoading>
      </LayoutD>
    );
  };
}, {
  exchanges: exchangeFull$,
  loading: exchangeFullLoading$,
  nextLoading: nextLoading$,
});
