const CancelablePromise = require('mn-utils/CancelablePromise');
const pick = require('mn-utils/pick');
const map = require('mn-utils/map');
const filter = require('mn-utils/filter');
const find = require('mn-utils/find');
const {apiRequest} = require('./ws');
const {localStore} = require('../stores');
const ENTITY_PREFIX = 'exchange/';

const mockExchanges$ = localStore('__mockExchangeList', [
  {
    id: '00000000-0000-0000-0000-000000000001',
    exchange_type: 'Binance',
    exchange_name: 'Binance',
    deposits: [
      {
        asset: 'ETH',
        amount: 1224.33232,
        address: '0x98bdF7888e5E3F8171fFd4fb7c1D70a82eF2A2B5',
      },
      {
        asset: 'EUR',
        amount: 876,
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    exchange_type: 'Kraken',
    exchange_name: 'Kraken',
    deposits: [
      {
        asset: 'BTC',
        amount: 0.000063089,
        address: '3PDBjdfff1qLZ8NabtxSuwuDL4DeGXVfKy',
      },
    ],
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    exchange_type: 'HitBtc',
    exchange_name: 'HitBtc',
    deposits: [
      {
        asset: 'EURS',
        amount: 12235.23,
        address: '0x98bdF7888e5E3F8171fFd4fb7c1D70a82eF2A2B5',
      },
      {
        asset: 'BTC',
        amount: 0.000063089,
        address: '3PDBjdfff1qLZ8NabtxSuwuDL4DeGXVfKy',
      },
      {
        asset: 'ETH',
        amount: 1224.33232,
        address: '0x98bdF7888e5E3F8171fFd4fb7c1D70a82eF2A2B5',
      },
    ],
  },
]);
let __lastId = require('../utils/getLastIdByEmitter')(mockExchanges$);

export function apiExchangeCreate(params) {
  if (process.env.MOCK) {
    mockExchanges$.emit([
      ...mockExchanges$.getValue(),
      pick(params, [
        'exchange_type',
        'api_key',
        'api_secret',
        'exchange_name',
      ], {
        id: '' + __lastId++,
      }),
    ]);
    return CancelablePromise.delay(500, {
      // invalid: 1,
    });
  }
  return apiRequest(ENTITY_PREFIX + 'create', params);
}
export function apiExchangeUpdate({id, ...params}) {
  if (process.env.MOCK) {
    const id = params.id; // eslint-disable-line
    mockExchanges$.emit(map(mockExchanges$.getValue(), (item) => {
      return id === item.id
        ? pick(params, [
          'exchange_type',
          'api_key',
          'api_secret',
          'exchange_name',
        ], item)
        : item;
    }));
    /*
    return CancelablePromise.delay(500, {
      invalid: 1,
    });
    */
  }
  return apiRequest(ENTITY_PREFIX + 'update', {
    ...params,
    exchange_id: id,
  });
}
export function apiExchangeList(params) {
  if (process.env.MOCK) {
    params = params || {};
    return CancelablePromise.delay(500, {
      list: mockExchanges$.getValue(),
    });
  }
  return apiRequest(ENTITY_PREFIX + 'list', params);
}
export function apiExchangeListFull(params) {
  params = params || {};
  const limit = params.limit || 10;
  const offset = params.offset || 0;
  if (process.env.MOCK) {
    const items = mockExchanges$.getValue();
    return CancelablePromise.delay(500, {
      list: items.slice(offset, offset + limit),
      count: items.length,
    });
    /*
    .then(() => {
      throw new Error('cannot connect');
    });
    */
  }
  return apiRequest(ENTITY_PREFIX + 'list', {
    limit,
    offset,
    with_upstream_info: true,
  });
}
export function apiExchangeGet(params) {
  if (process.env.MOCK) {
    return apiExchangeList()
        .then((v) => find(v.list, ['id', params.id]));
  }
  return apiRequest(ENTITY_PREFIX + 'get', params);
}
export function apiExchangeRemove(params) {
  if (process.env.MOCK) {
    const id = params.id; // eslint-disable-line
    mockExchanges$.emit(filter(mockExchanges$.getValue(), (item) => {
      return id !== item.id;
    }));
    return CancelablePromise.delay(500, {});
  }
  return apiRequest(ENTITY_PREFIX + 'remove', params);
}
export function apiExchangeMinDeal(params) {
  if (process.env.MOCK || 1) {
    return CancelablePromise.delay(500, {
      value: params.type === 'hitbtc' ? 1 : 11,
    });
  }
  return apiRequest(ENTITY_PREFIX + 'min-deal', params);
}
