const React = require('react');

/* eslint react/prop-types: 0 */

module.exports = function LinkA({component, ...props}) {
  const Outer = component || 'a';
  return (
    <Outer
      {...props}
      className={'olNone tdN f14 dn o70:h c1B1B1B '
        + (props.className || '')}
    />
  );
};
