const delay = require('mn-utils/delay');
const Emitter = require('mn-utils/Emitter');
const emitterBehave = require('mn-utils/Emitter/behave');
const CancelablePromise = require('mn-utils/CancelablePromise');
const {
  LINK_SIGN_IN,
  LINK_HOME,
  LINK_BLOCKED,
} = require('../constants/links');
const {
  replaceLocation,
  pushLocation,
} = require('../router');
const {localStore} = require('../stores');
const {
  apiAuthSignUp,
  apiAuthSignIn,
  apiAuthSignOut,
  apiAuthUser,
  apiAuthUserRemove,
  apiAuthForgotPassword,
  apiAuthEmailConfirm,
  apiAuthResendConfirmation,
} = require('../api/apiAuth');
const {
  infos$,
  errors$,
  disconnectOpen$,
  confirmOpen,
} = require('./popup');


function isErrorNoConnect(err) {
  return /(Failed to fetch|NetworkError|cannot connect|closed)/gim
      .test(err.message);
}

let promiseCheckSession = new CancelablePromise();
export function checkSessionWithoutLoading(ignoreEmpty) {
  if (loadingSession$.getValue()) return promiseCheckSession;
  loadingSession$.emit(1);
  function base() {
    return apiAuthUser().then(
        ignoreEmpty ? null : (v) => {
          if (v) return v;
          disconnectOpen$.emit(1);
        },
        (err) => {
          if (isErrorNoConnect(err)) {
            return CancelablePromise.delay(10000).then(base);
          }
          throw err;
        },
    );
  }
  return promiseCheckSession = base().finally(() => {
    loadingSession$.emit(0);
  });
}
export function checkSession(ignoreEmpty) {
  loadingReconnect$.emit(1);
  return checkSessionWithoutLoading(ignoreEmpty).finally(() => {
    loadingReconnect$.emit(0);
  });
}
export function decorateReconnect(request) {
  return (params) => {
    function base() {
      return request(params).catch((err) => {
        if (isErrorNoConnect(err)) return checkSession().then(base);
        throw err;
      });
    }
    return base();
  };
}

const userDelete = decorateReconnect(apiAuthUserRemove);
export function accountDelete(params, callback) {
  const promise = new CancelablePromise();
  function base() {
    loadingSignIn$.emit(1);
    userDelete(params)
        .then((v) => {
          infos$.push([
            'Deleting account',
            'Account deletion process started successfully',
          ]);
          return accountUpdate().then(() => v);
        })
        .finally((err, response) => {
          err
            ? errors$.push(err.message)
            : promise.resolve(response);
          loadingSignIn$.emit(0);
        });
  }
  confirmOpen({
    title: 'Deleting account',
    text: 'Are you sure you want delete this account?',
  }, () => {
    callback
      ? CancelablePromise.resolve(callback()).then(base)
      : base();
  });
  return promise;
}

export const login$ = localStore('email', '');
export function handleChangeEmail(e) {
  login$.emit(e.target.value);
}

export const loadingSession$ = new Emitter(0);
export const loadingReconnect$ = new Emitter(0);
export const loadingSignUp$ = new Emitter(0);
export const loadingSignIn$ = new Emitter(0);
export const loadingSignOut$ = new Emitter(0);
export const loadingForgotPassword$ = new Emitter(0);
const {emit: emitSignOutLoading} = loadingSignOut$;

export const openAuth2FA$ = new Emitter(0);

export function accountUpdateWithoutLoading(ignoreEmpty) {
  return checkSessionWithoutLoading(ignoreEmpty).then((v) => {
    if (v && v.need_tfa) {
      openAuth2FA$.emit(1);
      emitAccount(0);
      return;
    }
    emitAccount(v);
  });
}

export function accountUpdate() {
  emitSignOutLoading(1);
  return accountUpdateWithoutLoading(1).finally((err) => {
    err && (
      errors$.push(err.message),
      console.error(err)
    );
    emitSignOutLoading(0);
  });
}

export const account$ = emitterBehave(accountUpdate, {
  signIn: decorateByLoadingEmit(
      apiAuthSignIn,
      loadingSignIn$,
      (emit, response, params) => {
        if (response.blocked) {
          pushLocation({
            path: LINK_BLOCKED + '/' + params.email,
          });
          return;
        }
        if (response.need_tfa) {
          openAuth2FA$.emit(1);
          return;
        }
        emitAccount(response);
        replaceLocation({path: LINK_HOME});
      },
  ),
  signUp: decorateByLoadingEmit(
      apiAuthSignUp,
      loadingSignUp$,
  ),
  signOut: (() => {
    let promise;
    return () => {
      if (loadingSignOut$.getValue()) return promise;
      loadingSignOut$.emit(1);
      return promise = apiAuthSignOut().finally((err) => {
        err ? err.skip || (
          errors$.push(err.message),
          console.error(err),
          loadingSignOut$.emit(0)
        ) : refreshSession();
      });
    };
  })(),
  forgotPassword: decorateByLoadingEmit(
      apiAuthForgotPassword,
      loadingForgotPassword$,
      (emit, response) => {
        infos$.push(['Confirm Email', 'Check your email']);
      },
  ),
  emailConfirm: decorateByLoadingEmit(
      apiAuthEmailConfirm,
      loadingSignIn$,
  ),
  resendConfirmation: decorateByLoadingEmit(
      apiAuthResendConfirmation,
      loadingSignIn$,
  ),
});
export const needTFA$ = account$.map('need_tfa');
const {
  emit: emitAccount,
} = account$;
function refreshSession() {
  emitAccount(0);
  replaceLocation({path: LINK_SIGN_IN});
  delay(() => {
    window.location.reload();
  }, 100);
}

function decorateByLoadingEmit(request, loading$, onResponse) {
  let promise;
  return (emit, params, getValue) => {
    return loading$.getValue()
      ? promise
      : (
        loading$.emit(1),
        promise = request(params).finally((err, response) => {
          err
            ? err.skip || (
              errors$.push(err.message),
              console.error(err)
            )
            : onResponse && onResponse(emit, response, params, getValue);
          loading$.emit(0);
        })
      );
  };
}
