const React = require('react');
const isEmail = require('mn-utils/isEmail');
const {
  LINK_SIGN_IN,
  LINK_HOME,
} = require('../../../constants/links');
const withState = require('../../../utils/withState');
const {
  Link,
  replaceLocation,
} = require('../../../router');
const ButtonConnect = require('../../dumb/ButtonConnect');
const TextFieldA = require('../../dumb/TextFieldReg');
const FormAuthBase = require('./components/FormAuthBase');
const {
  account$,
  login$,
  handleChangeEmail,
} = require('../../../emitters/account');
const {
  error$,
} = require('../../../emitters/popup');

module.exports = withState((setState) => {
  let emailNode;

  function handleEmailRef(node) {
    node && (emailNode = node);
  }
  return (state) => {
    const {email, clicked} = state;
    const invalidEmail = !isEmail(email);

    function handleSubmit() {
      emailNode && emailNode.blur();
      if (state.error) return;
      invalidEmail
        ? setState({clicked: 1})
        : account$.forgotPassword({
          email,
        });
    }
    function handlePasswordEnter(e) {
      e.key == 'Enter' && handleSubmit();
    }

    state.account && replaceLocation({
      path: LINK_HOME,
    });

    return (
      <FormAuthBase
        title="Access recovery"
        subfooter={(
          <div className="f16 fw5 tc mt45 wmax400 w mhA">
            We will send your password to the specified email
          </div>
        )}
        footer={(
          <div className="tc">
            <Link
              className="f17 fw5 c1B1B1B tdU olN dn o70:h"
              href={LINK_SIGN_IN}
            >I have a password</Link>
          </div>
        )}
      >
        <div
          className="f20 c1B1B1B fw6 tc"
        >Password recovery</div>
        <div className="mt45">
          <TextFieldA
            error={clicked && invalidEmail}
            className="w"
            label="Email"
            value={email}
            onChange={handleChangeEmail}
            onKeyPress={handlePasswordEnter}
            inputRef={handleEmailRef}
          />
        </div>
        <div className="mt15">
          <ButtonConnect
            disabled={clicked && invalidEmail}
            className="w"
            onClick={handleSubmit}
          >Send password</ButtonConnect>
        </div>
      </FormAuthBase>
    );
  };
}, {
  account: account$,
  email: login$,
  error: error$,
});
