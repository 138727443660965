const storeProvider = require('mn-utils/browser/storeProvider');
const router = require('./router');

// eslint-disable-next-line
export const localStore = storeProvider(require('mn-utils/browser/localStorage'));

/*
export const hashParamStore = storeProvider(router.hashParamStorage);
export const paramStore = hashParamStore;
*/

export const paramStore = storeProvider(router.paramStorage);
export const hashParamStore = paramStore;
