const Emitter = require('mn-utils/Emitter');
const combine = require('mn-utils/Emitter/combine');
const filter = require('mn-utils/filter');
const indexOf = require('mn-utils/indexOf');
const {STABLECOINS, CRYPTOCOINS, ASSETS} = require('../constants');
const {
  apiAssetsAvailable,
  DEFAULT_ASSETS_AVAILABLE,
} = require('../api/apiAssets');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');

export const assetsAvailableLoading$ = new Emitter(0);
const getUpdate = decorateByLoadingEmit(
    apiAssetsAvailable,
    assetsAvailableLoading$,
    (emit, list) => emit(list || []),
);

export const assetsAvailable$ = (new Emitter((emit) => {
  getUpdate(emit);
}, DEFAULT_ASSETS_AVAILABLE)).behave({
  getUpdate,
});
export const stablecoins$ = combine([
  assetsAvailable$,
  STABLECOINS,
]).map(([availables, assets]) => {
  return filter(assets, (asset) => indexOf(availables, asset.id) > -1);
});
export const cryptocoins$ = combine([
  assetsAvailable$,
  CRYPTOCOINS,
]).map(([availables, assets]) => {
  return filter(assets, (asset) => indexOf(availables, asset.id) > -1);
});
export const assets$ = combine([
  assetsAvailable$,
  ASSETS,
]).map(([availables, assets]) => {
  return filter(assets, (asset) => indexOf(availables, asset.id) > -1);
});
