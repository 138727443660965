const React = require('react');
const map = require('mn-utils/map');
const IconButton = require('@material-ui/core/IconButton').default;
const PlayIcon = require('@material-ui/icons/PlayArrow').default;
const StopIcon = require('@material-ui/icons/Stop').default;
const {
  LINK_TRADING_PAIR_EDIT,
} = require('../../../constants/links');
const {
  Link,
} = require('../../../router');
const withState = require('../../../utils/withState');
const {
  ccPair$,
  ccPairLoading$,
} = require('../../../emitters/ccPair');
const ButtonA = require('../../dumb/ButtonA');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const Pencil = require('../../icons/Pencil');

module.exports = withState(() => {
  return (state, props) => {
    const pair = state.pair || {};
    const items = pair.list || [];

    return (
      <div {...props}>
        <WrapperWithLoading
          loading={state.loading}
        >
          <div className="mh-20">
            <div
              className="layoutRow fhaS fvaC w h44 bgF8F8F9 r4 cB8BDC0 ttU f13
                fw5 lts-\.015em ph15 pr5 ph5>1"
            >
              <div className="w(|min)100">Pair</div>
              <div className="w(|min)100">Exchange</div>
              <div className="w(|min)70">Profit</div>
              <div className="w(|min)55 tr fx1">Gain</div>
              <div className="w(|min)85"></div>
            </div>
            <div className="ovyA ovxH hmax135">
              {map(items, (item, i) => {
                const ccDst = item.cc_dst;
                const ccSrc = item.cc_src;
                const active = item.active || false;
                const {id} = item;
                const ActiveIcon = active ? StopIcon : PlayIcon;
                return (
                  <div
                    key={id || i}
                    className="layoutRow fhaS fvaC w c1B1B1B h45 pl15 ph5>1"
                  >
                    <div
                      className={'w(|min)100 o30.disactive'
                        + (active ? '' : ' disactive')}
                    >{ccSrc}/{ccDst}</div>
                    <div
                      className={'w(|min)100 tov ov ws o30.disactive'
                        + (active ? '' : ' disactive')}
                    >{item.exchange_name}</div>
                    <div
                      className={'w(|min)70 o30.disactive'
                        + (active ? '' : ' disactive')}
                    >
                      {
                        item.kind_of_gain === 'increase_stables'
                          ? ccDst
                          : ccSrc
                      }
                    </div>
                    <div
                      className={'w(|min)55 tr fx1 o30.disactive'
                        + (active ? '' : ' disactive')}
                    >+{item.gain}%</div>
                    <div className="w(|min)85 layoutRow fhaC fhaS">
                      <IconButton
                        onClick={() => {
                          ccPair$.activate({
                            id,
                            value: !active,
                          });
                        }}
                      >
                        <div className="m-5">
                          <ActiveIcon
                            className="cC5CAD0 dB*2"
                          />
                        </div>
                      </IconButton>
                      <IconButton
                        className={'o30.disactive'
                          + (active ? '' : ' disactive')}
                        component={Link}
                        href={LINK_TRADING_PAIR_EDIT + id}
                      >
                        <Pencil
                          className="w14 cC5CAD0"
                        />
                      </IconButton>
                    </div>
                  </div>
                );
              }, [])}
            </div>
          </div>
        </WrapperWithLoading>
        <div className="mt20 layoutRow fvaC fhaE">
          <ButtonA
            component={Link}
            href={LINK_TRADING_PAIR_EDIT + 'new'}
            className="wmin160"
          >Add Arbitrage Pair</ButtonA>
        </div>
      </div>
    );
  };
}, {
  pair: ccPair$,
  loading: ccPairLoading$,
});
