const Emitter = require('mn-utils/Emitter');
const CancelablePromise = require('mn-utils/CancelablePromise');
const decorateByLoading = require('./utils/decorateByLoading');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');
const {popup$} = require('./popup');
const {
  apiAuthPasswordSet,
  apiAuthTfaBind,
  apiAuthTfaConfirm,
  apiAuthTfaVerify,
} = require('../api/apiAuth');

export const openChangePassword$ = popup$.is('password').behave({
  enable(emit) {
    emit(1);
  },
  disable(emit) {
    emit(0);
  },
});

function apiProfileChange(params) {
  return CancelablePromise.delay(500, {
    ...params,
    password: '',
  });
}

export const profileLoading$ = new Emitter(0);
export const changePasswordLoading$ = new Emitter(0);
export const bind2FALoading$ = new Emitter(0);

export const changePassword = decorateByLoading(
    apiAuthPasswordSet,
    changePasswordLoading$,
);
export const bind2FA = decorateByLoading(
    apiAuthTfaBind,
    bind2FALoading$,
);
export const activate2FA = decorateByLoading(
    apiAuthTfaConfirm,
    bind2FALoading$,
);
export const verify2FA = decorateByLoading(
    apiAuthTfaVerify,
    bind2FALoading$,
);


export const profile$ = (new Emitter({
  firstName: 'Liza',
  secondName: 'Mueller',
})).behave({
  change: decorateByLoadingEmit(
      apiProfileChange,
      profileLoading$,
      (emit, response, params, getValue) => {
        emit({
          ...getValue(),
          ...params,
        });
      },
  ),
});
