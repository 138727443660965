const CancelablePromise = require('mn-utils/CancelablePromise');
const {apiRequest} = require('./ws');
const {localStore} = require('../stores');
const ENTITY_PREFIX = 'log/';

const mockLog$ = localStore('mockLogList', [
  {
    timestamp: '2019-11-20T00:06:14',
    event_text: 'Buy 0.004345435 ETH for 10 USCD with gain 1.5%',
  },
  {
    timestamp: '2019-11-20T00:06:14',
    event_text:
      'Error: no enought funds (BTC) for making counter deal on HitBTC',
  },
  {
    timestamp: '2019-11-20T00:06:14',
    event_text: 'Deposit 1200 EURS on arbitrage account',
  },
  {
    timestamp: '2019-11-20T00:06:14',
    event_text: 'No funds (EURS) for join to arbitrage auction',
  },
  {
    timestamp: '2019-11-20T00:06:14',
    event_text: 'Deposit 1200 EURS on arbitrage account',
  },
  {
    timestamp: '2019-11-20T00:06:14',
    event_text: 'No funds (EURS) for join to arbitrage auction',
    event_type: 'error',
  },
]);

export function apiLogList(params) {
  params = params || {};
  const offset = params.offset || 0;
  const limit = params.limit || 50;
  const items = mockLog$.getValue();
  return process.env.MOCK
    ? CancelablePromise.delay(500, {
      list: items.slice(offset, offset + limit),
      count: items.length,
    })
    : apiRequest(ENTITY_PREFIX + 'list', {
      offset,
      limit,
    });
}
