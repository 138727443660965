const React = require('react');
const get = require('mn-utils/get');
const delay = require('mn-utils/delay');
const find = require('mn-utils/find');
const some = require('mn-utils/some');
const map = require('mn-utils/map');
const noop = require('mn-utils/noop');
const formatNum = require('mn-utils/formatNum');
const combine = require('mn-utils/Emitter/combine');
const changeProviderProvider = require('mn-utils/changeProviderProvider');
const SelectA = require('../../dumb/SelectA');
const LinkA = require('../../dumb/LinkA');
const ButtonA = require('../../dumb/ButtonA');
const ButtonB = require('../../dumb/ButtonB');
const TextFieldA = require('../../dumb/TextFieldB');
const LayoutD = require('../../dumb/LayoutD');
const HeaderA = require('../../dumb/HeaderA');
const CaptionB = require('../../dumb/CaptionB');
const RadioA = require('../../dumb/RadioA');
const CheckboxB = require('../../dumb/CheckboxB');
const WrapperWithLoading = require('../../dumb/WrapperWithLoading');
const withState = require('../../../utils/withState');
const numberHandle = require('../../../utils/numberHandle');
const isSafeNumber = require('../../../utils/isSafeNumber');
const {
  Link,
  backLocation,
} = require('../../../router');
const {decorateReconnect} = require('../../../emitters/account');
const {
  ccPair$,
  ccPairUpdateLoading$,
} = require('../../../emitters/ccPair');
const {
  exchange$,
  exchangeListLoading$,
} = require('../../../emitters/exchange');
const {
  assetsAvailableLoading$,
  stablecoins$,
  cryptocoins$,
} = require('../../../emitters/assets');
const {
  infos$,
  error$,
} = require('../../../emitters/popup');
const {
  LINK_ADD_EXCHANGE,
} = require('../../../constants/links');
const {
  apiCcPairGet,
} = require('../../../api/apiCcPair');
const {
  apiExchangeMinDeal,
} = require('../../../api/apiExchange');

const ccPairGet = decorateReconnect(apiCcPairGet);
const exchangeMinDeal = decorateReconnect(apiExchangeMinDeal);

const MIN_GAIN_LIMIT = 0.01;
const MAX_GAIN_LIMIT = 99.99;

function LabelA(props) {
  return (
    <div
      {...props}
      className={'w$1 hmin1 c7D8594 ' + (props.className || '')}
    />
  );
}


module.exports = withState((setState, self, onMount) => {
  const changeProvider = changeProviderProvider(setState);
  const handleChangeGain = changeProvider('gain', 0, numberHandle);
  const handleChangeCurrency = handleProvider('cc_dst');
  const handleChangeCryptoCurrency = handleProvider('cc_src');
  const handleChangeExchangeId = handleProvider('exchange_id');
  const handleChangeProfitIn = handleProvider('profitIn');
  let _cancelMinDeal = noop;
  let _stop;
  let _id;
  let _exchangeType;


  onMount(() => {
    return () => {
      _stop = 1;
    };
  });

  function handleProvider(name) {
    return function(v) {
      setState({[name]: v});
    };
  }

  function getPair(id) {
    delay(() => {
      if (_stop) return;
      setState({
        getLoading: 1,
      });
      ccPairGet({id}).then(
          (item) => {
            if (_stop) return;
            item = item || {};
            const minDeal = parseFloat(item.min_deal);
            const maxDeal = parseFloat(item.max_deal);
            setState({
              ...item,
              gain: formatNum(item.gain, 2),
              profitIn: item.kind_of_gain === 'increase_stables'
                ? 'STABLE'
                : 'CRYPTO',
              min_deal: minDeal > 0 ? '' + minDeal : undefined,
              max_deal: maxDeal > 0 ? '' + maxDeal : '',
              getLoading: 0,
              disabledSell: !item.enabled_sell,
              paused: !item.active,
            });
          },
          (err) => {
            if (_stop) return;
            err && error$.push(err);
            setState({
              getLoading: 0,
            });
          },
      );
    });
  }
  function handleChangeDealPriceMin(e) {
    setState({
      clicked: 1,
      min_deal: numberHandle(e.target.value),
    });
  }
  function handleChangeDealPriceMax(e) {
    setState({
      clicked: 1,
      max_deal: numberHandle(e.target.value),
    });
  }
  function handleDisabledSell(disabledSell) {
    setState({
      disabledSell,
    });
  }
  function handlePaused(paused) {
    setState({
      paused,
    });
  }

  function getMinDeal(type) {
    delay(() => {
      if (_stop) return;
      setState({
        loadingDealMin: 1,
      });
      _cancelMinDeal();
      _cancelMinDeal = exchangeMinDeal({type}).then(
          (item) => {
            if (_stop) return;
            setState({
              dealMin: '' + (item && item.value || 0),
              loadingDealMin: 0,
            });
          },
          (err) => {
            if (_stop) return;
            err && error$.push(err);
            setState({
              loadingDealMin: 0,
            });
          },
      ).cancel;
    }, 50);
  }

  return (state, props) => {
    const params = props.params || {};
    const id = '' + (params.id || '');
    const hasNew = id === 'new';

    hasNew || _id === id || getPair(_id = id);

    const {
      exchanges,
      clicked,
      cryptocoins,
    } = state;
    const dealMin = state.dealMin || 0;
    const gain = '' + (state.gain || '');
    const exchangeId = state.exchange_id || get(exchanges, '0.id') || 0;
    const exchange = find(exchanges, ['id', exchangeId]) || {};
    const exchangeType = (exchange.exchange_type || '').toLowerCase();
    const exchangeName = exchange.exchange_name || '';
    const dealPriceMax = state.max_deal || '';
    const disabledSell = state.disabledSell || false;
    const paused = state.paused || false;

    let dealPriceMin = state.min_deal;
    dealPriceMin === undefined && (dealPriceMin = dealMin || '');

    let stablecoins = state.stablecoins;
    if (exchangeType !== 'hitbtc') {
      stablecoins = map(stablecoins, (v) => {
        return v.id === 'EURS' ? {
          id: 'EUR',
          name: 'EUR',
        } : v;
      }, []);
    }

    _exchangeType === exchangeType || getMinDeal(_exchangeType = exchangeType);

    const cryptoCurrency = state.cc_src || get(cryptocoins, '0.id');
    const currency = state.cc_dst || get(stablecoins, '0.id');

    const gainInt = parseFloat(gain);
    const gainParts = gain.split('.');
    const invalidGain = !isSafeNumber(gainInt)
      || (gainParts[0] || '').length > 2
      || (gainParts[1] || '').length > 2
      || gainInt < MIN_GAIN_LIMIT
      || gainInt > MAX_GAIN_LIMIT;

    const dealPriceMinInt = parseFloat(dealPriceMin);
    const dealPriceMaxInt = parseFloat(dealPriceMax);
    const invalidDealPriceMin = dealPriceMin && (
      !isSafeNumber(dealPriceMinInt)
      || isSafeNumber(dealPriceMaxInt) && dealPriceMinInt > dealPriceMaxInt
    );
    const invalidDealPriceMax = dealPriceMax && (
      !isSafeNumber(dealPriceMaxInt)
      || isSafeNumber(dealPriceMinInt) && dealPriceMinInt > dealPriceMaxInt
    );

    const profitInItems = [
      {label: cryptoCurrency, value: 'CRYPTO'},
      {label: currency, value: 'STABLE'},
    ];
    const profitIn = state.profitIn || profitInItems[0].value;
    const kindOfGain = profitIn === 'STABLE'
      ? 'increase_stables'
      : 'increase_cryptos';

    const disabled = invalidGain
      || invalidDealPriceMin || invalidDealPriceMax;

    const dealMinWarn = !isNaN(dealPriceMinInt) && dealPriceMinInt < dealMin;

    const sendParams = {
      cc_src: cryptoCurrency,
      cc_dst: currency,
      exchange_id: exchangeId,
      gain: gain,
      kind_of_gain: kindOfGain,
      active: true,
      min_deal: dealPriceMinInt || 0,
      max_deal: dealPriceMaxInt || 0,
      enabled_sell: !disabledSell,
      active: !paused,
    };

    return (
      <LayoutD className="fw5">
        <HeaderA>
          <CaptionB>{hasNew ? 'Add Trading Pairs' : 'Edit Pair'}</CaptionB>
        </HeaderA>
        <WrapperWithLoading
          loading={state.loading || state.getLoading}
        >
          {hasNew ? (
            <>
              <div className="layoutRow fhaS fvaC">
                <LabelA>Buy</LabelA>
                <SelectA
                  className="w170"
                  items={cryptocoins}
                  propValue="id"
                  propName="name"
                  value={cryptoCurrency}
                  onChange={handleChangeCryptoCurrency}
                  disabled={!hasNew}
                />
                <div className="ph30 c7D8594">For</div>
                <SelectA
                  className="w170"
                  items={stablecoins}
                  propValue="id"
                  propName="name"
                  value={currency}
                  onChange={handleChangeCurrency}
                  disabled={!hasNew}
                />
              </div>
              <WrapperWithLoading
                className="hmin0*2 mt30"
                loading={state.exchangeLoading}
              >
                <div className="layoutRow w fhaS fvaC">
                  <LabelA>Exchange</LabelA>
                  <SelectA
                    className="w420"
                    items={exchanges}
                    propName="exchange_name"
                    propValue="id"
                    value={exchangeId}
                    onChange={handleChangeExchangeId}
                    disabled={!hasNew}
                  />
                  {
                    hasNew ? (
                      <LinkA
                        className="ml25"
                        href={LINK_ADD_EXCHANGE}
                        component={Link}
                      >Add Exchange</LinkA>
                    ) : null
                  }
                </div>
              </WrapperWithLoading>
            </>
          ) : (
            <div className="layoutRow fhaB fvaC c7D8594 f15 w540">
              <div className="fwBold c1B1B1B>span">
                Buy <span>{cryptoCurrency}</span> for <span>{currency}</span>
              </div>
              <WrapperWithLoading
                loading={state.exchangeLoading}
                className="layoutRow fhaC fvaC"
              >
                Exchange <span
                  className="c1B1B1B fwBold"
                >{exchangeName} ({exchangeType})</span>
              </WrapperWithLoading>
            </div>
          )}
          <div
            className={'layoutRow fhaS fvaC mt30 c7D8594.pairEdit(|>input)'
              + (hasNew ? '' : ' pairEdit')}
          >
            <LabelA>Gain</LabelA>
            <div className="w90 rlv">
              <TextFieldA
                error={(gain || clicked) && invalidGain}
                className="w pr33>input*3"
                value={gain}
                onInput={handleChangeGain}
              />
              <div className="abs svr layoutRow fhaC fvaC pr18">%</div>
            </div>
            <div className="ml25">Profit in</div>
            <div className="ml20">
              <RadioA
                items={profitInItems}
                value={profitIn}
                onChange={handleChangeProfitIn}
              />
            </div>
          </div>
          <div className="layoutRow fhaS fvaC mt40">
            <LabelA>Deal limit:</LabelA>
            <div className="w420">
              <div className="mh-10 (lt|w1/2|ph10)>1">
                <WrapperWithLoading
                  loading={state.loadingDealMin}
                >
                  <div className="rlv">
                    {
                      dealMinWarn ? (
                        <div className="abs sl sb17 f10 cF00">
                          minimal recommended should be {dealMin}
                        </div>
                      ) : null
                    }
                    <div
                      className="abs sl sb3 f12 c7D8594"
                    >Minimum, {currency}:</div>
                  </div>
                  <TextFieldA
                    className="w"
                    error={clicked && invalidDealPriceMin || dealMinWarn}
                    value={dealPriceMin}
                    onInput={handleChangeDealPriceMin}
                  />
                </WrapperWithLoading>
                <div>
                  <div className="rlv">
                    <div
                      className="abs sl sb3 f12 c7D8594"
                    >Maximum, {currency}:</div>
                  </div>
                  <TextFieldA
                    className="w (c2|o100)>input:i"
                    placeholder="auto"
                    error={clicked && invalidDealPriceMax}
                    value={dealPriceMax}
                    onInput={handleChangeDealPriceMax}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="layoutRow fhaS fvaC mt40">
            <CheckboxB
              checked={disabledSell}
              onChange={handleDisabledSell}
            />
            <div className="pl15">
              Don&#39;t trade on exchange
            </div>
          </div>
          <div className="layoutRow fhaS fvaC mt20">
            <CheckboxB
              checked={paused}
              onChange={handlePaused}
            />
            <div className="pl15">
              Paused
            </div>
          </div>
          <div className="layoutRow fhaS fvaC mt70">
            <ButtonB
              className="wmin190"
              onClick={backLocation}
            >Back</ButtonB>
            <ButtonA
              className="ml20 wmin240"
              disabled={clicked && disabled}
              onClick={() => {
                disabled
                  ? setState({
                    clicked: 1,
                  })
                  : (
                    hasNew
                      ? ccPair$.create(sendParams)
                      : (
                        sendParams.id = id,
                        ccPair$.edit(sendParams)
                      )
                  ).then(() => {
                    backLocation();
                    infos$.push([
                      'Trading Pairs',
                      'Trading Pairs are successfully edited',
                    ]);
                  });
              }}
            >{hasNew ? 'Create' : 'Update'} arbitrage Pair</ButtonA>
          </div>
        </WrapperWithLoading>
      </LayoutD>
    );
  };
}, {
  stablecoins: stablecoins$,
  cryptocoins: cryptocoins$,
  exchanges: exchange$,
  loading: combine([
    ccPairUpdateLoading$,
    assetsAvailableLoading$,
  ]).map(some),
  exchangeLoading: exchangeListLoading$,
});
