const React = require('react');
const loopMap = require('mn-utils/loopMap');
const invoke = require('mn-utils/invoke');
const delay = require('mn-utils/delay');
const withState = require('../../utils/withState');

module.exports = withState((setState) => {
  const refs = [];
  return (state, props) => {
    const value = props.value || '';
    return (
      <div className={(props.error ? 'error ' : '') + (props.className || '')}>
        <div className="f16 cF00<1.error">
          Enter a one-time password
        </div>
        <div
          className="layoutRow fhaS fvaC (fx1|ph10)>1 mh-10 mt20
            (h40|b1|bc00|bcb56585B|bcbF00<1.error|bg00|cCT|f18|w|tc|olN)>input"
        >
          {loopMap(6, (i) => {
            const prevNode = refs[i - 1];
            const nextNode = refs[i + 1];
            return (
              <div key={i}>
                <input
                  value={value[i] || ''}
                  ref={(ref) => {
                    ref && ref !== refs[i] && (
                      refs[i] = ref,
                      i || ref.focus()
                    );
                  }}
                  onInput={(e) => {
                    const v = e.target.value.replace(/[^0-9]/, '');
                    invoke(props, 'onChange', [
                      (value.substr(0, i) + v + value.substr(i + 1))
                          .substr(0, 6),
                    ]);
                    v && nextNode && nextNode.focus();
                  }}
                  onKeyDown={(e) => {
                    const {keyCode} = e;
                    delay(() => {
                      keyCode === 37 && prevNode && prevNode.focus();
                      keyCode === 39 && nextNode && nextNode.focus();
                      keyCode === 8 && prevNode && prevNode.focus();
                      nextNode || keyCode == 13 && (
                        refs[i].blur(),
                        invoke(props, 'onEnd')
                      );
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}, {});
