const React = require('react');

/* eslint-disable */

module.exports = (props) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={'dB fillCT>path ' + (props.className || '')}
    >
      <path
        d="M4.15683 13.1623L0 14L0.838833 9.84375L4.15683 13.1623ZM1.66367 9.01833L4.98167 12.3369L14 3.31858L10.6814 0L1.66367 9.01833Z"
      />
    </svg>
  );
};
