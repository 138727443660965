const React = require('react');
const Ripple = require('./Ripple');

module.exports = (props) => {
  return (
    <Ripple
      {...props}
      className={
        `layoutRow fvaC fhaC h46 bgEEF0F4 c0 r6 f14 fw6 ph30 tdN olN ff$main
          usN rlv o70.disabled ` + (props.className || '')
      }
    />
  );
};
