const Emitter = require('mn-utils/Emitter');
const some = require('mn-utils/some');
const {
  apiCcPairCreate,
  apiCcPairRemove,
  apiCcPairUpdate,
  apiCcPairActivate,
  apiCcPairList,
} = require('../api/apiCcPair');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');

export const ccPairUpdateLoading$ = new Emitter(0);
export const ccPairListLoading$ = new Emitter(0);
export const ccPairLoading$ = Emitter.combine([
  ccPairUpdateLoading$,
  ccPairListLoading$,
]).map(some);

const getUpdate = decorateByLoadingEmit(
    apiCcPairList,
    ccPairListLoading$,
    (emit, response) => {
      emit(response);
    },
);

export const ccPair$ = (new Emitter((emit) => {
  getUpdate(emit);
}, {
  list: [],
})).behave({
  getUpdate,
  edit: decorateByLoadingEmit(apiCcPairUpdate, ccPairUpdateLoading$, update),
  activate: decorateByLoadingEmit(
      apiCcPairActivate,
      ccPairUpdateLoading$,
      update,
  ),
  create: decorateByLoadingEmit(
      apiCcPairCreate, ccPairUpdateLoading$,
      (emit, response, params, getValue) => {
        const v = getValue();
        emit({
          ...v,
          count: 1 + (v.count || 0),
          list: [
            {
              ...response,
              gain: 100 * (response.gain || 0),
            },
            ...v.list,
          ],
        });
      },
  ),
  remove: decorateByLoadingEmit(apiCcPairRemove, ccPairUpdateLoading$, update),
});

function update() {
  ccPair$.getUpdate();
}
