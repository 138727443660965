const React = require('react');
const InputLabel = require('@material-ui/core/InputLabel').default;
const MenuItem = require('@material-ui/core/MenuItem').default;
const OutlinedInput = require('@material-ui/core/OutlinedInput').default;
const FormControl = require('@material-ui/core/FormControl').default;
const Select = require('@material-ui/core/Select').default;
const uniqIdProvider = require('mn-utils/uniqIdProvider');
const find = require('mn-utils/find');
const noopHandle = require('mn-utils/noopHandle');
const get = require('mn-utils/get');
const isDefined = require('mn-utils/isDefined');
const isArray = require('mn-utils/isArray');
const isFunction = require('mn-utils/isFunction');
const upperFirst = require('mn-utils/upperFirst');
const without = require('mn-utils/without');
const withState = require('../../utils/withState');

const getId = uniqIdProvider('select_');
const WITHOUT_PROPS = [
  'disabled',
  'placeholder',
  'propValue',
  'propName',
  'items',
  'value',
  'inputComponent',
  'onChange',
  'multiple',
];

/* eslint-disable */

function DropIcon(props) {
  return (
    <svg
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'dB w14 abs sr20*2 st50%*2 xY-50%*2 fillCFD4D9>path '
        + (props.className || '')}
    >
      <path
        d="M12.25 -7.64949e-08L14 1.6304L7 8L-7.12671e-08 1.6304L1.75 -5.35465e-07L7 4.8L12.25 -7.64949e-08Z"
      />
    </svg>
  );
}

module.exports = withState((setState, self, onMount) => {
  const elId = getId();
  let inputLabelRef;

  onMount(() => {
    setState({
      labelWidth: inputLabelRef && inputLabelRef.offsetWidth || 0,
    });
  });

  function onRef(ref) {
    inputLabelRef = ref;
  }
  function handleChange(e) {
    const {onChange} = self.props;
    onChange && onChange(e.target.value);
  }

  return (state, props) => {
    const placeholder = props.placeholder || '';
    const propValue = props.propValue;
    const propName = props.propName;
    const multiple = props.multiple || false;
    const InputComponent = props.inputComponent || OutlinedInput;
    const items = props.items || [];

    let v, value = props.value || 0;
    multiple
      ? (isArray(value) || (value = []))
      : (
        isDefined(propValue)
          ? (
            find(items, [propValue, value])
              || (v = get(items, '0.' + propValue)) === undefined || (value = v)
          )
          : (find(items, [[], value]) || (value = items[0]))
      );

    const _propValue = isDefined(propValue)
      ? (isFunction(propValue) ? propValue : ((item) => get(item, propValue)))
      : noopHandle;
    const _propName = isDefined(propName)
      ? (
      isFunction(propName)
        ? propName
        : ((item) => upperFirst('' + (get(item, propName) || _propValue(item))))
      )
      : noopHandle;

    return (
      <FormControl {...without(props, WITHOUT_PROPS)}>
        <InputLabel
          ref={onRef}
          htmlFor={elId}
          shrink={true}
        >{placeholder}</InputLabel>
        <Select
          disabled={!!props.disabled}
          multiple={multiple}
          className="w f14*2 r6*2 (pt17|pb16|ph20)>.*OutlinedInput-input
            (dn|bcE7E8EA|b2)>.*OutlinedInput-notchedOutline*2
            bcC7C8CA(:h|.Mui-focused)>.*OutlinedInput-notchedOutline*2"
          value={value}
          onChange={handleChange}
          input={
            <InputComponent
              labelWidth={state.labelWidth}
              name={placeholder}
              id={elId}
            />
          }
          IconComponent={DropIcon}
        >
          {items.map((item) => {
            const value = _propValue(item);
            return (
              <MenuItem
                key={value}
                value={value}
                onClick={item.onClick || null}
              >{_propName(item)}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };
}, {
  labelWidth: 0,
});
