const Emitter = require('mn-utils/Emitter');
const {
  apiExchangeList,
} = require('../api/apiExchange');
const decorateByLoadingEmit = require('./utils/decorateByLoadingEmit');

export const exchangeUpdateLoading$ = new Emitter(0);
export const exchangeListLoading$ = new Emitter(0);

const getUpdate = decorateByLoadingEmit(
    apiExchangeList,
    exchangeListLoading$,
    (emit, response) => emit(response.list || []),
);

export const exchange$ = (new Emitter((emit) => {
  getUpdate(emit);
}, [])).behave({
  getUpdate,
});
