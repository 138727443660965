const React = require('react');
const ButtonBase = require('@material-ui/core/ButtonBase').default;
const State = require('./State');
const {backLocation} = require('../../router');

const STYLE = {
  background: 'rgba(24, 26, 28, 0.95)',
};

module.exports = (props) => {
  const {open$} = props;
  return (
    <State
      state={{
        open: open$,
        animate: open$.delay(50),
        lazyOpen: open$.delay(500),
      }}
      render={(state) => {
        const {
          open,
          animate,
        } = state;
        const value = open || animate || state.lazyOpen;

        return value ? (
          <div
            style={STYLE}
            className={`fixed s z10 o0 ff$main dn ovxH ovyA o100.active `
              + (open && animate ? ' active' : '')}
          >
            <div className="layoutRow fhaC fvaC w hmin rlv p10">
              <ButtonBase
                className="dB*2 abs*2 s"
                component="div"
                onClick={backLocation}
              />
              <div
                className={`wmax rlv w540 bgF8F8F8 r6 pv45 bxsh65y8c0\.15 `
                  + (props.className || '')}
              >
                <div className="wmax w400 mhA">
                  {props.render(value)}
                </div>
              </div>
            </div>
          </div>
        ) : null;
      }}
    />
  );
};
